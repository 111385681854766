import React from 'react'
import logo from '../images/ex-logo.png'
const Logo = () => {
  return (
    <div className="navbar-brand-box">
        <a href="" className="logo logo-dark">
            <span className="logo-sm">
                <img src={logo} alt="" />
            </span>
            <span className="logo-lg">
                <img src={logo} alt="" />
            </span>
        </a>
        <a href="" className="logo logo-light">
            <span className="logo-sm">
                <img src={logo} alt="" height="22" />
            </span>
            <span className="logo-lg">
                <img src={logo} alt="" height="20" />
            </span>
        </a>
    </div>
  )
}

export default Logo
