import React, { useEffect } from 'react'
import '../../Installers/Installers.css'
import InfoComponent from '../../../../Alerts/InfoComponent/InfoComponent'
import * as setupCompleted from '../../../../Constants/Constants'
import ConnectorInfo from '../ConnectorInfo'
import FinishSetup from '../../Installers/FinishSetup'
import SourceInstaller from './SourceInstaller/SourceInstaller'
import TargetInstaller from './TargetInstaller/TargetInstaller'

const ChildDownload = (props) => {
    return (
        <div className='card-body p-0'>
            {
                props.connectorInfo && props.connectorInfo ? 
                <>
                    <div className='downloadComponent'>
                        <ConnectorInfo
                            name={props.connectorInfo.name && props.connectorInfo.name}
                            sourceName={props.connectorInfo.source && props.connectorInfo.source.name}
                            sourceVersion={props.connectorInfo.source && props.connectorInfo.source.version}
                            targetName={props.connectorInfo.target && props.connectorInfo.target.name}
                            targetVersion={props.connectorInfo.target && props.connectorInfo.target.version}
                            productType={props.connectorInfo.instances.length > 0 ? props.connectorInfo.instances[0].instanceType : null}
                        />
                        <div className="installerComponent">
                            <SourceInstaller
                                installerName={props.connectorInfo && props.connectorInfo.source && props.connectorInfo.source.name}
                                installerVersion={props.connectorInfo && props.connectorInfo.source && props.connectorInfo.source.version}
                                installerSize={props.connectorInfo && props.connectorInfo.source && props.connectorInfo.source.installer && props.connectorInfo.source.installer.size}
                                installerType = {props.connectorInfo && props.connectorInfo.source && props.connectorInfo.source.type}
                                connectorId = {props.connectorInfo && props.connectorInfo.id}
                                instanceId =  {props.connectorInfo.instances.length > 0 ? props.connectorInfo.instances[0].id:null}
                                subscriptionId = {props.connectorInfo && props.connectorInfo.subscriptionId}
                                sourceId = {props.connectorInfo && props.connectorInfo.source && props.connectorInfo.source.id}
                                fileType = {props.connectorInfo && props.connectorInfo.source && props.connectorInfo.source.installer && props.connectorInfo.source.installer.type}
                            />
                            <TargetInstaller
                                installerName={props.connectorInfo && props.connectorInfo.target && props.connectorInfo.target.name}
                                installerVersion={props.connectorInfo && props.connectorInfo.target && props.connectorInfo.target.version}
                                installerSize={props.connectorInfo && props.connectorInfo.target && props.connectorInfo.target.installer && props.connectorInfo.target.installer.size}
                                installerType = {props.connectorInfo && props.connectorInfo.target && props.connectorInfo.target.type}
                                connectorId = {props.connectorInfo && props.connectorInfo.id}
                                instanceId =  {props.connectorInfo.instances.length > 0 ? props.connectorInfo.instances[0].id:null}
                                subscriptionId = {props.connectorInfo && props.connectorInfo.subscriptionId}
                                sourceId = {props.connectorInfo && props.connectorInfo.target && props.connectorInfo.target.id}
                                fileType = {props.connectorInfo && props.connectorInfo.target && props.connectorInfo.target.installer && props.connectorInfo.target.installer.type}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            Note : <InfoComponent info={setupCompleted.setupCompleted} /> 
                        </div>
                        <div className="col-lg-12">
                            <FinishSetup />
                        </div>
                    </div>
                </> : null
            }
        </div>
    )
}
export default ChildDownload
