import React from 'react'
import './css/dashboard.css'
import Metrics from './Metrics/Metrics'
import Store from '../../../Contexts/Store'
import ConnectorPanel from './ConnectorPanel/ConnectorPanel'

const Dashboard = () => {
  return (
    <div className="main-content mn-dashboard">
      <div className="page-content custompaddingdash">
        <div className="container-fluid">
          <Store>
            <Metrics />
            <ConnectorPanel />
          </Store>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
