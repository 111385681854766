import React from 'react'
import {Link} from 'react-router-dom'
import TableData from './TableData'
const TableRow = (props) => {
    const tableInfo = [
        {instanceId:props.instanceId, requestType:props.requestType, sourceId:props.sourceId, endPointId:props.endPointId, entityName: props.entityName, entityId:props.entityId, statusIds: 1, count:props.requestReceivedCount},
        {instanceId:props.instanceId, requestType:props.requestType, sourceId:props.sourceId, endPointId:props.endPointId, entityName: props.entityName, entityId:props.entityId, statusIds: 2, count:props.inProcessCount},
        {instanceId:props.instanceId, requestType:props.requestType, sourceId:props.sourceId, endPointId:props.endPointId, entityName: props.entityName, entityId:props.entityId, statusIds: 4, count:props.responseReceivedCount},
        {instanceId:props.instanceId, requestType:props.requestType, sourceId:props.sourceId, endPointId:props.endPointId, entityName: props.entityName, entityId:props.entityId, statusIds: 5, count:props.responseTransferredCount},
        {instanceId:props.instanceId, requestType:props.requestType, sourceId:props.sourceId, endPointId:props.endPointId, entityName: props.entityName, entityId:props.entityId, statusIds: 3, count:props.errorCount},
        {instanceId:props.instanceId, requestType:props.requestType, sourceId:props.sourceId, endPointId:props.endPointId, entityName: props.entityName, entityId:props.entityId, statusIds: 6, count:props.completeCount},
        {instanceId:props.instanceId, requestType:props.requestType, sourceId:props.sourceId, endPointId:props.endPointId, entityName: props.entityName, entityId:props.entityId, statusIds: null, count:props.totalCount}
    ]
    
    return (
        <tr>
            <th scope="row">{props.entityName}</th>
            {
                tableInfo.map((item,index) => 
                    <TableData 
                        count={item.count} 
                        entityId={item.entityId}
                        endPointId={props.endPointId}
                        sourceId={item.sourceId}
                        requestType={item.requestType}
                        instanceId={item.instanceId}
                        statusIds={item.statusIds}
                    />
                )
            }
        </tr>
    )
}

export default TableRow