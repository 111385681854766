import React,{ useState} from 'react'
import API from '../../../../../API/API'

const DownloadInstaller = (props) => {
    const [count, setCount] = useState(0)
    const downloadTargetInstaller = () => {
        setCount(count + 1)
        var response = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        API.get('/User/Token/Check', { headers })
        .then((response) => {
            if(response.status === 200){
                if(response.data.result == false){
                    API.get('/User/Authorize', { headers })
                    .then((response) => {
                        if(response.status === 200){
                            if(count < 5){
                                var win = window.open(response.data, "", "width=500,height=500");
                                setTimeout(function () { win.close(); }, 10000);
                            }else {
                                var html = "<html><head></head><body> Authentication failed : The Request could not be completed </body></html>"
                                var win = window.open(response.data, "", "width=500,height=500");
                                win.document.write(html)
                                setTimeout(function () { win.close(); }, 10000);
                            }
                        }
                    });
                    setTimeout(function(){
                        if(count < 5){
                            downloadTargetInstaller()
                        }else{
                            setCount(0)
                        }
                    }, 11000)
                }else{
                    var mnTocken = window.localStorage.getItem("accessToken");
                    const body = {
                        "subscriptionId": props.subscriptionId,
                        "endpointId": props.endpointId,
                        "instanceId": props.instanceId,
                        "fileType": props.fileType,
                        "refreshToken": mnTocken,
                    }
                    API.post("/Client/connections/download", body, { headers, responseType: 'blob' })
                    .then((response)=>{
                        const disposition = response.headers["content-disposition"];
                        var fileName = "";
                        const array1 = disposition.split(';');
                        if (array1 && array1.length > 1) {
                            const string1 = array1[1];
                            if (string1) {
                                const array2 = string1.split('=');
                                if (array2 && array2.length > 1) {
                                    fileName = array2[1];
                                }
                            }
                        }
                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', fileName);
                        link.click();
                    })
                }
            }
        })
    }
    return (
        <a className="cursor-pointer" onClick={downloadTargetInstaller}> Download </a>
    )
}

export default DownloadInstaller
