import {React, useState, } from 'react'
import './targetInstaller.css'
import API from '../../../../../API/API'
import { InfinitySpin } from  'react-loader-spinner'

const TargetInstaller = (props) => {
    const [connector, setConnector] = useState()
    const [count, setCount] = useState(0)
    const [progressMessage, setProgressMessage] = useState("0")
    const [loading, setLoading]  =useState(false)

    const sourceDownload = () => {
        setLoading(true)
        setCount(count + 1)
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json",
        };
        API.get(`/User/Token/Check`, {headers})
        .then((response)=> {
            if(response.status === 200){
                if(response.data.result == false){
                    API.get('/User/Authorize', { headers })
                    .then((response)=>{
                        if(response.status === 200){
                            if(count < 5){
                                var win = window.open(response.data, "", "width=500,height=500");
                                setTimeout(function () { win.close(); }, 10000);
                            }
                            else{
                                var html = "<html><head></head><body> Authentication failed : The Request could not be completed </body></html>"
                                var win = window.open(response.data, "", "width=500,height=500");
                                win.document.write(html)
                                setTimeout(function () { win.close(); }, 10000);
                            }
                        }
                    })
                    setTimeout(function () {
                        if (count < 5) {
                            sourceDownload();
                        }else{
                            setCount(0);
                        }
                    }, 11000);
                }else{
                    API.post(`/Client/connections/download`, {
                            "subscriptionId": props.subscriptionId,
                            "endpointId": props.sourceId,
                            "instanceId": props.instanceId,
                            "fileType": props.fileType,
                            "refreshToken": response,
                        },{ headers, responseType: 'blob' }
                    )
                    .then((response) => {
                        const disposition = response.headers["content-disposition"];
                        var fileName = "";
                        const array1 = disposition.split(';');
                        if (array1 && array1.length > 1) {
                            const string1 = array1[1];
                            if (string1) {
                                const array2 = string1.split('=');
                                if (array2 && array2.length > 1) {
                                    fileName = array2[1];
                                }
                            }
                        }
                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', fileName);
                        link.click();
                    })
                }
            }
        })
    }
    
    const sourceSetup = () => {
        setProgressMessage("2%")
        setCount(count+1)
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json",
        };
        API.get(`/User/Token/Check`, {headers})
        .then((response)=> {
            if(response.status === 200){
                if(response.data.result == false){
                    API.get('/User/Authorize', { headers })
                    .then((response)=>{
                        if(response.status === 200){
                            if(count < 5){
                                var win = window.open(response.data, "", "width=500,height=500");
                                setTimeout(function () { win.close(); }, 10000);
                            }else{
                                var html = "<html><head></head><body> Authentication failed : The Request could not be completed </body></html>"
                                var win = window.open(response.data, "", "width=500,height=500");
                                win.document.write(html)
                                setTimeout(function () { win.close(); }, 10000);
                            }

                        }
                    })
                    setTimeout(function () {
                        if (count < 5) {
                            sourceSetup();
                        }else{
                            setCount(0);
                        }
                    }, 600000);
                }else{
                    API.post(`/Client/connections/setup`, {
                        "subscriptionId": props.subscriptionId,
                        "endpointId": props.sourceId,
                        "instanceId": props.instanceId,
                        "fileType": props.fileType,
                    }, {headers})
                    .then((response) =>{
                        alert(response.data.message)
                        setLoading(false)
                    })
                }
            }
        })
    }

    return (
        <>
            {
                loading ?
                    <div className='spinLoaderContainer spinLoaderContainer1'>
                        <InfinitySpin
                            width='200'
                            color="#437FED"
                            wrapperStyle={{}}
                            wrapperClass="spinnerStyle"
                            visible={true}
                        />
                        <p> Please wait, Setup in progress </p>
                    </div> 
                : null
            }
            <h6 className="installer-title"> {props.installerName} Installer </h6>
            <div className="table-responsive">
                <table className="table mb-0">
                    <tr>
                        <th>Installer</th>
                        <td>{props.installerVersion}</td>
                        <td> {props.installerSize} </td>
                        <td>
                            <div className="startSetupButton">
                                {
                                    props.installerType && props.installerType !== 1 ? 
                                    <button type="button" className="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector" onClick={sourceDownload}>Download</button> :
                                    <button type="button" className="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector" onClick={sourceSetup}>Start Setup</button>
                                }
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </>
    )
}

export default TargetInstaller
