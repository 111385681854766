import React,{ useState } from 'react'
import $ from "jquery"
import Dropdown from './FieldType/Dropdown';
import Input from './FieldType/Input'

const Questions = (props) => {
    return (
        <>
            {
                props.questions.map((item,index) => 
                    <div className='col-md-6'>
                        {
                            item.type === "Dropdown" ?
                            <Dropdown 
                                options={item.questionOptions} 
                                placeholder={item.text}
                                id={item.id}
                            />
                            :
                            <Input
                                type={item.type} 
                                title={item.text} 
                                placeholder={item.text} 
                                id={item.id}
                                defaultValue={item.defaultValue}
                            />
                        }

                    </div>
                    
                )
            }
        </>
    )
}

export default Questions
