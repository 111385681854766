import React from 'react'
import $ from "jquery"

const Resync = (props) => {
    const getresync = () => {
        let checkedCheckbox = $('input:checkbox:checked').length
        if(checkedCheckbox < 1){
            $("button#SelectedreSync").addClass("d-none")
            $("button#defaultSelectedreSync").removeClass("d-none")
        }else{
            $("button#SelectedreSync").removeClass("d-none")
            $("button#defaultSelectedreSync").addClass("d-none")
        }
    }

    return (
        <>
            {
                props.statusName === "Error" ?
                    <div className="custom-control custom-checkbox mt-2">
                        <input type="checkbox" className="custom-control-input" id={"errorStatus"+props.messageId} name={"resync"} defaultValue={props.messageId} onChange={getresync} />
                        <label className="custom-control-label" for={"errorStatus"+props.messageId}> </label>
                    </div> 
                :
                <div className="custom-control custom-checkbox mt-2">
                    <input type="checkbox" className="custom-control-input" id={"errorNewway"+props.messageId} disabled={true} title='It`s Allowed for only error status' />
                    <label className="custom-control-label newDisabledMethod" for={"errorNewway"+props.messageId}>  </label>
                </div>                                                                    

            }
        </>
    )
}
export default Resync