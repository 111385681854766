import React from 'react'
import './myproducts.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Store from '../../../Contexts/Store'
import ConnectorPanel from './ConnectorPanel/ConnectorPanel';

const MyProducts = () => {
  return (
    <div className="main-content myProducts newMyProducts">
      <div className="page-content pb-0">
        <div className="container-fluid">
          <Store>
            <ConnectorPanel />
          </Store>
        </div>
      </div>
    </div>
  )
}

export default MyProducts
