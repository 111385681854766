import React from 'react'
import TableHeadings from './TableHeadings'
import TableBody from './TableBody'

const EntityTable = (props) => {
    return (
        <div className="row mt-4">
            <div className="col-lg-12">
                <div className="card no-padding">
                    <div className="card-body no-padding">
                        <div className="table-responsive">
                            <table className="viewSummaryNew table mb-0">
                                <TableHeadings />
                                {
                                    props.Entities && props.Entities.length != 0 ? 
                                    <TableBody 
                                        Entities={props.Entities} 
                                        endPointId={props.endPointId} 
                                        sourceId={props.sourceId}
                                        requestType={props.requestType}
                                        instanceId={props.instanceId}
                                    /> : 
                                    <tr>
                                        <td className='text-center' colSpan={8}> 
                                            <div className="alert alert-info" role="alert">
                                                No data is available for selected combination.
                                            </div>
                                        </td>
                                    </tr>
                                }

                                
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EntityTable