import React, { useEffect, useState } from 'react';
import Styles from './Target.module.css'
import { Center, Card, Circle, Image , CardBody, Box, Wrap, Avatar, WrapItem} from '@chakra-ui/react';

import Images from '../Image/Images';
const Target = (props) => {
    return (
        <>
            <Box width={'80px'} margin={'0 auto'}>
                <Circle size={'80px'} boxShadow={'0 4.86899px 24.3449px #0000001a'} overflow={'hidden'}>
                    <Images shortName={props.shortName}  />
                </Circle>
            </Box>
        </>
    )
}

export default Target
