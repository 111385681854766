import React, { useState } from 'react'
import API from '../../API/API'
const Authentication = () => {
    const [sourceCount, setSourceCount] = useState(0)
    const [targetcount, setTargetcount] = useState(0)
    
    let data= JSON.parse(window.localStorage.getItem("data"));
    const SourceAuthenticate = (event) => {
        setSourceCount(sourceCount + 1)
        var response = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        const body =
        {
          "subscriptionConnectorId": parseInt(data.id),
          "instanceType": data.instances[0].instanceType
        }
        API.get('/User/Token/Check', { headers })
        .then(response => {
            if(response.data.result === false){
                API.get('/User/Authorize', { headers })
                .then(response => {
                    if(targetcount < 5){
                        var win = window.open(response.data, "", "width=500,height=500");
                        setTimeout(function () { win.close(); }, 10000);
                    }else{
                        var html = "<html><head></head><body> Authentication failed : The Request could not be completed </body></html>"
                        var win = window.open(data.data, "", "width=500,height=500");
                        win.document.write(html)
                        setTimeout(function () { win.close(); }, 10000);
                    }
                })
                setTimeout(function () {
                    if (targetcount < 5) {
                        TargetAuthenticate()
                    }
                }, 11000);
            }else{
                API.get(`/Authentication/QuickBooksAuthenticate/?subscriptionConnectorId= ${data.id} &instanceType=${data.instances[0].instanceType}`, { headers })
                .then(response => {
                    window.open(response.data,"_self");
                })
            }
        })


    }

    const TargetAuthenticate = (event) => {
        setTargetcount(targetcount + 1)
        var response = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        API.get('/User/Token/Check', { headers })
        .then(response=>{
            if(response.data.result === false){
                API.get('/User/Authorize', { headers })
                .then(response => {
                    if(targetcount < 5){
                        var win = window.open(response.data, "", "width=500,height=500");
                        setTimeout(function () { win.close(); }, 10000);
                    }else{
                        var html = "<html><head></head><body> Authentication failed : The Request could not be completed </body></html>"
                        var win = window.open(data.data, "", "width=500,height=500");
                        win.document.write(html)
                        setTimeout(function () { win.close(); }, 10000);
                    }
                })
                setTimeout(function () {
                    if (targetcount < 5) {
                        TargetAuthenticate()
                    }
                }, 11000);
            }else{
                if(event.target.name === "Shopify"){
                    API.get(`/Shopify/${data.id}/Authenticate/${data.instances[0].instanceType}`, { headers })
                    .then(response => {
                        window.open(response.data,"_self");
                    })
                }else if(event.target.name === "BigCommerce"){
                    API.get(`/Bigcommerce/${data.id}/Authenticate/${data.instances[0].instanceType}`, { headers })
                    .then(response => {
                        window.open(response.data,"_self");
                    })
                }
            }
        })
    }

    return (
        <>
            <div className="main-content authentication">
                <div className="page-content pb-0">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-between mb-3 pb-0">
                                    <h4 className="mb-0">Authentication</h4>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            {
                                data.source.isAuthenticationRequired ? 
                                <div class="col-xl-6">
                                    <div class="card">
                                        <h4 class="card-header font-size-16">{data.source.shortName} Authentication</h4>
                                        <div class="card-body">
                                            <p class="card-title-desc">
                                                Dear Subsciber, <br />
                                                Click Authenticate button, In order to get Authenticated with {data.source.shortName}
                                            </p>
                                            <button class="btn btn-primary float-right i95-button" type="submit" name={data.source.shortName} onClick={SourceAuthenticate}>Authenticate</button>
                                        </div>
                                    </div>
                                </div> : null
                            }
                            {
                                data.target.isAuthenticationRequired ? 
                                <div class="col-xl-6">
                                    <div class="card">
                                        <h4 class="card-header font-size-16">{data.target.shortName} Authentication</h4>
                                        <div class="card-body">
                                            <p class="card-title-desc">
                                                Dear Subsciber, <br />
                                                Click Authenticate button, In order to get Authenticated with {data.target.shortName}
                                            </p>
                                            <button class="btn btn-primary float-right i95-button" type="submit" name={data.target.shortName} onClick={TargetAuthenticate}>Authenticate</button>
                                        </div>
                                    </div>
                                </div> : null
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Authentication
