import React, {useEffect, useState} from 'react'
import $ from 'jquery'
import "./ViewDetails.css"
import ToggleImg from "./images/toggleSummary.svg"
import API from '../../API/API'
import BreadCrumb from './BreadCrumb/BreadCrumb'
import PanelWidget from './PanelWidget/PanelWidget'
import PanelInfo from './PanelWidget/PanelInfo'
import Toggle from './PanelWidget/Toggle'
import EntityTable from './EntityTable/EntityTable'

const ViewDetails = () => {
    //var instanceIdNew = JSON.parse(localStorage.getItem('summaryInfo'))
    const [instanceIdNew, setInstanceIdNew] = useState(JSON.parse(localStorage.getItem('summaryInfo')))
    const Summary = instanceIdNew
    //const sourceEntities = Summary.sourceEntity
    //const targetEntities = Summary.targetEntity

    const [sourceEntities, setSourceEntities] = useState(null)
    const [targetEntities, setTargetEntities] = useState(null)
    
    const sourceId = Summary.sourceId
    const targetId = Summary.targetId
    const instanceId = Summary.instancesId

    const [endPointId, setEndPointId] = useState(sourceId)
    const [requestType, setRequestType] = useState("Source")

    const [sourcePushTime, setSourcePushTime] = useState()
    const [sourcePullTime, setSourcePullTime] = useState()
    const [targetPushTime, setTargetPushTime] = useState()
    const [targetPullTime, setTargetPullTime] = useState()

    const [sourcetoTarget, setSourcetoTarget] = useState(true)
    const [targettoSource, setTargettoSource] = useState(false)

    useEffect(() => {
        console.log(targetEntities)
        //$(".trdashboard").addClass("active")
        var response = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        }
        API.post("/Client/connections/sync-time",{
            "subscriptionInstanceId": instanceId,
            "sourceEndpointId": sourceId,
            "requestType": "Source"
        },{headers})
        .then(response => {
            setSourcePullTime(response.data.lastPullTime)
            setSourcePushTime(response.data.lastPushTime)
        })
        API.post("/Client/connections/sync-time",{
            "subscriptionInstanceId": instanceId,
            "sourceEndpointId": targetId,
            "requestType": "Target"
        },{headers})
        .then(response => {
            setTargetPullTime(response.data.lastPullTime)
            setTargetPushTime(response.data.lastPushTime)
        })
        API.post("/Client/connections/report/summary",
            {
                "subscriptionInstanceId": instanceId,
                "sourceEndpointId": sourceId,
                "requestType": "Source"
            },
            {headers}
        ).then(response =>{
            setSourceEntities(response.data)
        })
        API.post("/Client/connections/report/summary",
            {
                "subscriptionInstanceId": instanceId,
                "sourceEndpointId": targetId,
                "requestType": "Target"
            },
            {headers}
        ).then(response =>{
            setTargetEntities(response.data)
        })
    }, []);

    const flipPanel = () =>{
        setSourcetoTarget(!sourcetoTarget)
        setTargettoSource(!targettoSource)
        if(endPointId === sourceId){
            setEndPointId(targetId)
            setRequestType("Target")
        }else{
            setEndPointId(sourceId)
            setRequestType("Source")
        }
    }

    const filterData = (getFiltered) =>{
        setInstanceIdNew(getFiltered)
        setSourceEntities(getFiltered.sourceEntity)
        setTargetEntities(getFiltered.targetEntity)
    }

    return (
        <div className="main-content mn-dashboard">
            <div className="page-content custompaddingdash">
                <div className="container-fluid">
                    <BreadCrumb 
                        filterData={filterData}
                        instanceId={instanceId}
                        sourceId={sourceId}
                        targetId={targetId}
                        sourceShortName={Summary.sourceShortName}
                        targetShortName={Summary.targetShortName}
                    />
                    <div className="row">
                        <div className="col-sm-12 col-md-7 col-xl-6">
                            <div className="card mb-0">
                                <div className="p-4">
                                    <div className='row flip'>
                                        {sourcetoTarget ? <PanelWidget sourceShortName={Summary.sourceShortName} /> : <PanelWidget sourceShortName={Summary.targetShortName} /> }
                                        <div className="col-lg-2">
                                            <div className="card m-0 mrt-10">
                                                <div className="card-body card-body-customnew">
                                                    <div className='selectFlip' onClick={flipPanel}>
                                                    </div>
                                                    <Toggle ToggleImg={ToggleImg} />
                                                </div>
                                            </div>
                                        </div>
                                        {targettoSource ? <PanelWidget sourceShortName={Summary.sourceShortName} /> : <PanelWidget sourceShortName={Summary.targetShortName} /> }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {sourcetoTarget ? 
                            <PanelInfo 
                                sourceShortName={Summary.sourceShortName}
                                targetShortName={Summary.targetShortName}
                                pullTime={sourcePullTime && sourcePullTime}
                                pushTime={sourcePushTime && sourcePushTime}
                            />:
                            <PanelInfo 
                                sourceShortName={Summary.targetShortName}
                                targetShortName={Summary.sourceShortName}
                                pullTime={targetPullTime && targetPullTime}
                                pushTime={targetPushTime && targetPushTime}
                            />
                        }
                    </div>
                    <EntityTable
                        Entities={sourcetoTarget ? sourceEntities : targetEntities}
                        endPointId={endPointId}
                        sourceId={sourceId}
                        requestType={requestType}
                        instanceId={instanceId}
                    />
                </div>
            </div>
        </div>
    )
}
export default ViewDetails