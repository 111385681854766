import React, { useState } from 'react';
import Select from 'react-select';
import { Card, CardBody, Heading, Box, Button, Center, Text } from '@chakra-ui/react';
import { Spinner } from '@chakra-ui/react'
const VersionSelection = () => {
    const [selectedVersion1, setSelectedVersion1] = useState(null);
    const [selectedVersion2, setSelectedVersion2] = useState(null);
    const [selectedVersion3, setSelectedVersion3] = useState(null);
    const [error, setError] = useState(null);

    const options = [
        { value: '1.0', label: 'Version 1.0' },
        { value: '2.0', label: 'Version 2.0' },
        { value: '3.0', label: 'Version 3.0' },
    ];

    const handleSubmit = () => {
        if (!selectedVersion1 || !selectedVersion2 || !selectedVersion3) {
            setError('Please select all versions.');
        } else {
            setError(null);  // Clear any existing errors
            console.log('Selected Versions:', selectedVersion1, selectedVersion2, selectedVersion3);
            // Continue with further actions, such as API calls or state updates
        }
    };

    return (
        <Card mt={'20px'} border="1px solid" borderColor="gray.200" shadow="md">
            <CardBody>
                <Heading size="md" mb={4}>Select Versions</Heading>

                <Box mb={4}>
                    <Select 
                        options={options}
                        placeholder="Select Version 1"
                        value={selectedVersion1}
                        onChange={setSelectedVersion1}
                    />
                </Box>

                <Box mb={4}>
                    <Select 
                        options={options}
                        placeholder="Select Version 2"
                        value={selectedVersion2}
                        onChange={setSelectedVersion2}
                    />
                </Box>

                <Box mb={4}>
                    <Select 
                        options={options}
                        placeholder="Select Version 3"
                        value={selectedVersion3}
                        onChange={setSelectedVersion3}
                    />
                </Box>

                {error && (
                    <Text color="red.500" mb={4}>
                        {error}
                    </Text>
                )}

                <Center mt={6}>
                    <Button colorScheme="teal" onClick={handleSubmit}>Confirm Selection</Button>
                </Center>
            </CardBody>
        </Card>
    );
};

export default VersionSelection;