import {React, useEffect, useState} from 'react'
import $ from 'jquery'
import Style from './defaultmapping.module.css'
import API from '../../../../../API/API';
import DeleteMapping from '../DeleteMapping/DeleteMapping';
const DefaultMappingTable = (props) => {
    const [checked, setChecked] = useState(false);
    const [defaultMappings, setDefaultMappings] = useState()
    const [sourceMethods, setSourceMethods] = useState([])
    const [targetMethods, setTargetMethods] = useState([])
    useEffect(() => {
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };
        API.get(`/Connection/${props.connectorId}/instance/${props.instancesId}/shipping-methods/target`,{headers})
        .then((response) => {
            if(response.status === 200){
                setSourceMethods(response.data)
            }
        })
        API.get(`/Connection/${props.connectorId}/instance/${props.instancesId}/shipping-methods/source`,{headers})
        .then((response) => {
            if(response.status === 200){
                setTargetMethods(response.data)
            }
        })
        API.get(`/Connection/${props.connectorId}/instance/${props.instancesId}/shipping-mappings`,{headers})
        .then((response) => {
            if(response.status === 200){
                setDefaultMappings(response.data)
            }
        })
    },[])

    const updateMethod = (event) =>{
        if(event.target.checked === true){
            $(event.target).closest("td").attr("headers", "true")
        }else if(event.target.checked === false){
            $(event.target).closest("td").attr("headers", "false")
        }else{
            $(event.target).closest("td").attr("headers", event.target.value)
        }
    }

    return (
        <>
            <div className={Style.mappingtableContainer}>
                <table id='shippingMappingTable' width={"100%"}>
                    {
                        props.newlyAddedMapping && props.newlyAddedMapping.map(item => 
                            <tr> 
                                <td className={Style.selecterptd} headers={item.erpMethod}>
                                    <select className='form-control sourceMethod' onChange={updateMethod}>
                                        <option value={item.erpMethod}> {item.erpMethod}  </option>
                                        {
                                            sourceMethods && sourceMethods.map(item => 
                                                <option value={item}> {item} </option>
                                            )
                                        }
                                    </select>
                                </td>
                                <td className={Style.selectecommercetd} headers={item.ecommerceMethod}> 
                                    <select className='form-control sourceMethod' onChange={updateMethod}>
                                        <option value={item.ecommerceMethod} > {item.ecommerceMethod}   </option>
                                        {
                                            targetMethods && targetMethods.map(item => 
                                                <option value={item}> {item} </option>
                                            )
                                        }
                                    </select>
                                </td>
                                <td headers={item.isEcommerceDefault ? "true" : "false"}> 
                                    <div className="form-check">
                                            <input 
                                                className="custom-control-input form-check-input" 
                                                type="checkbox" 
                                                id={"sourcetotarget"} 
                                                defaultChecked={item.isEcommerceDefault}
                                                onChange={updateMethod}
                                            />
                                        <label className="custom-control-label form-check-label" for={"sourcetotarget"}>
                                            ERP-Ecom
                                        </label>
                                    </div>
                                </td>
                                <td headers={item.isErpDefault ? "true" : "false"}>
                                    <div className="form-check">
                                            <input 
                                                className="custom-control-input form-check-input" 
                                                type="checkbox" 
                                                id={"sourcetotarget"} 
                                                defaultChecked={item.isErpDefault}
                                                onChange={updateMethod}
                                            />
                                        <label className="custom-control-label form-check-label" for={"sourcetotarget"}>
                                            ERP-Ecom
                                        </label>
                                    </div>
                                </td>
                                <td> </td>
                            </tr>
                        )
                    }
                    {
                        defaultMappings && defaultMappings.map((item, index) =>
                            <tr>
                                <td className={Style.selecterptd} headers={item.erpMethod}>
                                    <select className='form-control sourceMethod' onChange={updateMethod}>
                                        <option value={item.erpMethod}> {item.erpMethod}  </option>
                                        {
                                            sourceMethods && sourceMethods.map(item => 
                                                <option value={item}> {item} </option>
                                            )
                                        }
                                    </select>
                                </td>
                                <td className={Style.selectecommercetd} headers={item.ecommerceMethod}> 
                                    <select className='form-control sourceMethod' onChange={updateMethod}>
                                        <option value={item.ecommerceMethod} > {item.ecommerceMethod}   </option>
                                        {
                                            targetMethods && targetMethods.map(item => 
                                                <option value={item}> {item} </option>
                                            )
                                        }
                                    </select>
                                </td>
                                <td headers={item.isEcommerceDefault ? "true" : "false"}>
                                    <div className="form-check">
                                        {
                                            <input 
                                                className="custom-control-input form-check-input" 
                                                type="checkbox" 
                                                id={"sourcetotarget"+index} 
                                                defaultChecked={item.isEcommerceDefault}
                                                onChange={updateMethod}
                                            />
                                        }
                                        <label className="custom-control-label form-check-label" for={"sourcetotarget"+index}>
                                            ERP-Ecom
                                        </label>
                                    </div>
                                </td>
                                <td headers={item.isErpDefault ? "true" : "false"}>
                                    <div className="form-check">
                                        <input 
                                            className="custom-control-input form-check-input" 
                                            type="checkbox" 
                                            id={"targettosource"+index} 
                                            defaultChecked={item.isErpDefault}
                                            onChange={updateMethod}
                                        />
                                        <label className="custom-control-label form-check-label" for={"targettosource"+index}>
                                            Ecom-ERP
                                        </label>
                                    </div> 
                                </td>
                                <td>
                                    <DeleteMapping />
                                </td>
                            </tr>
                        )
                    }
                </table>
            </div>
        </>
    )
}

export default DefaultMappingTable
