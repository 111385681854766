import React, { useEffect, useState } from 'react';
import Styles from './landingpage.module.css'
import {Link} from 'react-router-dom'
import { Amplify, Auth, Hub } from 'aws-amplify'
import API from '../../API/API';
import { Heading, Text, Container, Card, CardBody, SimpleGrid, Grid, GridItem, Button, Box, CardHeader } from '@chakra-ui/react';
import Header from './Header/Header';
import Connectors from './Connectors/Connectors';
import SelectedConnectorVersions from './Connectors/SelectedConnectorVersions/SelectedConnectorVersions';
import { Spinner } from '@chakra-ui/react'

const LandingPage = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedConnector, setSelectedConnector] = useState(null);
  
  useEffect(() => {
    API.get(`/Connector/TrialConnectors`,)
    .then((response) => {
      setData(response.data);
      setLoading(false)
    }).catch(error=>{
      setLoading(false)
    })
  }, []);

  return (
    <>
      <Header />
      {
        loading && loading ? 
          <Box className={Styles.parentBoxofLoader}>
            <Box w={'100%'} height={'100%'} position={'relative'}>
              <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
                position={'relative'}
                top={'50%'}
                transform={'translateY(-50%)'}
                left={'50%'}
              />
            </Box>
          </Box>
        : null
      }
      <Container m={'20px 0 0 0'} maxWidth={'100%'} h='400px'>
        <Card>
          <CardHeader>
            <Heading textAlign={'center'}> <Text as={'span'} color={'#1935c1'}> Available </Text><Text as={'span'} color={'#dc332e'}> Trial Connectors </Text> </Heading>
          </CardHeader>
          <CardBody>
            {error && <p>Error: {error}</p>}
            {!loading && !error && data && (
              <>
                {!selectedConnector ? (
                  <SimpleGrid columns={[2, 3, 4]} spacing='40px'>
                    {data.map((item, index) => (
                      <Link to={{
                          pathname: `connector/${item.id}/${item.name}/`,
                          state: {
                            imageContent: item.imageContent,
                            index:index,
                            name:item.name,
                            image:item.imageContent,
                            sourceShortName:item?.source?.shortName,
                            targetShortName:item?.target?.shortName
                          }
                        }}
                      >
                        <Box
                          key={index}
                          position="relative"
                          overflow="hidden"
                          transition="transform 0.2s ease"
                          _hover={{ transform: 'scale(1.05)' }}
                        >
                          <Connectors
                            index={index}
                            name={item.name}
                            image={item.imageContent}
                            sourceShortName={item?.source?.shortName}
                            targetShortName={item?.target?.shortName}
                          />
                        </Box>
                      </Link>
                    ))}
                  </SimpleGrid>
                ) : (
                  <SelectedConnectorVersions 
                    connector={selectedConnector} 
                    onBack={() => setSelectedConnector(null)} // Function to clear selected connector
                  />
                )}
              </>
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default LandingPage;