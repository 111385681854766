import React from 'react'

const FinishSetup = () => {
    return (
        <button 
            type='button' 
            className="btn btn-primary btn-rounded waves-effect waves-light float-right finishSetupbtn"
            onClick={() => window.location.reload(false)}
        >
            Finish Setup
        </button>
    )
}

export default FinishSetup