import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import './css/Sidebar.css'
import Logo from './Logo/Logo'
import NavigationMenu from './NavigationMenu/NavigationMenu'
import API from '../../API/API'
const SideBar = () => {

  const [data, setData] = useState()
  
  useEffect(() => {
    var response1 = window.localStorage.getItem("signInUserSessionidToken");    
    const headers = {
      "authorization": `Bearer ${response1}`,
      "content-type": "application/json",
    };
    const body = {}
    API.post(`/Account/client`, body, { headers})
    .then(response =>{
      setData(response.data)
    })
  },[]);

  return (
    <div className="vertical-menu">
        <Logo />
        <NavigationMenu />
    </div>
  )
}

export default SideBar
