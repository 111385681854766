import React from 'react'

const AdvancedB2BModules = () => {
    return (
        <div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
            <p>
                With features unique to B2B businesses, i95Dev’s EGE helps create a seamless customer experience.
                The advanced functionalities play a critical role in the success of a B2B eCommerce platform.
            </p>
        </div>
    )
}

export default AdvancedB2BModules
