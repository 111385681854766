import React from 'react'

const ScaleYourBusiness = () => {
  return (
    <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
        <p>
            With streamlined business processes and workflows across various functional departments, 
            EGE helps scale and achieve your business objectives at a much faster pace. All the key business information,
            customer data and order details are synchronized and can be accessed at ease making the entire process hands-on. 
        </p>
    </div>
  )
}

export default ScaleYourBusiness