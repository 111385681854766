import React, { useEffect } from 'react';
import './css/Header.css'
import Headerbg from './Headerbg'
import Navbar from './Navbar/Navbar';
import API from '../../API/API'

const Header = () => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  return (
    <header id="page-topbar">
      <Headerbg />
      <Navbar />
    </header>
  )
}
export default Header
