import React,{useState} from 'react'
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "./addConnector.css"
import API from '../../../API/API'
import AddConnector from './AddConnector';
import SelectConnectorSkeleton from '../../../SharedComponents/Skeletons/SelectConnectorSkeleton';

const ConnectorType = () => {
    const [connectorType, setConnectorType] = useState([])
    const [connectorTypeImg, setConnectorTypeImg] = useState([])
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState({
        isPaneOpen: false,
    });
    const slidingPaneClose = () =>{
        setState({ isPaneOpen: false })    
    }
    const addConnectors = ()=> {
        setState({ isPaneOpen: true })
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };
        API.get("/MarketPlace/endpoints",{headers})
        .then((response) => {
            if(response.status === 200){
                setConnectorType(response.data)
                setLoading(true)
                const images = response.data;
                Promise.all(images.map(image => API.get(
                    `/MarketPlace/endpoints/${image.id}/image`,{headers}
                )))
                .then(resp =>resp)
                .then(result => {
                    setConnectorTypeImg(result)
                    setLoading(false)
                })
            }
        })
    }
    return (
        <>
            <button type="button" className="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector" onClick={addConnectors}>
                + New Connector
            </button>
            <SlidingPane overlayClassName="some-custom-overlay-class" isOpen={state.isPaneOpen} onRequestClose={() => {setState({ isPaneOpen: false });}} title="Add Connector" subtitle="Select a Connector">
                {loading ? <SelectConnectorSkeleton /> : <AddConnector connectorType={connectorType} connectorTypeImg={connectorTypeImg}  /> }
            </SlidingPane>
        </>
    )
}

export default ConnectorType
