import React from 'react'

const Toggle = (props) => {
    return (
        <div className="customToggleButton" >
            <img src={props.ToggleImg} className='toggleImg' />
        </div>
    )
}

export default Toggle
