import React from 'react'
import './marketplace.css'
import Panel from './Subcomponents/Panel'
const MarketPlace = () => {

    return (
        <div className="main-content marketplace">
            <div className="page-content pb-0">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-flex align-items-center justify-content-between mb-3 pb-0">
                                <h4 className="mb-0">Market Place</h4>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <Panel />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MarketPlace

