import React, { useState } from 'react'
import ConnectorInfo from '../../Pages/Dashboard/ConnectorPanel/Connectors/SelectedConnector/SubComponents/ConnectorInfo'
import BusinessEntities from './BusinessEntities/BusinessEntities'
import SetupWarning from '../../Alerts/WarningComponent/SetupWarning'
import SetupAction from '../../Pages/Dashboard/ConnectorPanel/Connectors/SelectedConnector/SetupAction/SetupAction'
import { NavLink, Link } from 'react-router-dom'
const Details = (props) => {
    const configuration = () => {
        localStorage.setItem("configureConnectorId", props.connectorId)
        localStorage.setItem("configureInstanceId", props.instancesId)   
    }
    return (
        <>
            <div className='col-lg-8'>
                <div className='card mndesc'>
                    <BusinessEntities
                        businessEntitiesSource={props.businessEntitiesSource}
                        businessEntitiesTarget={props.businessEntitiesTarget}
                        sourceName={props.sourceName}
                        targetName={props.targetName}
                        sourceShortName={props.sourceShortName}
                        targetShortName={props.targetShortName}
                        instancesId={props.instancesId}
                        sourceId={props.sourceId}
                        targetId={props.targetId}
                    />
                    {!props.isSetupCompleted ?
                        <>
                            {/* <SetupWarning /> */}
                        </> : null
                    }
                </div>
            </div>
            <div className='col-lg-4'>
                <div className='card mndesc'>
                    <div className="card-header">Meta Data<a className="float-right font-size-10"></a></div>
                    <div className='card-body'>
                        <ConnectorInfo
                            sourceName={props.sourceName}
                            sourceVersion={props.sourceVersion}
                            targetName={props.targetName}
                            targetVersion={props.targetVersion}
                            featureName={props.featureName}
                        />
                        <div className="otheroptions mt-1">
                            <h6> Other Options </h6>
                            {//props.isSetupCompleted ?
                                <>
                                    <div className={"actionButton"}>
                                        <SetupAction
                                            sourceType = {props.sourceType}
                                            targetType = {props.targetType}
                                            targetShortName={props.targetShortName}
                                            connectorId={props.connectorId}
                                            instancesId = {props.instancesId}
                                        /> 
                                    </div>
                                </> //: null
                            }
                            <Link className="customDownloadnew" to={{ pathname: "/configurations"}} onClick={configuration}>Configuration</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Details