import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import './awssetup.css'
import DropDown from './SubComponents/DropDown';
import InputText from './SubComponents/InputText';
import Validations from './SubComponents/Validations/Validations';
import SlidingPane from "react-sliding-pane";
import MagentoInfo from '../MagentoInfo/MagentoInfo';
import Next from './SubComponents/Next';

const AwsInfo = (props) => {
  const [awsDetails, setAwsDetails] = useState()
  const [isPaneOpen, setIsPaneOpen] = useState(false)

  const getFieldValue = (event) =>{
    setAwsDetails({...awsDetails, [event.target.name]: event.target.value})
  }
  const getAwsInfo = (event) => {
    setIsPaneOpen(!isPaneOpen)
  }
  const submitValues = (event) => {
    const {...inputfields} = awsDetails;
    event.preventDefault();
  }


  return (
    <div className="card mt-3 m-0 no-boxshadow no-border">
      <div className="card-body no-padding">
        <form id="aws_setup" name="awsSetup" onSubmit={submitValues}>
          <div className="row">
            {
              props.egeQuestions && props.egeQuestions.slice().reverse().map((item, index) => 
                item.groupName === "aws" ?
                  <div className="col-md-6">
                    {
                      (item.type === "DropDown" || item.type === "Dropdown")?
                        <div className="form-group awsGroup validClass">
                          {/* <DropDown id={item.code} name={item.id} label={item.text} options={item.questionOptions} getAllFieldValues={getAllFieldValues} /> */}
                          <select className="form-control aws_version-field version-field" id={item.code} name={item.id} onChange={getFieldValue}>
                              <option value="" data-default>{item.text}</option>
                              {item.questionOptions.map(value=>
                                  <option value={value.code} >{value.displayName}</option>
                              )}
                          </select>
                        </div>
                      : 
                      (item.type === "Text" || item.type === "text")?
                        <div className="form-group awsGroup validClass">
                          {/* <InputText id={item.code} name={item.id} label={item.text} getAllFieldValues={getAllFieldValues}/> */}
                          <input type="text" 
                            className="form-control aws_version-field version-field awsNext" 
                            name={item.id}
                            placeholder={item.text}
                            id={item.code}
                            onChange={getFieldValue}
                          />
                          <Validations />
                        </div>
                      :null
                    }
                  </div> 
                : null
              )
            } 
          </div>
          <div className="row">
              <div className="col-lg-12 float-right">
                  <button id="awsSubmitnew" className="btn btn-primary btn-rounded waves-effect waves-light float-right awsnext" onClick={getAwsInfo}> Next </button>
              </div>
          </div>
          <SlidingPane overlayClassName="some-custom-overlay-class" isOpen={isPaneOpen} onRequestClose={() => {setIsPaneOpen(false);}} title="Add Connector" subtitle="Magento Details">
            <MagentoInfo 
              egeQuestions={props.egeQuestions} 
              awsDetails={awsDetails} 
              name={props.name}
              sourceName={props.sourceName}
              targetName={props.targetName} 
              connectorInfo={props.connectorInfo} 
              instance = {props.instance}
              id={props.id}
            />
          </SlidingPane>
          {/* <Next 
            egeQuestions={props.egeQuestions} 
            awsDetails={awsDetails} 
            name={props.name}
            sourceName={props.sourceName}
            targetName={props.targetName} 
            connectorInfo={props.connectorInfo} 
            instance = {props.instance}
            id={props.id}
          /> */}
        </form>
      </div>
    </div>
  )
}
export default AwsInfo