import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import Pagination from "react-js-pagination";
import './summaryDetails.css'
import BreadCrumb from './BreadCrumb/BreadCrumb'
import API from '../../../../API/API'
import DetailsTable from './DetailsTable/DetailsTable'

const SummaryDetails = () => {
    const [detailPost, setDetailPost] = useState(JSON.parse(localStorage.getItem("summaryDetailsInfo")))
    const [page, setPage] = useState()
    const [details, setDetails] = useState()
    const [totalRecords, setTotalRecords] = useState()
    const [activePage, setActivePage] = useState(1)
    const [updatedStart, setUpdatedStart] = useState(null)
    const [updatedEnd, setUpdatedEnd] = useState(null)

    useEffect(() => {
        //$(".trdashboard").addClass("active")
        var response = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json",
        };
        const body = detailPost
        API.post(`/Client/connections/report/details`, body, { headers })
        .then(response =>{
            setPage(response.data.page)
            setDetails(response.data.records)
            setTotalRecords(response.data.totalRecords)
        })
    }, []);

    const getStartDate = (startDate) => {
        var newstartDate = new Date(startDate),
        newstartDatemnth = ("0" + (newstartDate.getMonth() + 1)).slice(-2),
        newstartDateday = ("0" + newstartDate.getDate()).slice(-2);
        setUpdatedStart([newstartDate.getFullYear(), newstartDatemnth, newstartDateday].join("-"))
    }
    const getEndDate = (endDate) => {
        var convertedendDate = new Date(endDate),
        newendDatemnth = ("0" + (convertedendDate.getMonth() + 1)).slice(-2),
        newendDateday = ("0" + convertedendDate.getDate()).slice(-2);
        setUpdatedEnd([convertedendDate.getFullYear(), newendDatemnth, newendDateday].join("-"))
    }

    const applyDates = (appliedDates) => {
        setPage(appliedDates.page)
        setDetails(appliedDates.records)
        setTotalRecords(appliedDates.totalRecords)
    }

    
    const paginationCheck = (pageNumber) =>{
        setActivePage(pageNumber)
        var response = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json",
        };
        const body = {
            entityIds: detailPost.entityIds,
            page: pageNumber,
            pageSize: 10,
            requestType:detailPost.requestType,
            sourceEndpointId:detailPost.sourceEndpointId,
            statusIds:detailPost.statusIds,
            subscriptionInstanceId: detailPost.subscriptionInstanceId,
            "updatedTime": {
                "fromTime":  updatedStart,
                "toTime": updatedEnd
            }
        }
        API.post(`/Client/connections/report/details`, body, { headers })
        .then(response =>{
            setPage(response.data.page)
            setDetails(response.data.records)
            setTotalRecords(response.data.totalRecords)
        })
    }

    return (
        <div className="main-content mn-summaryDetails">
            <div className="page-content custompaddingdash">
                <div className="container-fluid">
                    <BreadCrumb 
                        applyDates={applyDates}
                        getStartDate={getStartDate}
                        getEndDate={getEndDate}
                    />
                    <DetailsTable details={details} page={page} totalRecords={totalRecords} requestType={detailPost.requestType} />
                    {totalRecords > 10 ?
                        <Pagination
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={totalRecords}
                            pageRangeDisplayed={5}
                            onChange={paginationCheck}
                        /> : null
                    }
                </div>
            </div>
        </div>
    )
}

export default SummaryDetails