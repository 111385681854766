import React from 'react'

const SourceInfo = (props) => {
  return (
        <div className="col-md-7 col-xl-6">
            <div className="pushandpullstatus">
                <div className='pushContainer'>
                    <h5 className="sourcetotargettitle"> Transferred from <b>{props.sourceShortName} to {props.targetShortName}</b> </h5>
                    <span className="last-pull">
                        <span className="fixedWidthnewLeft"> Last Pull </span>
                        <span className="deviderColon"> : </span>
                        <b>{props.pullTime === null ? <span> Not updated yet </span>: props.pullTime && props.pullTime.split('.')[0]}</b>
                    </span>
                    <span className="last-push">
                        <span className="fixedWidthnewLeft"> Last Push </span>
                        <span className="deviderColon"> : </span>
                        <b> {props.pushTime === null ? <span> Not updated yet </span>:  props.pushTime && props.pushTime.split('.')[0]} </b>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default SourceInfo
