import React from 'react'
import Integrations from '../Integrations/Integrations'

const SelectConnector = (props) => {
  const integrationType = props.integrationType
  const integrationImg = props.integrationImg
  const imagesi = integrationImg.map((item)=>item.data);

  return (
    <div className="row mt-3 padding-20-new">
      {
        integrationType.map((connector, index) => 
          <Integrations
            id = {connector.id}
            name = {connector.name}
            sourceShortName = {connector.source.shortName}
            targetShortName = {connector.target.shortName}
            imagesi = {imagesi[index]}
          />
        )
      }
    </div>
  )
}

export default SelectConnector
