import React, { useState } from 'react'
import TableRow from './TableRow'

const TableBody = (props) => {
    
    return (
        <tbody>
            {
                props.details && props.details.map((item, index) => 
                    <TableRow 
                        entityName = {item.entityName}
                        messageId = {item.messageId}
                        targetId = {item.targetId}
                        sourceId = {item.sourceId}
                        statusName = {item.statusName}
                        updatedTime = {item.updatedTime}
                        syncCounter = {item.syncCounter}
                        reference = {item.reference}
                        errorMessage={item.errorMessage}
                        index={index}
                    />
                )
            }
        </tbody>
    )
}
export default TableBody