import React, { useState } from 'react'
import $ from 'jquery'
import API from '../../../../../API/API'
import TableHeadings from './TableHeadings'
import TableBody from './TableBody'
const DetailsTable = (props) => {
    const reSync = () => {
        $("#loaderContainer").removeClass("d-none")
        var reSyncList = []
        $("input:checkbox[name=resync]:checked").each(function(){
            reSyncList.push(JSON.parse($(this).val()));
        });      
        var response = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        const body = {
            "requestType": props.requestType,
            "messageId": reSyncList
        }
        API.post("/Client/ManualSync", body, {headers})
        .then(response =>{
            response.data.result ? $(".successErrorMessage").removeClass("d-none") : $(".failuerErrorMessage").removeClass("d-none")
            if(response.data.result === true){
                $("#loaderContainer").addClass("d-none")
                setTimeout(function(){
                    window.location.reload(false)
                }, 10000);
            }
        })
    }
    return (
        <div className="row mt-4">
            <div className="col-lg-12">
                <button id={"SelectedreSync"} className="btn btn-primary mb-2 float-right d-none" name={props.requestType} onClick={reSync}> Re-sync all selected records </button>
                <button id={"defaultSelectedreSync"} className="btn btn-primary mb-2 float-right" disabled> Re-sync all selected records </button>
            </div>
            <div className="col-lg-12">
                <div className="alert alert-success successErrorMessage d-none" role="alert">
                    Checked list successfully re-synced, Please wait, we are reloading the page in 10 seconds 
                </div>
                <div className="alert alert-danger failuerErrorMessage d-none" role="alert">
                    Selected record(s) are processing please wait some time
                </div>
                <div className="card no-padding">
                    <div className="card-body no-padding">
                        <div className="table-responsive">
                            <table className="viewSummaryNew table mb-0">
                                <TableHeadings />
                                <TableBody details={props.details} />
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DetailsTable




