import React from 'react'
import TableRow from './TableRow'
const TableBody = (props) => {
    return (
        <tbody>
            {
                props.Entities.map((item, index) => 
                    <TableRow 
                        entityId={item.entityId}
                        entityName={item.entityName}
                        requestReceivedCount={item.requestReceivedCount}
                        inProcessCount={item.inProcessCount}
                        responseReceivedCount={item.responseReceivedCount}
                        responseTransferredCount={item.responseTransferredCount}
                        errorCount={item.errorCount}
                        completeCount={item.completeCount}
                        totalCount={item.totalCount}
                        endPointId={props.endPointId}
                        sourceId={props.sourceId}
                        requestType={props.requestType}
                        instanceId={props.instanceId}
                    />
                )
            }
        </tbody>
    )
}

export default TableBody