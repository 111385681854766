import React from 'react'
import '../../../Installers/Installers.css'
import InfoComponent from '../../../../../Alerts/InfoComponent/InfoComponent'
import * as setupCompleted from '../../../../../Constants/Constants'
import ConnectorInfo from '../../ConnectorInfo'
import FinishSetup from '../../../Installers/FinishSetup'
import SourceInstaller from '../../DownloadInstaller/SourceInstaller/SourceInstaller'
import TargetInstaller from '../../DownloadInstaller/TargetInstaller/TargetInstaller'

const ChildDownload = (props) => {
    return (
        <>
            <div className='card-body p-0'>
                {
                    props.connectorInfo ? 
                    <>
                        <div className='downloadComponent'>
                            <ConnectorInfo
                                name={props.connectorInfo.name && props.connectorInfo.name}
                                sourceName={props.connectorInfo.source.name}
                                sourceVersion={props.connectorInfo.source.version}
                                targetName={props.connectorInfo.target.name}
                                targetVersion={props.connectorInfo.target.version}
                                productType={props.connectorInfo.instances[0].instanceType}
                            />
                            <div className="installerComponent">
                                <SourceInstaller
                                    installerName={props.connectorInfo.source.name}
                                    installerVersion={props.connectorInfo.source.version}
                                    installerSize={props.connectorInfo.source.installer.size}
                                    installerType = {props.connectorInfo.source.type}
                                    connectorId = {props.connectorInfo && props.connectorInfo.id}
                                    instanceId =  {props.connectorInfo && props.connectorInfo.instances[0].id}
                                    subscriptionId = {props.connectorInfo && props.connectorInfo.subscriptionId}
                                    sourceId = {props.connectorInfo && props.connectorInfo.source.id}
                                    fileType = {props.connectorInfo && props.connectorInfo.source.installer.type}
                                />
                                <TargetInstaller 
                                    installerName={props.connectorInfo.target.name}
                                    installerVersion={props.connectorInfo.target.version}
                                    //installerSize={props.connectorInfo.target.installer.size}
                                    installerType = {props.connectorInfo.target.type}
                                    connectorId = {props.connectorInfo && props.connectorInfo.id}
                                    instanceId =  {props.connectorInfo && props.connectorInfo.instances[0].id}
                                    subscriptionId = {props.connectorInfo && props.connectorInfo.subscriptionId}
                                    sourceId = {props.connectorInfo && props.connectorInfo.target.id}
                                    //fileType = {props.connectorInfo && props.connectorInfo.target.installer.type}
                                />
                            </div>
                        </div>
                    </> : null
                }
            </div>
        </>
    )
}

export default ChildDownload
