import React from 'react'
import $ from 'jquery'

const DeleteMapping = () => {
    
    function deleteMapping (event){
        $(event.target).closest("tr").remove()
    }

    return (
        <>
            <button type="button" className="deleteMapping" onClick={deleteMapping}>
                <span className='deleteMappingNew'></span>
                <span id="shippingMapping0"></span>
                <i className="uil-trash-alt"></i>
            </button> 
        </>
    )
}

export default DeleteMapping
