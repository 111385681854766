import React, { useEffect, useRef, useState } from 'react'
import './input.css'
import $ from 'jquery'
const Input = (props) => {
    const inputRef = useRef(null)
    const [value, setValue] = useState("")
    
    const valueHandler = (event) => {
        $("#defaultQuestioneries tr#newSavequestions"+event.target.id+" td").text(event.target.value)
    }

    return (
        <div className="form-group">
            <label className='floatingLabel'> {props.placeholder} </label>
            <input 
                type="text"
                className="form-control"
                title={props.title}
                placeholder={props.placeholder}
                name={props.placeholder}
                id={props.id}
                defaultValue={props.defaultValue}
                onChange={valueHandler}
            />
        </div>
    )
}
export default Input