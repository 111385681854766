import React from 'react'
const TableHeadings = () => {
    return (
        <thead className="thead-light">
            <tr>
                <th>Entity</th>
                <th>Message Id</th>
                <th>Target Id</th>
                <th>Source Id</th>
                <th>Status</th>
                <th>Time</th>
                <th>Sync Counter</th>
                <th>Reference</th>
                <th>Action</th>
            </tr>
        </thead>
    )
}
export default TableHeadings