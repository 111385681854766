import React,{useEffect, useState} from 'react'
import API from '../../../../API/API'
import EntityName from './EntityName/EntityName'
import IsInboundActive from './IsInboundActive/IsInboundActive'
import IsOutboundActive from './IsOutboundActive/IsOutboundActive'
const Tbody = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [entities, setEntities] = useState()
    useEffect(() => {
        setIsLoading(true)
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };
        API.get(`/Connection/${props.connectorId}/instance/${props.instancesId}/entities`,{headers})
        .then((response) => {
            if(response.status === 200){
                setEntities(response.data)
                setIsLoading(false)
            }
        })
    },[])
    const getStatus = (changeEntityStatus) =>{
        if(changeEntityStatus === 200){
            if(changeEntityStatus.data.message === null){
                //Entity Updated successfully
            }
        }
    }
    return (
        <div className='entityBody table-bordered mappingtableContainer'>
            {isLoading ?
                <div className="loaderNewContainer">
                    <div className="loading-spinner"></div>
                </div> : null
            }
            <table width={"100%"} className='table table-striped'>
                <tbody>
                    {
                        entities && entities.map((entity, index) => 
                            <tr key={index}>
                                <EntityName entityName={entity.displayName} />
                                <IsInboundActive
                                    id={entity.isOutboundActive} 
                                    isInboundActive={entity.isInboundActive}
                                    isInboundEditable={entity.isInboundEditable}
                                    name={entity.id}
                                    connectorId={props.connectorId}
                                    instancesId={props.instancesId}
                                    getStatus={getStatus}
                                    getMessage={props.getMessage}
                                />
                                <IsOutboundActive
                                    id={entity.isInboundActive} 
                                    isOutboundActive={entity.isOutboundActive}
                                    isOutboundEditable={entity.isOutboundEditable}
                                    name={entity.id}
                                    connectorId={props.connectorId}
                                    instancesId={props.instancesId}
                                    getStatus={getStatus}
                                    getMessage={props.getMessage}
                                />
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}
export default Tbody
