import React from 'react'
import nav_gp from './images/nav_gp.svg'
import magento from './images/magento.svg'
import bc from './images/bcnew.png'
import EGE from './images/EGE.svg'
import bigcommerce from './images/bigcommerce.png'
import qb from './images/qb_thumb.png'
import shopify from './images/shopify.svg'

const ConnectorImage = (props) => {
    return (
        <div className="mr-2">
            <div className="img-cont-full img-cont-1">
                {
                    props.sourceShortName === "EGE" ? <img src={EGE} alt={props.sourceShortName} /> : 
                    props.sourceShortName === "Magento" ? <img src={magento} alt={props.sourceShortName}/> : 
                    (props.sourceShortName === "Nav") || (props.sourceShortName === "GP") || (props.sourceShortName === "AX") ? <img src={nav_gp} alt={props.sourceShortName}/> : 
                    (props.sourceShortName === "BC") || (props.sourceShortName === "Business Central") ? <img src={bc} /> :
                    props.sourceShortName === "BigCommerce" ? <img src={bigcommerce} alt={props.sourceShortName}/> : 
                    props.sourceShortName === "QuickBooks" ? <img src={qb} alt={props.sourceShortName}/> : 
                    props.sourceShortName === "Shopify" ? <img src={shopify} alt={props.sourceShortName}/> : <div className='imgPlaceholderNew'> {props.sourceShortName} </div>}
            </div>
            <div className="img-cont-full img-cont-2">
                {
                    props.targetShortName === "EGE" ? <img src={EGE} /> : 
                    props.targetShortName === "Magento" ? <img src={magento} /> : 
                    (props.targetShortName === "Nav") || (props.targetShortName === "GP") || (props.targetShortName === "AX") ? <img src={nav_gp} /> : 
                    (props.targetShortName === "BC") || (props.targetShortName === "Business Central") ? <img src={bc} /> :
                    props.targetShortName === "BigCommerce" ? <img src={bigcommerce} /> : 
                    props.targetShortName === "QuickBooks" ? <img src={qb} /> : 
                    props.targetShortName === "Shopify" ? <img src={shopify} /> : <div className='imgPlaceholderNew'> {props.targetShortName} </div>
                }
            </div>
        </div>
    )
}

export default ConnectorImage