import React,{ useState } from 'react'
import $ from "jquery"
import Select from 'react-select';

const ERPVersions = (props) => {
    const [selectedOption, setSelectedOption] = useState(null);
    var erpVersionOptionsMapping = props.versions.reduce((unique, o) => {
        if(!unique.some(obj => obj.sourceVersion === o.sourceVersion)) {
          unique.push(o);
        }
        return unique;
    },[]);

    const erpVersionOptions = erpVersionOptionsMapping.map((item, index) =>(
        {value :item.sourceVersion, label: item.sourceVersion}
    ))
    const selectErp = (event) =>{
        setSelectedOption(event.value)
        if(event.value != selectedOption){
            props.passData(event.value);
            $("#selectEcommVersion .css-319lph-ValueContainer").prepend('<div className=" css-14el2xx-placeholder" id="react-select-23-placeholder">Select Ecommerce Version</div>')
            $("#selectEcommVersion .css-qc6sy-singleValue").text("")
            $("#selectProductType .css-319lph-ValueContainer").prepend('<div className=" css-14el2xx-placeholder" id="react-select-13-placeholder">Select Product Type</div>')
            $("#selectProductType .css-qc6sy-singleValue").text("")
            $("#selectERPVersion").addClass("notEmptyfield")
            $("#selectEcommVersion").removeClass("notEmptyfield")
            $("#selectProductType").removeClass("notEmptyfield")
            setSelectedOption(null)
        }
    }

    return (
        <div className="form-group">
            <Select 
                id="selectERPVersion"
                className='form-control version-field'
                defaultValue={selectedOption}
                options={erpVersionOptions}
                placeholder ={"Select ERP Version"}
                onChange={selectErp}
            />
        </div>
    )
}

export default ERPVersions