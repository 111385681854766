import React from 'react'
import Select from '../SelectConnector/Select'

const ConnectorList = (props) => {
    
    return (
        <div id={"selectendpoint"+props.shortName} className='col-lg-3 mb-3 conhover'>
            <div className="connector-listing">
                <Select id={props.id} />
                <div className="connector-logo-container">
                    <img src={props.image} />
                </div>
                <h5 className="text-truncate add-connector-title"> {props.shortName} </h5>
            </div>
        </div>
    )
}

export default ConnectorList
