import React from 'react'
import { Auth } from 'aws-amplify'
const Logout = () => {
    const handleLogout = (logoutType) => {
        Auth.signOut();
    }
    return (
        <a className="dropdown-item" onClick={() => handleLogout("redirect")} key="logoutRedirect">
            <i className="uil uil-sign-out-alt font-size-18 align-middle mr-1 text-muted"></i>
            <span className="align-middle" >Sign out</span>
        </a>
    )
}

export default Logout
