import React from 'react'
import Imagecomponent from './Imagecomponent'


const PanelWidget = (props) => {
    return (
        <div className='col-lg-5'>
            <div className='row brpd'>
                <div className='col-md-6'>
                    <Imagecomponent name={props.sourceShortName} />
                </div>
                <div className='col-md-6 position-relative'>
                    <span className='summaryTitle position-relative' title={props.sourceShortName}>{props.sourceShortName}</span>
                </div>
            </div>
        </div>
    )
}
export default PanelWidget