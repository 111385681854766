import React from 'react'
import SelectEGEConnector from './SelectEGEConnector/SelectEGEConnector'
const EgeSetup = () => {
    return (
        <>
            <h3 className="newconnector-title">
                EGE Setup
            </h3>
            <SelectEGEConnector />
        </>
    )
}
export default EgeSetup