import React,{useState} from 'react'
import closeicon from '../images/close-icon.svg';
import SlidingPane from "react-sliding-pane";
import EgeSetup from './EgeSetup/EgeSetup';

const CreateYoursNow = () => {
  const [isPaneOpen, setIsPaneOpen] = useState(false)
  const SetupEGE = () => {
    setIsPaneOpen(true)
  }
  const ClosePane = () =>{
      setIsPaneOpen(false)
  }
  return (
    <>
      <button type="button" className="create-yours-now" onClick={SetupEGE}> Create Yours Now </button>
      <SlidingPane className='connector-popup ege-learnmore' overlayClassName='some-custom-overlay-class' isOpen={isPaneOpen}>
        <button className="popup-close" onClick={ClosePane}>
          <img src={closeicon} />
        </button>
        <EgeSetup />
      </SlidingPane>
    </>
  )
}

export default CreateYoursNow