import React, { useState } from 'react'
import API from '../../../../../API/API'
const SetupInstaller = (props) => {
    const [targetMessage, setTargetMessage] = useState(0)
    const [count, setCount] = useState(0)
    const sourceSetup = () => {
        setTargetMessage("2%")
        setCount(count+1)
        var response =window.localStorage.getItem("accessToken");
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json",
        };
        API.get('/User/Token/Check', { headers })
        .then((response) => {
            if(response.status === 200){
                if(response.data.result == false){
                    API.get('/User/Authorize', { headers })
                    .then((response) => {
                        if(response.status === 200){
                            if(count < 5){
                                var win = window.open(response.data, "", "width=500,height=500");
                                setTimeout(function () { win.close(); }, 10000);
                            }else {
                                var html = "<html><head></head><body> Authentication failed : The Request could not be completed </body></html>"
                                var win = window.open(response.data, "", "width=500,height=500");
                                win.document.write(html)
                                setTimeout(function () { win.close(); }, 10000);
                            }
                        }
                    })
                    setTimeout(function(){
                        if(count < 5){
                            sourceSetup()
                        }else{
                            setCount(0)
                        }
                    }, 11000)
                }else{
                    var mnTocken = window.localStorage.getItem("accessToken");
                    const body = {
                        "subscriptionId": props.subscriptionId,
                        "endpointId": props.endpointId,
                        "instanceId": props.instanceId,
                        "fileType": props.fileType,
                        "refreshToken": mnTocken,
                    }
                    API.post("/Client/connections/setup", body, {headers})
                    .then((response) => {
                        if(response.status === 200){
                        }
                    })
                }
            }
        })
    }
    return (
        <a className="cursor-pointer" onClick={sourceSetup}> Setup </a>
    )
}

export default SetupInstaller
