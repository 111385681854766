import React from 'react'
import './learnmore.css'
import TabsList from './TabsList'
import TabsContent from './TabsContent/TabsContent'
const LearnMoreTabs = () => {
    return (
        <div className="row viewdetails">
            <div className="col-lg-12">
                <div className="card no-boxshadow no-border">
                    <div className="card-header">
                        <h3 className="heading"> EGE - Benefits </h3>
                    </div>
                    <div className="card-body pt-0">
                        <div className="row">
                            <div className="card no-boxshadow no-padding no-border">
                                <div className="card-body no-boxshadow no-padding no-border">
                                    <div className="row pt-3">
                                        <TabsList />
                                        <TabsContent />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>                
        </div>
    )
}

export default LearnMoreTabs