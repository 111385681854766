import React, { useEffect, useState } from 'react'
import SlidingPane from "react-sliding-pane";
import API from '../../../../API/API';
import FeatuerWarning from '../../../../Alerts/WarningComponent/FeatuerWarning'
import NoFeatuer from '../../../../Alerts/WarningComponent/NoFeatuer'
import FeatuerContainer from './FeatuerContainer'
import ConfirmandProceed from './ConfirmandProceed/ConfirmandProceed'
import Installers from '../../Installers/Installers';
const Featuers = (props) => {
    const [state, setState] = useState({
        isPaneOpen: false,
    });
    const [confirm, setConfirm] = useState(false)
    const [subscription, SetSubscription] = useState()
    const [instance, SetInstance] = useState()
    const featuerData = props.featuers

    const confirmProcess = (getConfirm) => {
        setConfirm(featuerData)
        if(getConfirm === true){
            var response = window.localStorage.getItem("accessToken");
            const headers = {
                "authorization": `Bearer ${response}`,
                "content-type": "application/json"
            };
            const body = {
                connectorVersionId : props.connectorInfo && props.connectorInfo.productTypeValue,
                "connectorName": props.name,
                "sourceVersion": props.connectorInfo.erpVersion,
                "targetVersion": props.connectorInfo.ecommVersion
            }

            API.post(`/Subscription`, body, { headers })
            .then((response) => {
                if(response.status === 200){
                    SetSubscription(response.data)
                    API.get(`Client/connections/${response.data.connectorId}/instances/${response.data.instanceId}`, { headers })
                    .then((response1 => {
                        if(response1.status === 200){
                            SetInstance(response1.data)
                            setState({ isPaneOpen: true })
                        }
                    }))
                }
            })
        }
    }

    return (
        <>
            <div className='col-lg-6 mb-3'>
                <div className='card featuer-container pb-0 mb-0'>
                    <div className="card-header pb-0">
                        <h2 className="featuer-header"> Features </h2>
                    </div>
                    <div className='card-body'>
                        {
                            props.featuers && props.featuers.length >= 1 ? 
                            <FeatuerContainer featuerData={props.featuers} targetName={props.targetName} sourceName={props.sourceName} />:
                            props.featuers && props.featuers.length == 0 ? 
                            <NoFeatuer /> : <FeatuerWarning />
                        }
                    </div>
                    {
                        ((props.featuers && props.featuers.length >= 1) || (props.featuers && props.featuers.length == 0)) ? 
                            <ConfirmandProceed
                                name={props.name}
                                sourceName={props.sourceName}
                                targetName={props.targetName} 
                                connectorInfo={props.connectorInfo} 
                                featuerData={featuerData}
                                productTypeValue = {props.productTypeValue}
                                eventValue= { props.eventValue}
                                instance = {instance}
                                id={props.id}
                                sourceVersion = {props.connectorInfo.erpVersion}
                                targetVersion = {props.connectorInfo.ecommVersion}
                                versionId = {props.connectorInfo && props.connectorInfo.productTypeValue}
                                getConfirm = {confirmProcess}
                            />
                        :null
                    }
                    {
                        confirm ?  
                            <SlidingPane overlayClassName="some-custom-overlay-class" isOpen={state.isPaneOpen} onRequestClose={() => {setState({ isPaneOpen: false });}} title="Add Connector" subtitle="Download Files">
                                <Installers
                                    name={props.name}
                                    sourceName={props.sourceName}
                                    targetName={props.targetName} 
                                    connectorInfo={props.connectorInfo} 
                                    featuerData={featuerData}
                                    productTypeValue = {props.productTypeValue}
                                    eventValue= { props.eventValue}
                                    instance = {instance}
                                    id={props.id}
                                />
                            </SlidingPane> 
                        : null
                    }
                </div>
            </div>
        </>
    )
}

export default Featuers