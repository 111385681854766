import React, { useState} from 'react'
import ERPVersions from '../ERPVersions/ERPVersions'
import EcommVersions from '../EcommVersions/EcommVersions';
import ProductType from '../ProductType/ProductType';

const VersionForm = (props) => {
    const [selectedErpOption, setSelectedErpOption] = useState();
    const [selectedEcommVersions, setSelectedEcommVersions] = useState();
    const [productTypeData, setProductTypeData] = useState();
    const [mnFeatuers, setMnFeatuers] = useState()
    const [productData, setProductData] = useState([])

    const erpVersionsChange = (erpSelectedValue) => {
        setSelectedErpOption(erpSelectedValue);
        setSelectedEcommVersions()
        props.passData(null)
    };
    const ecommVersionsChange = (ecommSelectedValue) => {
        setSelectedEcommVersions(ecommSelectedValue.selectedEcommValue)
        setProductTypeData(ecommSelectedValue.features)
        props.passData(null)
    };
    const productTypeChange = (productSelectedValue) => {
        props.passData(productSelectedValue)
        props.connectorInfo(
            {
                "erpVersion" : selectedErpOption,
                "ecommVersion" : selectedEcommVersions,
                "displayName" : productSelectedValue.displayName,
                "productTypeValue" : productSelectedValue.selectedProductType,
                "features": productTypeData
            }
        )
    }

    return (
        <div className='form-section mt-3'>
            <form>
                <ERPVersions versions = {props.versions} passData={erpVersionsChange} />
                <EcommVersions 
                    id={props.id} 
                    versions = {props.versions} 
                    selectedErpOption={selectedErpOption} 
                    selectedEcommVersions={selectedEcommVersions}
                    passData={ecommVersionsChange} 
                />
                <ProductType 
                    id={props.id} 
                    versions = {props.versions} 
                    selectedErpOption={selectedErpOption}
                    selectedEcommVersions = {selectedEcommVersions} 
                    productTypeData={productTypeData} 
                    passData={productTypeChange} 
                /> 
            </form>
        </div>    
    )
}

export default VersionForm