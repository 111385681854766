import React from 'react'

const SeamlessERPIntegrations = () => {
  return (
    <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
        <p>
            The seamless integration with EGE helps scale by streamlining the business operations and minimizing all the time consuming manual work. 
            It not only enables centralization of data, but also enhances workflow management.
        </p>
    </div>
  )
}

export default SeamlessERPIntegrations
