import React from 'react'
import Resync from '../Resync/Resync'
const TableData = (props) => {
    return (
        <>
            <td> {props.entityName} </td>
            <td> {props.messageId} </td>
            <td> {props.targetId} </td>
            <td> {props.sourceId} </td>
            <td> 
                {props.statusName} 
                {
                    props.statusName === "Error" ?
                    <span className="infoIconnew">
                        <i className={props.statusName + " uil-info-circle" }></i>
                        <span className={props.statusName + " infoMessagenew" }>{props.errorMessage}</span>
                    </span> : null
                }
            </td>
            <td> {props.updatedTime} </td>
            <td> {props.syncCounter} </td>
            <td> {props.reference} </td>
            <td className="text-center">
                <Resync
                    messageId={props.messageId}
                    statusName={props.statusName}
                />
                
            </td>
        </>
    )
}

export default TableData