import React from 'react'

const MetricValues = (props) => {
    return (
        <div className="col-md-3 ctab">
            <div className="card crd-ctab">
                <div className="card-body">
                    <div>
                        <h4 className="mb-1 mt-1 heading-number">{props.count}</h4>
                        <p className="text-muted mb-0">{props.label}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MetricValues
