import React from 'react'
import FeatuerImage from './FeatuerImage'
const Featuer = (props) => {
    return (
        <div className="col-lg-4 card no-border no-boxshadow">
            <div className="featuer-circle">
                <span className="fwdsyncimg">
                    <FeatuerImage displayName={props.displayName} />
                </span>
            </div>
            <h6> {props.displayName}</h6>
        </div>
    )
}
export default Featuer