import React,{useEffect, useState} from 'react'
import SlidingPane from "react-sliding-pane";
import { useHistory } from 'react-router-dom'
import './updates.css'
import API from '../../API/API';
import marketplace_icon from '../../../assets/images/marketplace_icon.svg'
import knowledgebase from '../../../assets/images/knowledgebase.svg'
import customersupport from '../../../assets/images/customersupport.svg'
import closeicon from '../../../assets/images/close-icon.svg';
import partnerprogram from '../../../assets/images/partnerprogram.png';
import partnernetwork from '../../../assets/images/i95Dev-Partners-Network.png';
import NewConnector from '../../Modules/NewConnector/NewConnector';
import ConnectorType from '../../Modules/NewConnector/AddConnector/ConnectorType';
import Select from '../../Modules/NewConnector/SelectConnector/Select';
const Updates = () => {
    const history = useHistory();
    const [connectorType, setConnectorType] = useState([])
    const [connectorTypeImg, setConnectorTypeImg] = useState([])
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState({
        modal: true,
        isPaneOpen: false,
        isPaneOpen1: false,
        isPaneOpen2: false,
        isLoaded:true,
        images:[],
        selectedCustomer: 1,
    });
    

    useEffect(() => {
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };
        API.get("/MarketPlace/endpoints",{headers})
        .then((response) => {
            if(response.status === 200){
                setConnectorType(response.data)
                setLoading(true)
                const images = response.data;
                Promise.all(images.map(image => API.get(
                    `/MarketPlace/endpoints/${image.id}/image`,{headers}
                )))
                .then(resp =>resp)
                .then(result => {
                    setConnectorTypeImg(result)
                    setLoading(false)
                })
            }
        })
    },[]);
    let images = connectorTypeImg.map((item)=>item.data);
    return (
        <div className="main-content welcome myProducts newMyProducts">
            <div className="page-content pb-0">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-5 cursor-pointer" onClick={() => history.push('/Marketplace')}>
                            <div className="card">
                                <div className="card-body section1">
                                    <div className="media align-items-center">
                                        <div className="mr-3">
                                            <img src={marketplace_icon} />
                                        </div>
                                        <div className="media-body overflow-hidden">
                                            <h5 className="font-size-16 mb-1">Market Place</h5>
                                            <p className="text-muted mb-0">
                                                i95Dev Cloud App Marketplace with integration products & services that empower eCommerce businesses to outperform their business objectives.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>                                
                        </div>
                        <div className="col-lg-5 cursor-pointer" onClick={() => window.open('https://wiki.i95-dev.com', '_blank')}>
                            <div className="card">
                                <div className="card-body section1">
                                    <div className="media align-items-center">
                                        <div className="mr-3">
                                            <img src={knowledgebase} />
                                        </div>
                                        <div className="media-body overflow-hidden">
                                            <h5 className="font-size-16 mb-1">Knowledge Base</h5>
                                            <p className="text-muted mb-0">
                                                A knowledge ecosystem that enables businesses with deep industry insights & expert support. Get a walk-through of information on all products and services here. 
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>                                
                        </div>
                        <div className="col-lg-2 cursor-pointer" onClick={() => window.open('http://support.i95dev.com/', '_blank')}>
                            <div className="card">
                                <div className="card-body section1">
                                    <div className="media align-items-center d-block">
                                        <div className="mr-3">
                                            <img src={customersupport} />
                                        </div>
                                        <div className="media-body overflow-hidden">
                                            <h5 className="font-size-16 mb-1 customersupport">Helpdesk</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>                                
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="card">
                                <div className="card-body">
                                    <div className="welcome-head">
                                        <h3 className="float-left"> Popular Products </h3>
                                        <ConnectorType />
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="row mt-3">
                                        {
                                            connectorType && connectorType.slice(0, 4).map((item, i)=>
                                                // <div className="col-lg-3">
                                                //     <div className="card custom-connector cursor-pointer" id={item.id} >
                                                //         <div className="card-body" id={item.id}>
                                                //             <div className="imgContainer" id={item.id}>
                                                //                 <img src={images[i]} id={item.id} />
                                                //             </div>
                                                //             <h5 id={item.id}> {item.shortName} </h5>
                                                //         </div>
                                                //     </div>
                                                // </div>

                                                <div id={"selectendpoint"+item.shortName} className='col-lg-3 mb-3 conhover'>
                                                    <div className="card custom-connector cursor-pointer updateC" id={item.id}>
                                                        <div className="card-body updateCB" id={item.id}>
                                                            <div className="connector-listing">
                                                                <Select id={item.id} />
                                                                <div className="connector-logo-container">
                                                                    <img src={images[i]} id={item.id} />
                                                                </div>
                                                                <h5 className="text-truncate add-connector-title"> {item.shortName} </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="welcome-head mt-3">
                                        <h3 className="float-left"> Why Partner with Us </h3>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-lg-6 cursor-pointer" onClick={() => setState({ isPartnerprogram: true })}>
                                            <div className="card">
                                                <div className="card-body section1">
                                                    <div className="media align-items-center">
                                                        <div className="mr-3">
                                                            <img src={partnerprogram} className="partnerImg" />
                                                        </div>
                                                        <div className="media-body overflow-hidden">
                                                            <h5 className="font-size-16 mb-1">Partner Program</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 cursor-pointer" onClick={() => setState({ isPartnernetwork: true })}>
                                            <div className="card">
                                                <div className="card-body section1">
                                                    <div className="media align-items-center">
                                                        <div className="mr-3">
                                                            <img src={partnernetwork} className="partnerImg" />
                                                        </div>
                                                        <div className="media-body overflow-hidden">
                                                            <h5 className="font-size-16 mb-1">Partners Network</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="card">
                                <div className="card-body">
                                    <div className="welcome-head mb-4">
                                        <h3> Latest Blogs </h3>
                                    </div>
                                    <div className="offersectionnew mb-3">
                                        <div className="media align-items-center cmnNewsection">
                                            <div className="mr-3">
                                                <img src="https://www.i95dev.com/wp-content/uploads/2021/01/Key-Roles-and-Profiles-for-eCommerce-Success-Thumbnail-Image.png" />
                                            </div>
                                            <div className="media-body overflow-hidden">
                                                <h5 className="font-size-16 mb-1 updateh5">
                                                    <a href="https://www.i95dev.com/top-4-benefits-of-integrated-ecommerce-for-b2b-companies/" target="_blank">Top 4 benefits of Integrated eCommerce for B2B Companies</a>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="offersectionnew mb-3">
                                        <div className="media align-items-center cmnNewsection">
                                            <div className="mr-3">
                                                <img src="https://www.i95dev.com/wp-content/uploads/2021/01/How-integrated-eCommerce-benefits-retailers-and-wholesalers-400-x-250.png" />
                                            </div>
                                            <div className="media-body overflow-hidden">
                                                <h5 className="font-size-16 mb-1 updateh5">
                                                    <a href="https://www.i95dev.com/how-integrated-ecommerce-benefits-retailers-and-wholesalers/" target="_blank">How integrated eCommerce benefits retailers and wholesalers?</a>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="offersectionnew">
                                        <div className="media align-items-center cmnNewsection">
                                            <div className="mr-3">
                                                <img src="https://www.i95dev.com/wp-content/uploads/2020/12/Driving-Transparency-with-Dynamics-Business-Central-and-Magento-Integration-400-x-250.png" />
                                            </div>
                                            <div className="media-body overflow-hidden">
                                                <h5 className="font-size-16 mb-1 updateh5">
                                                    <a href="https://www.i95dev.com/magento-dynamics-business-central-integration-and-web-store-performance/" target="_blank">Dynamics 365 Business Central – Magento Integration and Web Store Performance</a>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>                                                                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SlidingPane className='connector-popup' overlayClassName='some-custom-overlay-class' isOpen={ state.isPaneOpen } onRequestClose={ () => { setState({ isPaneOpen: true }); } }>
                    <button className="popup-close" onClick={() => setState({ isPaneOpen: false })}>
                        <img src={closeicon} />
                    </button>
                    <NewConnector />
                </SlidingPane>

                <SlidingPane className='connector-popup welcomecustomerSupport' overlayClassName='some-custom-overlay-class' isOpen={ state.isPaneOpen1 } onRequestClose={ () => { setState({ isPaneOpen1: true }); } }>
                    <button className="popup-close" onClick={() => setState({ isPaneOpen1: false })}>
                        <img src={closeicon} />
                    </button>
                    <iframe id="helpdeskurl" src="https://www.i95dev.com/contact/" width="100%" frameBorder="none"></iframe>
                </SlidingPane>

                <SlidingPane className='connector-popup welcomecustomerSupport' overlayClassName='some-custom-overlay-class' isOpen={ state.isPartnerprogram } onRequestClose={ () => { setState({ isPartnerprogram: true }); } }>
                    <button className="popup-close" onClick={() => setState({ isPartnerprogram: false })}>
                        <img src={closeicon} />
                    </button>
                    <iframe id="helpdeskurl" src="https://www.i95dev.com/become-a-partner/" width="100%" frameBorder="none"></iframe>
                </SlidingPane>

                <SlidingPane className='connector-popup welcomecustomerSupport' overlayClassName='some-custom-overlay-class' isOpen={ state.isPartnernetwork } onRequestClose={ () => { setState({ isPartnernetwork: true }); } }>
                    <button className="popup-close" onClick={() => setState({ isPartnernetwork: false })}>
                        <img src={closeicon} />
                    </button>
                    
                    <div className='iframeCont'>
                        <iframe id="helpdeskurl" src="https://www.i95dev.com/our-partners/" width="100%" frameBorder="none"></iframe>
                    </div>
                    
                </SlidingPane>

                {/* <SlidingPane className='connector-popup marketplacesec' overlayClassName='some-custom-overlay-class' isOpen={ this.state.isPaneOpen2 } onRequestClose={ () => { this.setState({ isPaneOpen2: true }); } }>
                    <button className="popup-close" onClick={() => this.setState({ isPaneOpen2: false })}>
                        <img src={closeicon} />
                    </button>
                    {this.state.isLoaded?<div> </div>:  <Connector  id={this.state.selectedCustomer} closePopup={this.togglePopup.bind(this)} />}
                </SlidingPane> */}
        </div>
    )
}

export default Updates