import {React, useState} from 'react'
import './setupInstaller.css'
import API from '../../../../../API/API'
import { InfinitySpin } from  'react-loader-spinner'

const SetupInstaller = (props) => {
    const [count, setCount] = useState(0)
    const [sourceProgressMessage, setSourceProgressMessage] = useState()
    const [targetProgressMessage, setTargetProgressMessage] = useState()
    const [loading, setLoading]  =useState(false)

    const targetSetup = () => {
        setLoading(true)
        setCount(count+1)
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json",
        };
        API.get(`/User/Token/Check`, {headers})
        .then((response)=> {
            if(response.status === 200){
                if(response.data.result == false){
                    API.get('/User/Authorize', { headers })
                    .then((response)=>{
                        if(response.status === 200){
                            if(count < 5){
                                var win = window.open(response.data, "", "width=500,height=500");
                                setTimeout(function () { win.close(); }, 10000);
                            }else{
                                var html = "<html><head></head><body> Authentication failed : The Request could not be completed </body></html>"
                                var win = window.open(response.data, "", "width=500,height=500");
                                win.document.write(html)
                                setTimeout(function () { win.close(); }, 10000);
                            }

                        }
                    })
                    setTimeout(function () {
                        if (count < 5) {
                            targetSetup();
                        }else{
                            setCount(0);
                        }
                    }, 11000);
                }else{
                    API.post(`/Client/connections/setup`, {
                        "subscriptionId": props.subscriptionId,
                        "endpointId": props.endpointId,
                        "instanceId": props.instanceId,
                        "fileType": props.fileType ? props.fileType : 1
                    }, {headers})
                    .then((response) =>{
                        alert(response.data.message)
                        setLoading(false)
                        setTargetProgressMessage(response.data.message)
                    })
                }
            }
        })
    }

    return (
        <>
            {
                loading ?
                    <div className='spinLoaderContainer spinLoaderContainer1'>
                        <InfinitySpin
                            width='200'
                            color="#437FED"
                            wrapperStyle={{}}
                            wrapperClass="spinnerStyle"
                            visible={true}
                        />
                        <p> Please wait, Setup in progress </p>
                    </div> 
                : null
            }
            <a className="cursor-pointer" onClick={targetSetup}> Setup </a>
        </>
    )
}

export default SetupInstaller
