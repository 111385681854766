import React from 'react'

const InvisibleLogo = () => {
    return (
        <div className="d-flex">
            <div className="navbar-brand-box">
                <a href="" className="logo logo-dark">
                    <span className="logo-sm">
                    <img src="" alt="" height="22" />
                    </span>
                    <span className="logo-lg">
                    <img src="" alt="" height="20" />
                    </span>
                </a>

                <a href="" className="logo logo-light">
                    <span className="logo-sm">
                    <img src="" alt="" height="22" />
                    </span>
                    <span className="logo-lg">
                    <img src="" alt="" height="20" />
                    </span>
                </a>
            </div>
        </div>
    )
}

export default InvisibleLogo
