import React, { useEffect, useState } from 'react'

const EcommerceAttributeName = (props) => {
    const [isVisible, setIsVisible] = useState(false);
    return (
        <>
            <span 
                className='infoHover'
                onMouseEnter={() => setIsVisible(true)}
                onMouseLeave={() => setIsVisible(false)}
            >
                {props.name}
            </span>
            {isVisible && (
                <div className="tooltip-content">
                    {props.dataType}
                </div>
            )}
            <div className='clearfix'></div>
        </>
    )
}

export default EcommerceAttributeName
