import {React, useEffect, useState} from 'react'
import $ from 'jquery'
import API from '../../../../../API/API'

const SourceMethods = (props) => {
    const [sourceMethods, setSourceMethods] = useState([])
    useEffect(() => {
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };
        API.get(`/Warehouse/erp/${props.instancesId}/${props.targetId}`, {headers})
        .then((response) => {
            if(response.status === 200){
                setSourceMethods(response.data)
            }
        })
    },[])
    const selectSourceMethod = (event) => {
        if(event.target.value === "customERPmethod"){
            $(event.target).addClass("d-none")
            $(event.target).next(".customerpmethod").removeClass("d-none")
        }else{
            props.getSelectedSourceMethod(event.target.value)
            props.getSelectedSourceMethodText($("#selecteSource option:selected" ).text())
        }
    }
    const enterSourceMethod = (event)=>{
        props.getSelectedSourceMethod(event.target.value)
    }
    return (
        <>
            <select id='selecteSource' className='form-control sourceMethod' onChange={selectSourceMethod}>
                <option value={""}>{"Select ERP Method"}</option>
                <option value={"customERPmethod"}> Custom ERP Method </option>
                {
                    sourceMethods && sourceMethods.map(item => 
                        <option value={item.value}> {item.name} </option>
                    )
                }
            </select>
            <input type='text' placeholder='Please enter ERP Method' className='d-none customerpmethod form-control version-field erpFieldnew' onChange={enterSourceMethod} />
        </>
    )
}

export default SourceMethods