import React, { useState } from 'react'
import SourceMethods from './SourceMethods/SourceMethods'
import TargetMethods from './TargetMethods/TargetMethods'
import SourcetoTarget from './SourcetoTarget/SourcetoTarget'
import TargettoSource from './TargettoSource/TargettoSource'
import AddMapping from './AddMapping/AddMapping'
import SubmitMappings from './SubmitMappings/SubmitMappings'

import DefaultMappingTable from './DefaultMappingTable/DefaultMappingTable'
const PaymentMapping = (props) => {
    const newMappingData = []
    const [sourceMethod, setSourceMethod] = useState("")
    const [targetMethod, setTargetMethod] = useState("")
    const [sourceToTarget, setSourceToTarget] = useState(false)
    const [targetToSource, setTargetToSource] = useState(false)
    const [newMapping, setNewMapping] = useState(newMappingData)
    const getSelectedSourceMethod = (event) => {
        setSourceMethod(event)
    }
    const getSelectedTargetMethod = (event) => {
        setTargetMethod(event)
    }
    const getSourcetoTarget = (event) => {
        setSourceToTarget(event)
    }
    const getTargettoSource = (event) =>{
        setTargetToSource(event)
    }
    const getNewlyAddedMapping = (event) =>{
        setNewMapping(s => {
            return [
                ...s,{
                    ecommerceMethod : event.ecommerceMethod,
                    erpMethod: event.erpMethod,
                    isEcommerceDefault: event.isEcommerceDefault,
                    isErpDefault: event.isErpDefault
                }
            ];
        });
    }
    return (
        <>
            <div className="card-header p-10">
                Payment Method Mapping 
                <SubmitMappings
                    connectorId ={props.connectorId}
                    instancesId={props.instancesId}
                />
            </div>
            <div className='card-body p-10'>
                <div className='mappingFormContainer'>
                    <table className='mappingsNew mappingForm'>
                        <thead>
                            <tr>
                                <td className='selecterptd'>
                                    <SourceMethods
                                        connectorId ={props.connectorId}
                                        instancesId={props.instancesId} 
                                        getSelectedSourceMethod = {getSelectedSourceMethod}
                                    />
                                </td>
                                <td className='selectecommercetd'>
                                    <TargetMethods
                                        connectorId ={props.connectorId}
                                        instancesId={props.instancesId} 
                                        getSelectedTargetMethod = {getSelectedTargetMethod}
                                    />
                                </td>
                                <td>
                                    <SourcetoTarget
                                        getSourcetoTarget={getSourcetoTarget}
                                    />
                                </td>
                                <td>
                                    <TargettoSource
                                        getTargettoSource={getTargettoSource}
                                    />
                                </td>
                                <td>
                                    <AddMapping
                                        sourceMethod={sourceMethod}
                                        targetMethod={targetMethod}
                                        sourceToTarget={sourceToTarget}
                                        targetToSource={targetToSource}
                                        getNewlyAddedMapping={getNewlyAddedMapping}
                                    />
                                </td>
                            </tr>
                        </thead>
                    </table>
                    <DefaultMappingTable
                        connectorId ={props.connectorId}
                        instancesId={props.instancesId}
                        newlyAddedMapping={newMapping}
                    />
                </div>
            </div>
        </>
    )
}

export default PaymentMapping
