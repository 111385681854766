import React, { useEffect, useState } from 'react'
import API from "../../../../../API/API"
import { Redirect } from 'react-router-dom';
import ChildDownload from './ChildDownload';

const DownloadInstaller = (props) => {
    return (
        <>
            {
                (props.connector.source.isAuthenticationRequired) || (props.connector.target.isAuthenticationRequired) ? <Redirect to="/Authentication"/> : 
                <ChildDownload 
                    connectorInfo = {props.connector}
                />
            }
        </>
    )
}
export default DownloadInstaller