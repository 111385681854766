import React from 'react'
import $ from 'jquery'

const Notification = () => {
    const notificationPanel = () =>{
        $("#offer-custom-notification").removeClass("d-none")
    }

    const closenotificationPanel = () =>{
        $("#offer-custom-notification").addClass("d-none")
    }
    return (
        <>
            <div className="dropdown d-inline-block">
                <button type="button" className="btn header-item noti-icon waves-effect" onClick={() => notificationPanel()} id="page-header-notifications-dropdown">
                    <i className="uil-bell"onClick={() => notificationPanel()}></i>
                </button>
            </div>
            <div id="offer-custom-notification" className="card offer-custom-notification no-border d-none">
                <div className="card-body p-12">
                    <div className="notification-panel">
                        <h3 className="offer-title">
                            <span className="float-left text-left title-mn">Notifications</span>
                            <span id="close" className="float-right text-right popup-close-mn" onClick={() => closenotificationPanel()}> Close </span>
                            <span className="clearfix"></span>
                        </h3>
                        <p>
                            There is no notifications to show
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Notification