import React from 'react'
const content = () => {
    return (
        <div className="col-sm-7">
            <p className="ege-mn-title">E-Commerce Growth Engine</p>
            <p className="ege-mn-desc">
                A comprehensive & intelligent eCommerce - ERP integrated platform that boosts businesses and helps them scale & achieve higher ROI. The seamless ERP integration provides businesses with multiple benefits such as timely updates, real-time inventory control, timely order placement, on-time delivery, efficient finance management, increased sales, better ROI from marketing, and not to mention, excellent customer experience. 
                i95Dev is an eCommerce thought leader with a team of integration specialists that help build eCommerce businesses that are smart, seamless, productive & efficient. 
            </p>
        </div>
    )
}

export default content
