import React, {useState} from 'react'
import "./confirmPopup.css"
import {Link} from 'react-router-dom';
import API from "../../../../API/API"
import SlidingPane from "react-sliding-pane";
import DownloadInstaller from './DownloadInstaller/DownloadInstaller';


const ConfirmandProceed = (props) => {
    const [state, setState] = useState({
        isPaneOpen: false,
    });
    const [finatData, setFinalData] = useState([])
    const [connector, setConnector] = useState({})
    const closePopup = () =>{
        localStorage.removeItem("awsDetails")
        localStorage.removeItem("magentoDetails")
        localStorage.removeItem("fullName")
        localStorage.removeItem("sourceName")
        localStorage.removeItem("targetName")
        localStorage.removeItem("connectorInfo")
        localStorage.removeItem("instance")
        localStorage.removeItem("id")
        props.getCancel(false)
    }
    const createSubscription = () => {
        var awsDetails = Object.entries(props.awsDetails)
        var magentoDetails = Object.entries(props.magentoDetails)
        let finalResult = [...awsDetails, ...magentoDetails]
        var fullName = props.name
        var connectorInfo = props.connectorInfo
        var egeConnectorId = localStorage.getItem("egeConnectorId")
        var erpVersion = connectorInfo.erpVersion
        var ecommVersion = connectorInfo.ecommVersion
        let allQuestions = finalResult.map((obj) => {
            return {
              "questionId": obj[0],
              "answer": obj[1]
            }
        });
        var response = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        const body = {
            connectorVersionId: parseInt(egeConnectorId),
            "connectorName": fullName,
            "sourceVersion": erpVersion,
            "targetVersion": ecommVersion,
            "subscriptionAnswers": allQuestions
        }
        API.post("/Subscription", body, { headers })
        .then((response) => {
            if(response.status === 200){
                API.get(`/Client/connections/${response.data.connectorId}/instances/${response.data.instanceId}`, { headers })
                .then((response) => {
                    if(response.status === 200){
                        setConnector(response.data)
                        setState({ isPaneOpen: true })
                    }
                })
            }
        })
    }
    return (
        <>
            <div className='confirmPopup'>
                <div className="card popupContainer">
                    <h4 className="card-header text-center mt-0">Want to Proceed...?</h4>
                    <div className="card-body">
                        <p className="card-text text-center">Please Click on Confirm &amp; Proceed button for Setup</p>
                    </div>
                    <div className="text-muted card-ftr">
                        <button type='button' className="btn btn-light waves-effect btn-rounded waves-effect waves-light cancel float-left" onClick={closePopup}>
                            Cancel
                        </button>
                        <button type='button' className="btn btn-primary btn-rounded waves-effect waves-light float-right awsnext" onClick={createSubscription}>
                            {/* <a href="/Egesetupdownload">Confirm &amp; Proceed</a> */}
                            {/* <Link to={{pathname:"/Egesetupdownload"}}
                            >
                                Confirm & Proceed
                            </Link> */}
                            Confirm & Proceed
                        </button>
                        <div className="clearfix"> </div>
                    </div>
                </div>
            </div>
            <SlidingPane overlayClassName="some-custom-overlay-class" isOpen={state.isPaneOpen} onRequestClose={() => {setState({ isPaneOpen: false });}} title="Add Connector" subtitle="Setup / Download Files">
                <DownloadInstaller
                    name={props.name}
                    sourceName={props.sourceName}
                    targetName={props.targetName} 
                    connectorInfo={props.connectorInfo} 
                    featuerData={props.featuerData}
                    productTypeValue = {props.productTypeValue}
                    eventValue= { props.eventValue}
                    instance = {props.instance}
                    id={props.id}
                    sourceVersion = {props.sourceVersion}
                    targetVersion ={props.targetVersion}
                    versionId ={props.versionId }
                    connector={connector}
                />
            </SlidingPane>
        </>
    )
}
export default ConfirmandProceed