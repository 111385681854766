import React, { useRef, useState } from 'react'
import $ from "jquery"
import Select from 'react-select';
import API from '../../../../API/API'
import Featuers from '../Featuers/Featuers';

const EcommVersions = (props) => {
    const [selectedOption, setSelectedOption] = useState(null)

    const getEcommerceOptions = props.versions.map((item, index) =>(
        props.selectedErpOption === item.sourceVersion ? 
        {value :item.targetVersion, label: item.targetVersion} : null
    ))
    var filteredEcommerceOptions = getEcommerceOptions.filter(function (el) {
        return el != null;
    });

    const selectEcommerce = (event) =>{
        setSelectedOption(event.value)
        if(event.value != selectedOption){
            $("#react-select-23-placeholder").text("")
            $("#selectProductType .css-319lph-ValueContainer").prepend('<div className=" css-14el2xx-placeholder" id="react-select-13-placeholder">Select Product Type</div>')
            $("#selectProductType .css-qc6sy-singleValue").text("")
            $("#selectEcommVersion").addClass("notEmptyfield")
            $("#selectProductType").removeClass("notEmptyfield")
            var response =window.localStorage.getItem("accessToken") ;
            const headers={
                "authorization": `Bearer ${response}`,
                "content-type" : "application/json"
            };
            const body ={
                "id": props.id,
                "sourceVersion": `${props.selectedErpOption}`,
                "targetVersion": `${event.value}`,
            }
            API.post(`/MarketPlace/connectors/${props.id}/features`, body, { headers })
            .then((response) => {
                if(response.status === 200){
                    //setFeatures(response.data)
                    props.passData(
                        {
                            "selectedEcommValue" : event.value,
                            "features": response.data
                        }
                    );
                }
            })
            setSelectedOption(null)
        }
    }

    return (
        <div className="form-group">
            <Select 
                id="selectEcommVersion"
                className='form-control version-field'
                options={filteredEcommerceOptions}
                placeholder ={"Select Ecommerce Version"}
                onChange={selectEcommerce}
                defaultValue={selectedOption}
            />
        </div>
    )
}

export default EcommVersions
