import React,{useEffect, useState} from 'react'
import API from '../../../../../../../API/API';
import $ from 'jquery'

const SubmitMappings = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [allUpdatedMappings, setAllUpdatedMappings] = useState()
    
    useEffect(() => {
    },[])

    const submitSelectedMappings = () =>{
        var allMapping = []
        $("#newAttributeMappingTableNewTarget tbody tr").each(function(){
            var mappingTable = {
                "erpAttributeName": $(this).find("td:nth-child(6)").attr("headers"),
                "erpAttributeDataType": $(this).find("td:nth-child(7)").attr("headers"),
                "erpIsRequired":  $(this).find("td:nth-child(8)").attr("headers") === "true" ? true : false,
                "erpAttributeSize": $(this).find("td:nth-child(9)").attr("headers"),
                "erpAttributeType": $(this).find("td:nth-child(10)").attr("headers"),
                "ecommerceAttributeName": $(this).find("td:nth-child(1)").attr("headers"),
                "ecommerceAttributeDataType": $(this).find("td:nth-child(2)").attr("headers"),
                "ecommerceIsRequired": $(this).find("td:nth-child(3)").attr("headers") === "true" ? true : false,
                "ecommerceAttributeSize": $(this).find("td:nth-child(4)").attr("headers"),
                "ecommerceAttributeType": $(this).find("td:nth-child(5)").attr("headers"),
            }
            allMapping.push(mappingTable)
        })

        setIsLoading(true);

        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };
        const body = {"targetMappedSchemaList": allMapping}
        //Connection/{ConnectionId}/instance/{InstanceId}/save-customer-mappings/{RequestType}
        API.post(`/Connection/${props.connectorId}/instance/${props.instancesId}/save-customer-mappings/Target`, body, { headers })
        .then((response) => {
            if(response.status === 200){
                setIsLoading(false);
            }
        })
    }

    return (
        <>
            {isLoading ?
                <div className="spinner-container">
                    <div className="loading-spinner"></div>
                </div> : null
            }

            <button className='submitButton submitButton1' onClick={submitSelectedMappings}>
                Submit Mappings
            </button>
        </>
    )
}

export default SubmitMappings

