import React,{useEffect, useState} from 'react'
import { Redirect } from 'react-router-dom';
import $ from 'jquery'
import SlidingPane from "react-sliding-pane";
import DownloadInstaller from './DownloadInstaller/DownloadInstaller';
import API from '../../../API/API';
import { Box } from '@chakra-ui/react';
import ChildDownload from './DownloadInstaller/ChildDownload';

const SendPrerequisites = (props) => {
    const [state, setState] = useState({
        isPaneOpen: false,
    });
    const [errorMessage, setErrorMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [questionsUpdated, setQuestionsUpdated] = useState()
    const [connectorId, setConnectorId] = useState()
    const [expiryTime, setExpiryTime] = useState()
    const [id, setId] = useState()
    const [instanceId, setInstanceId] = useState()
    const [key, setKey] = useState()
    const [result, setResult] = useState()
    const [sourceIsAuthenticationRequired, setSourceIsAuthenticationRequired] = useState()
    const [targetIsAuthenticationRequired, setTargetIsAuthenticationRequired] = useState()
    const [connectorInfo, setConnectorInfo] = useState()

    const selectedPrerequisites = () => {
        setIsLoading(true)
        let mainResult = [];
        var row = {};
        $("#defaultQuestioneries tr td").each(function(){
            var mnId = $(this).attr("id");
            var mnValueNew = $(this).text().trim()
            var mnValue = mnValueNew
            if((mnValue === "")){
                //setAllFilled(false)
            }else{
                row[mnId] = mnValue
                mainResult.push(row);
                setQuestionsUpdated(row)
                let connectorques=Object.entries(row);
                connectorques=connectorques.filter(obj=>{
                    if(obj[0]!=="isPaneOpen") { return {obj}}
                })
                let result1 = connectorques.map((obj) => { 
                    return {
                        "questionId":parseInt(obj[0]), 
                        "answer": obj[1]
                    }
                });
                var response =window.localStorage.getItem("accessToken") ;
                const headers={
                    "authorization": `Bearer ${response}`,
                    "content-type" : "application/json"
                };
                const body =
                {
                    connectorVersionId: parseInt(props.versionId),
                    "connectorName": `${props.name}`,
                    "sourceVersion": props.sourceVersion.toString(),
                    "targetVersion": props.targetVersion.toString(),
                    "subscriptionAnswers":result1
                }
                API.post(`/Subscription`, body, {headers})
                .then((response) => {
                    if(response.data.result){
                        setState({
                            isPaneOpen: true,
                        })
                        setConnectorId(response.data.connectorId)
                        setExpiryTime(response.data.expiryTime)
                        setId(response.data.id)
                        setInstanceId(response.data.instanceId)
                        setKey(response.data.ket)
                        setResult(response.data.result)
                        API.get(`/Client/connections/${response.data.connectorId}/instances/${response.data.instanceId}`, {headers})
                        .then((response) => {
                            window.localStorage.setItem("data",JSON.stringify(response.data))
                            setSourceIsAuthenticationRequired(response.data.source && response.data.source.isAuthenticationRequired)
                            setTargetIsAuthenticationRequired(response.data.source && response.data.target.isAuthenticationRequired)
                            setConnectorInfo(response.data)
                            setIsLoading(false)
                        })
                    }else{
                        setErrorMessage(response.data.message)
                        setIsLoading(false)
                    }
                })
            }
        })
    }
    
    return (
        <>
            {
                errorMessage && errorMessage ? 
                    <Box
                        position={'absolute'}
                        top={'7px'}
                        right={'0'}
                        width={'100%'}
                        maxWidth={'350px'}
                        color={'red'}
                    > {errorMessage && errorMessage} </Box> 
                : null
            }
            {
                isLoading ? <div id="loaderContainer" className="loaderContainer"><div className="loader"></div></div> : null
            }
            
            <button type="button" className="btn btn-primary waves-effect waves-light mr-1 subsubmit" onClick={selectedPrerequisites}>Submit</button>
            <SlidingPane overlayClassName="some-custom-overlay-class" isOpen={state.isPaneOpen} onRequestClose={() => {setState({ isPaneOpen: false });}} title="Add Connector" subtitle="Download Files">
                {/* <Installers /> */}
                {
                (sourceIsAuthenticationRequired || targetIsAuthenticationRequired) ? <Redirect to="/Authentication"/> : 
                <ChildDownload 
                    connectorInfo = {connectorInfo}
                />
                
                }
                {/* <DownloadInstaller
                    name={props.name}
                    sourceName={props.sourceName}
                    targetName={props.targetName} 
                    connectorInfo={props.connectorInfo} 
                    featuerData={props.featuerData}
                    productTypeValue = {props.productTypeValue}
                    eventValue= { props.eventValue}
                    instance = {props.instance}
                    id={props.id}
                    questions={questionsUpdated} 
                    sourceVersion = {props.sourceVersion}
                    targetVersion ={props.targetVersion}
                    versionId ={props.versionId }
                /> */}
            </SlidingPane>
        </>
    )
}
export default SendPrerequisites
