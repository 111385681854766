import React from 'react'

const ErrorLogs = (props) => {
    return (
        <div className="col-md-4">
            <div className="">
                <p className="text-muted mb-0 font-size-9">Error Logs</p>
                <h5 className="font-size-10">{props.errorCount}</h5>
            </div>
        </div>
    )
}

export default ErrorLogs
