import React, { useContext, useEffect, useState } from 'react'
import $ from'jquery'
import API from '../../../../API/API'
import InfoComponent from '../../../../Alerts/InfoComponent/InfoComponent'
import { ApiContext } from '../../../../../Contexts/ApiContext'
import { BusinessEntitiesSourceContext } from '../../../../../Contexts/ApiContext'
import { BusinessEntitiesTargetContext } from '../../../../../Contexts/ApiContext'
import ConnectorListing from './ConnectorListing/ConnectorListing'
import SelectedConnector from './SelectedConnector/SelectedConnector'
import HmyProducts from '../../../../Headings/HmyProducts'
import * as NO_CONNECTORS from '../../../../Constants/Constants' 
import NewConnector from '../../../../Modules/NewConnector/NewConnector'

const Connectors = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [connectors, setconnectors] = useContext(ApiContext)
    const [id, setId] = useState(0)
    const selectedConnector = connectors && connectors[id]
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [businessEntitiesSource, setBusinessEntitiesSource] = useContext(BusinessEntitiesSourceContext)
    const [businessEntitiesTarget, setBusinessEntitiesTarget] = useContext(BusinessEntitiesTargetContext)

    function testFunction(event) {
        setIsLoading(true)
        $(".connectortab").removeClass("active")
        $(event.target).closest(".connectortab").addClass("active")
        setId(event.target.id)
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };    
        API.post("/Client/connections/report/summary",
        {
            "subscriptionInstanceId": connectors[event.target.id].instances[0].id,
            "sourceEndpointId": connectors[event.target.id].source.id,
            "requestType": "Source"
        },
        {headers}
        ).then((response)=>{
            setBusinessEntitiesSource(response.data)
            setIsLoading(false)
        })
        API.post("/Client/connections/report/summary",
        {
            "subscriptionInstanceId": connectors[event.target.id].instances[0].id,
            "sourceEndpointId": connectors[event.target.id].target.id,
            "requestType": "Target"
        },
        {headers}
        ).then((response)=>{
            setBusinessEntitiesTarget(response.data)
            setIsLoading(false)
        })
    }
    return (
        <>
            {isLoading ?
                <div className="loaderNewContainer">
                    <div className="loading-spinner"></div>
                </div> : null
            }
            <h4 className="card-title mb-2 card-title-wth-border">
                <span className="float-left"><HmyProducts /> ({connectors.length})</span>
                <NewConnector />
                <div className="clearfix"></div>
            </h4>
            {connectors.length > 0 ? 
                <div id="emptyNewconnector" className="row">
                    <div className="col-md-5">
                        <div className="nav flex-column nav-pills">
                            {
                                connectors.slice(0, 4).map((item,index) => 
                                    <ConnectorListing key={index}
                                        connectorIndex={index}
                                        name={item.name}
                                        sourceShortName={item.source.shortName}
                                        targetShortName={item.target.shortName}
                                        isSetupCompleted={item.instances[0].isSetupCompleted}
                                        expiryDate={item.expiryDate}
                                        errorCount={item.instances[0].errorCount}
                                        entityCount={item.instances[0].entityCount}
                                        selectedIndex={selectedIndex}
                                        fn={testFunction}
                                    />
                                )
                            }
                        </div>
                        {connectors.length > 4 ?
                            <div className='buttonContainer text-center d-block'>
                                <a className="btn btn-primary btn-rounded waves-effect waves-light viewmorenewsec" href="/MyProducts"> View More </a>
                            </div> : null
                        }
                    </div>
                    <div className='col-md-7 connection-info no-padding'>
                        <SelectedConnector
                            key={selectedConnector && selectedConnector.id}
                            connectorId = {selectedConnector && selectedConnector.id}
                            name={selectedConnector && selectedConnector.name}
                            sourceName={selectedConnector && selectedConnector.source.name}
                            sourceVersion={selectedConnector && selectedConnector.source.version}
                            targetName={selectedConnector && selectedConnector.target.name}
                            targetVersion={selectedConnector && selectedConnector.target.version}
                            featureName={selectedConnector && selectedConnector.featureName}
                            isSetupCompleted = {selectedConnector && selectedConnector.instances[0].isSetupCompleted}
                            businessEntitiesSource={businessEntitiesSource}
                            businessEntitiesTarget={businessEntitiesTarget}
                            sourceShortName={selectedConnector && selectedConnector.source.shortName}
                            targetShortName={selectedConnector && selectedConnector.target.shortName}
                            instancesId={selectedConnector && selectedConnector.instances[0].id}
                            sourceId={selectedConnector && selectedConnector.source.id}
                            targetId={selectedConnector && selectedConnector.target.id}
                            sourceType = {selectedConnector && selectedConnector.source.type}
                            targetType = {selectedConnector && selectedConnector.target.type}
                        />
                    </div>
                </div> : 
                <div id='mainWarningInfo' className='row d-none'>
                    <div className='col-md-12'>
                        <InfoComponent info={NO_CONNECTORS.NO_CONNECTORS} />
                    </div>
                </div> 
            }
        </>
    )
}

export default Connectors




