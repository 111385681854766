import React from 'react'

const ConnectorInfo = (props) => {
    return (
        <div className="detailsContainer">
            <h3> {props.name} </h3>
            <table className="table table-nowrap table-centered mb-0">
                <tr>
                    <th scope="row" colspan="4" className="text-left">{props.sourceName}</th>
                    <td className="text-right">{props.sourceVersion}</td>
                </tr>
                <tr>
                    <th scope="row" colspan="4" className="border-0 text-left">{props.targetName}</th>
                    <td className="border-0 text-right"> {props.targetVersion} </td>
                </tr>
                <tr>
                    <th scope="row" colspan="4" className="border-0 text-left">Product Type</th>
                    <td className="border-0 text-right">{props.productType}</td>
                </tr>
            </table>
        </div>
    )
}

export default ConnectorInfo
