import React from 'react'
import $ from 'jquery'
const Tabs = (props) => {
    function selectTab(event){
        $(".tabItem").removeClass("active")
        $(event.target).addClass("active")
        props.getActiveTab(event.target.name)
    }
    return(
        <div className='tabMenuContainer'>
            <ul className='nav'>
                <li className='nav-item'>
                    <a className="nav-link tabItem active" name="Details" onClick={selectTab}>
                        Details
                    </a>
                </li>
                {
                    !props.instance.isShippingMethodMappingEnable && !props.instance.isPaymentMethodMappingEnable && !props.instance.isMultiWarehouseAttributeMappingEnable && !props.instance.isProductAttributeMappingEnable ? null 
                    : 
                    <li className='nav-item'>
                        <a className="nav-link tabItem" name="Mappings" onClick={selectTab}>
                            Mappings
                        </a>
                    </li>
                }
                
                <li className='nav-item'>
                    <a className="nav-link tabItem" name="ManageEntities" onClick={selectTab}>
                        Manage Entities
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default Tabs