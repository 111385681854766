import React from 'react'
import $ from 'jquery'
import BusinessEntity from './Subcomponents/BusinessEntity'
import EntityCount from './Subcomponents/EntityCount'

const BusinessEntities = (props) => {
    const sourceEntity = props.businessEntitiesSource
    const targetEntity = props.businessEntitiesTarget
    const allEntity = sourceEntity.concat(targetEntity)

    // $(document).on("ready", function(){
    //     $("div.entityListNew .entityList").each(function(){
    //         var mnIdr = $(this).attr('id');
    //         $('.'+mnIdr).slice(1).addClass("d-none")
    //     })
    // })
    // $(document).ready(function(){
    //     $("div.entityListNew .entityList").each(function(){
    //         var mnIdr = $(this).attr('id');
    //         $('.'+mnIdr).slice(1).addClass("d-none")
    //     })
    // })

    return (
        <>
            <EntityCount
                sourceShortName={props.sourceShortName}
                targetShortName={props.targetShortName}
                sourceEntity={sourceEntity}
                targetEntity={targetEntity}
                allEntity={allEntity} 
                sourceId={props.sourceId}
                targetId={props.targetId}
                isSetupCompleted={props.isSetupCompleted && props.isSetupCompleted}
                connectorId={props.connectorId && props.connectorId}
                instancesId={props.instancesId && props.instancesId}
                sourceType={props.sourceType && props.sourceType}
                targetType={props.targetType && props.targetType}
            />
            <div className='scrollingContainer'>
                <div className='row entityListNew'>
                    <BusinessEntity
                        sourceEntity={sourceEntity}
                        targetEntity={targetEntity}
                        key={props.key}
                    />
                </div>
            </div>
        </>
    )
    
}

export default BusinessEntities