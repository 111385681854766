import React, { useEffect, useState } from 'react'
import $ from "jquery"
import Select from 'react-select';
const DropDown = (props) => {
    const [value, setValue] = useState(null)
    const selectebleOptions = props.options.map(item =>(
        {value :item.code, label: item.displayName, name:item.id}
    )) 
    const getFieldValue = (event) => {
        $(event.target).closest("div.awsGroup").removeClass("validClass")
        if(event.value != null){
            setValue({[event.name]: event.value})
            props.getAllFieldValues({[event.name]: event.value})
            $(event.target).removeClass("validClass")
        }else{
            $(event.target).addClass("validClass")
        }
    }

    return (
        <Select
            className="form-control aws_version-field version-field validClass"
            options={selectebleOptions}
            placeholder={props.label}
            name={props.name}
            onChange={getFieldValue}
            defaultValue={value}
        />
    )
}

export default DropDown
