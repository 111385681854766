import React,{useState} from 'react'
import egesidebanner from './images/ege-side-banner.svg'
import LearnMore from './LearnMore/LearnMore';
import CreateYoursNow from './CreateYoursNow/CreateYoursNow';

const ActionSection = () => {
    return (
        <div className="col-sm-5">
            <div className="mt-4 mt-sm-0">
                <img src={egesidebanner} className="img-fluid" alt="" />
            </div>
            <div className="mt-5 text-center">
                <CreateYoursNow />
                <LearnMore />
            </div>
        </div>
    )
}

export default ActionSection