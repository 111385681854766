import React, { useContext} from 'react'
import { ApiContext } from '../../../../Contexts/ApiContext'
import { DashboardContext } from '../../../../Contexts/ApiContext'
import MetricValues from './MetricValues'

const Metrics = () => {
  const [connectors, setconnectors] = useContext(ApiContext)
  const [dashboardInfo, setDashboardInfo] = useContext(DashboardContext)

  const Metrics = [
    {label:"Connectors Used", count:connectors.length},
    // {label:"Artifacts Loaded", count:dashboardInfo.artifactsCount},
    // {label:"Yearly Avg Billing", count:dashboardInfo.yearlyBilling},
    {label:"Total Synced Records", count:dashboardInfo.inboundCount + dashboardInfo.outboundCount}
  ]
  
  const connectorLength = connectors.length

  return (
    connectorLength > 0 ?
    <div className="row">
      {Metrics.map((item,index) => <MetricValues key={index} label={item.label} count={item.count} />)}
    </div> : null
  )
}
export default Metrics