import React, { useState } from 'react'
import './questionnaires.css'
import ConnectorInfo from './ConnectorInfo'
import Questions from './Questions'
import QuestionnairesTable from './QuestionnairesTable'
import SendPrerequisites from './SendPrerequisites';

const Questionnaires = (props) => {
    const connectorInfo= props.connectorInfo
    const [allFilled, setAllFilled] = useState()
    const hideModal = () =>{
        props.passData(false)
    }
    return (
        <>
            <div className='questionsPopup'>
                <div className="popup_inner">
                    <div className="addPrerequisites">
                        <h3> Add Prerequisites </h3>
                        <ConnectorInfo
                            name={props.name}
                            sourceName={props.sourceName}
                            sourceVersion={connectorInfo.erpVersion}
                            targetName={props.targetName}
                            targetVersion={connectorInfo.ecommVersion}
                            productType={connectorInfo.displayName}
                        />
                        <div className="form-section mt-3">
                            <form>
                                <div className="card">
                                    <div className="card-header">
                                        Please fill below Prerequisites
                                        {allFilled === false ? <span className='d-block errorPrerequisites'> Please fill all Prerequisites </span> : null}
                                    </div>
                                    <div className="card-body customFormControl">
                                        <div className="row">
                                            <Questions questions={props.questions} />
                                        </div>
                                        <QuestionnairesTable 
                                            questions={props.questions}
                                        />
                                    </div>
                                    <div className="card-footer text-muted">
                                        <div className="float-right">
                                            <button type="button" className="btn btn-secondary waves-effect cancancel" onClick={hideModal}>Cancel</button>
                                            <SendPrerequisites 
                                                name={props.name}
                                                sourceName={props.sourceName}
                                                targetName={props.targetName} 
                                                connectorInfo={props.connectorInfo} 
                                                featuerData={props.featuerData}
                                                productTypeValue = {props.productTypeValue}
                                                eventValue= { props.eventValue}
                                                instance = {props.instance}
                                                id={props.id}
                                                sourceVersion = {props.sourceVersion}
                                                targetVersion ={props.targetVersion}
                                                versionId ={props.versionId }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </div>
        </>
    )
}

export default Questionnaires
