import React from 'react'

const MatchedEntity = (props) => {
  return (
    <div key={props.key} id={(props.entityName).replace(/\s/g,'')} className={"col-lg-6 entityList "+(props.entityName).replace(/\s/g,'')}>
        <div className="card myconnector-businessentity-new text-white text-center p-3">
            <h5 className="mt-0 text-white businessentity-title mb-1">{props.entityName}</h5>
            <div className="syncContainer">
                <div className="erpCircle float-left">
                    <span className="endtext">ERP</span>
                </div>
                <div className="erp-ec-dir float-left">
                    <span className="erp-ecomm-dir-container">
                        <span className="syncnmbr">{props.sourceCount ? props.sourceCount : 0}</span>
                    </span>
                    <span className="ecomm-erp-dir-container">
                        <span className="syncnmbr"> {props.targetCount ? props.targetCount : 0} </span>
                    </span>
                </div>
                <div className="ecommCircle float-left">
                    <span className="endtext">EC</span>
                </div>
                <div className="clearfix"></div>
            </div>
        </div>
    </div>
  )
}

export default MatchedEntity