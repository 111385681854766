import React from 'react'
import InvisibleLogo from './InvisibleLogo'
import Notification from './Notification'
import User from './User'

const Navbar = () => {
  return (
    <div className="navbar-header">
        <InvisibleLogo />
        <div className="d-flex">
            <Notification />
            <User />
          </div>
        </div>
  )
}

export default Navbar
