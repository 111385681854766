import React,{ useState} from 'react'
import API from '../../../../API/API'

import DownloadInstaller from './DownloadInstaller/DownloadInstaller'
import SetupInstaller from './SetupInstaller/SetupInstaller'

const TargetInstaller = (props) => {
    return (
        <>
            <h6 className="installer-title"> {props.installerName} Installer </h6>
            <div className="table-responsive">
                <table className="table mb-0">
                    <tr>
                        <th>Installer</th>
                        <td>{props.installerVersion}</td>
                        <td> {props.installerSize} </td>
                        <td>
                            {
                                props.targetType !==1 ? 
                                <DownloadInstaller
                                    subscriptionId = {props.subscriptionId}
                                    endpointId = {props.endpointId}
                                    instanceId = {props.instanceId}
                                    fileType = {props.fileType}
                                />:
                                <SetupInstaller
                                    subscriptionId = {props.subscriptionId}
                                    endpointId = {props.endpointId}
                                    instanceId = {props.instanceId}
                                    fileType = {props.fileType} 
                                />
                            }
                        </td>
                    </tr>
                </table>
            </div>
        </>
    )
}

export default TargetInstaller