import React from 'react'
import TabsList from './TabsList'
import SubTabs from './SubTabs'
const Panel = () => {
    return (
        <>
            <TabsList />
            <SubTabs />
        </>
    )
}
export default Panel
