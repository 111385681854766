import React from 'react'

const UserName = () => {
    const data = JSON.parse(window.localStorage.getItem('userData'));
    const userName=data?data.name:undefined
    return (
        <a className="dropdown-item" href="#">
            <i className="uil uil-user-circle font-size-18 align-middle text-muted mr-1"></i>
            <span className="align-middle">
                {userName}
            </span>
        </a>
    )
}

export default UserName
