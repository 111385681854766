import React from 'react';
import MatchedEntity from './MatchedEntity';

const BusinessEntity = (props) => {
    const { sourceEntity, targetEntity } = props;

    // Merge entities with the same entityName
    const mergedEntities = sourceEntity.map(source => {
        const matchingTarget = targetEntity.find(target => target.entityName === source.entityName);
        return {
            ...source,
            sourceCount: source.totalCount,
            targetCount: matchingTarget ? matchingTarget.totalCount : 0, // Set targetCount to 0 if no match
        };
    }).concat(
        targetEntity.filter(target => 
            !sourceEntity.find(source => source.entityName === target.entityName)
        ).map(target => ({
            ...target,
            sourceCount: 0, // Set sourceCount to 0 for unmatched targetEntity
            targetCount: target.totalCount,
        }))
    );

    return (
        <>
            {mergedEntities.map((entity) => (
                <MatchedEntity
                    key={entity.entityName} // Use entityName as the key
                    entityName={entity.entityName}
                    sourceCount={entity.sourceCount}
                    targetCount={entity.targetCount}
                />
            ))}
        </>
    );
};

export default BusinessEntity;
