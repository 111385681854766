import React from 'react'

const Toggle = (props) => {
    return (
        <div className="customToggleButton">
            <img src={props.ToggleImg} className='toggleImg' />
            <span className="imgTitlenew"> Toggle </span>
        </div>
    )
}

export default  Toggle   