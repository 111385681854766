import React,{useState} from 'react'
import closeicon from '../images/close-icon.svg';
import SlidingPane from "react-sliding-pane";
import LearnMoreTabs from './LearnMoreTabs';
const LearnMore = () => {
    const [isPaneOpen, setIsPaneOpen] = useState(false)
    const LearnMore = () => {
        setIsPaneOpen(true)
    }
    const ClosePane = () =>{
        setIsPaneOpen(false)
    }
    return (
        <>
            <span className="text-primary learnmorenew" onClick={LearnMore}> Learn More... </span>
            <SlidingPane className='connector-popup ege-learnmore' overlayClassName='some-custom-overlay-class' isOpen={isPaneOpen}>
                <button className="popup-close" onClick={ClosePane}>
                    <img src={closeicon} />
                </button>
                <LearnMoreTabs />
            </SlidingPane>
        </>
    )
}

export default LearnMore
