import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Text, Alert,  AlertIcon,  AlertTitle,  AlertDescription, } from '@chakra-ui/react';
import { NavLink, Link } from 'react-router-dom'

const EntityCount = (props) => {
    const history = useHistory();
    var entityLength = props.allEntity.reduce((unique, o) => {
        if(!unique.some(obj => obj.entityName === o.entityName)) {
          unique.push(o);
        }
        return unique;
    },[]);

    const viewDetails = () =>{
        localStorage.setItem('summaryInfo', JSON.stringify(
            {
                sourceShortName: props.sourceShortName,
                targetShortName: props.targetShortName,
                instancesId: props.instancesId,
                sourceEntity: props.sourceEntity, 
                targetEntity:props.targetEntity,
                sourceId:props.sourceId,
                targetId:props.targetId
            }
        ))
        history.push(`/ViewSummary`);
    }

    const setupRedirection = () =>{
        localStorage.setItem("setupConnectorId", props.connectorId)
        localStorage.setItem("setupInstanceId", props.instancesId)
    }


    return (
        <>
            <h3 className="subtitleNew">Business Entity ({entityLength.length})
                {
                    entityLength.length > 0 ?
                    <a className="float-right text-primary viewDetails" onClick={viewDetails}>View Details</a> :
                    <button className="float-right text-primary no-border disabledNew" title="Installation setup is incomplete" disabled> View Details</button>
                }
            </h3>
            <Box>
                {
                    (entityLength.length < 1) && (!props.isSetupCompleted) ? 
                        <Alert status='warning'>
                            <AlertIcon />
                            <Box fontSize={'13px'}>
                                You're almost there! A few more steps are needed, Please  
                                {
                                    (
                                        props.sourceType === 1) || (props.targetType === 1) ? (
                                        (props.targetShortName == "EGE") ?
                                        <Link className="setupButtonDashboard" to={{ pathname: "/Setup"}}  onClick={setupRedirection}>click here</Link> :
                                        <Link className="setupButtonDashboard" to={{ pathname: "/Setup"}}  onClick={setupRedirection}>click here</Link>
                                    )
                                    :
                                    <Link className="setupButtonDashboard" to={{ pathname: "/Setup"}}  onClick={setupRedirection}>
                                        click here
                                    </Link>
                                }
                                to complete the setup
                            </Box>
                        </Alert>
                    : null
                }
            </Box>
        </>
    )
}

export default EntityCount