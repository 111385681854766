import React from 'react'
import Select from '../../../Modules/NewConnector/SelectConnector/Select';
const TabContent = (props) => {
    let images = props.endPointImgs && props.endPointImgs.map((item)=>item.data);
    return (
        <div className="row padding-top-20">
            {
                props.endPoints && props.endPoints.map((item, index) => 
                    props.connectorType === "All" ?
                    <div className="col-md-3 col-xl-2 mb-3 conhover">
                        <div className="connector-listing">
                            <Select id={item.id} />
                            <div className="connector-logo-container">
                                <img src={images[index]} />
                            </div>
                            <h5 className="add-connector-title"> {item.shortName} </h5>
                        </div>
                    </div> : 
                    item.type === props.connectorType ?
                    <div className="col-md-3 col-xl-2 mb-3 conhover">
                        <div className="connector-listing">
                            <Select id={item.id} />
                            <div className="connector-logo-container">
                                <img src={images[index]} />
                            </div>
                            <h5 className="add-connector-title"> {item.shortName} </h5>
                        </div>
                    </div> : null
                )
            }

        </div>
    )
}

export default TabContent