import React, { useState } from 'react'
import API from '../../../../../API/API';
import SlidingPane from "react-sliding-pane";
import Questionnaires from '../../../Questionnaires/Questionnaires';
import AwsInfo from '../../../Questionnaires/AwsInfo/AwsInfo';

const ConfirmandProceed = (props) => {
    
    const [state, setState] = useState({
        isPaneOpen: false,
    });
    const [questions, setQuestions] = useState()
    const [egeQuestions, setEgeQuestions] = useState()
    const [isEGE, setIsEGE] = useState(false)
    const [show, setShow] = useState(false);
    const modalClose = () => setShow(false);

    const ClickConfirm = () => {
        var response =window.localStorage.getItem("accessToken") ;
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        const bodynew =
        {
            "id": `${props.eventValue}`,
        };
        API.post(`/MarketPlace/connectors/${props.eventValue}/questions`, bodynew, { headers })
        .then((response) => {
            if(response.status === 200){
                setQuestions(response.data)
                //response.data.length > 0 ? setShow(true) : props.getConfirm(true)
                if(response.data.length > 0){
                    setShow(true)
                }else{
                    props.getConfirm(true)
                }
            }
        })
        // if(props.targetName === "EGE"){
        //     const bodynew =
        //     {
        //         "id": `${props.eventValue}`,
        //     };
        //     API.post(`/MarketPlace/connectors/${props.eventValue}/questions`, bodynew, { headers })
        //     .then((response) => {
        //         if(response.status === 200){
        //             setEgeQuestions(response.data)
        //             setIsEGE(false)
        //             setState({ isPaneOpen: true })
        //         }
        //     })
        // }else{
        //     const bodynew =
        //     {
        //         "id": `${props.eventValue}`,
        //     };
        //     API.post(`/MarketPlace/connectors/${props.eventValue}/questions`, bodynew, { headers })
        //     .then((response) => {
        //         if(response.status === 200){
        //             setQuestions(response.data)
        //             //response.data.length > 0 ? setShow(true) : props.getConfirm(true)
        //             if(response.data.length > 0){
        //                 setShow(true)
        //             }else{
        //                 props.getConfirm(true)
        //             }
        //         }
        //     })
        // }
    }
    const hideModal = (data) =>{
        setShow(false)
    }
    return (
        <>
            <div id="cardftr-ftrnew" className="card-footer pl-2">
                <button type="button" className="btn btn-primary w-md featuer-btn-confirm customPanopen" onClick={ClickConfirm}>
                    Confirm &amp; Proceed 
                </button>
            </div>
            {
                isEGE ? 
                    <SlidingPane overlayClassName="some-custom-overlay-class" isOpen={state.isPaneOpen} onRequestClose={() => {setState({ isPaneOpen: false });}} title="EGE Setup" subtitle="AWS Setup">
                        <AwsInfo 
                            egeQuestions={egeQuestions} 
                            name={props.name}
                            sourceName={props.sourceName}
                            targetName={props.targetName} 
                            connectorInfo={props.connectorInfo} 
                            instance = {props.instance}
                            id={props.id}
                            productTypeValue = {props.productTypeValue}
                            eventValue= { props.eventValue}
                            sourceVersion = {props.sourceVersion}
                            targetVersion ={props.targetVersion}
                            versionId ={props.versionId }
                        />
                    </SlidingPane> 
                : 
                show ? 
                <Questionnaires 
                    name={props.name}
                    sourceName={props.sourceName}
                    targetName={props.targetName} 
                    connectorInfo={props.connectorInfo} 
                    featuerData={props.featuerData}
                    productTypeValue = {props.productTypeValue}
                    eventValue= { props.eventValue}
                    instance = {props.instance}
                    id={props.id}
                    questions={questions}
                    passData={hideModal} 
                    sourceVersion = {props.sourceVersion}
                    targetVersion ={props.targetVersion}
                    versionId ={props.versionId }
                /> 
                : null
            }
        </>
    )
}
export default ConfirmandProceed