import React, { useContext, Component, useState } from "react";
import './newconnector.css'
import ConnectorType from "./AddConnector/ConnectorType";
// import NewConnectorStore from '../../../Contexts/NewConnector/Store'


const NewConnector = () => {
  return (
    <>
      <ConnectorType />
    </>
  )
}

export default NewConnector