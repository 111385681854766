import React from 'react'
import { Skeleton } from '@chakra-ui/react';

const SelectIntegrationSkeleton = () => {
    return (
        <div className='cstmoverflow'>
            <div className='row mt-3 padding-20-new'>
                <div className='col-lg-6 mb-3'>
                    <Skeleton variant="rounded" height={153} />
                </div>
                <div className='col-lg-6 mb-3'>
                    <Skeleton variant="rounded" height={153} />
                </div>
                <div className='col-lg-6 mb-3'>
                    <Skeleton variant="rounded" height={153} />
                </div>
                <div className='col-lg-6 mb-3'>
                    <Skeleton variant="rounded" height={153} />
                </div>
                <div className='col-lg-6 mb-3'>
                    <Skeleton variant="rounded" height={153} />
                </div>
                <div className='col-lg-6 mb-3'>
                    <Skeleton variant="rounded" height={153} />
                </div>
            </div>
        </div>
    )
}

export default SelectIntegrationSkeleton
