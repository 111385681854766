import React from 'react'

const BuildLoyalty = () => {
    return (
        <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
            <p>
                Create brand loyalty by exceeding customer expectations with enhanced shopping experience at every touch point.
                With improved inventory management, product visibility and a personalized shopping experience,
                the technological capabilities that come with EGE certainly put you on top of the competition.
            </p>
        </div>
    )
}

export default BuildLoyalty
