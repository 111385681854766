import React from 'react'
import * as featuerWarning from '../../Constants/Constants'

const FeatuerWarning = (props) => {
    return (
        <div id="featuersWarning" className="row">
            <div className="col-lg-12">
                <div className="alert alert-warning" role="alert">{featuerWarning.featuerWarning}</div>
            </div>
        </div>
    )
}
export default FeatuerWarning
