import React from 'react'

import './myconnections.css'

import Connectors from './Connectors/Connectors'

const ConnectorPanel = () => {
    
  return (
    <div className="row">
        <div className="col-lg-12">
            <div className="card">
                <div className="card-body">
                    <div>
                        <Connectors />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ConnectorPanel
