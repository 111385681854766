import React, { useState } from 'react'
import $ from 'jquery'
import './manageentities.css'
import Theader from './SubComponents/Theader/Theader'
import Tbody from './SubComponents/Tbody/Tbody'
import * as entityHeading from '../../Constants/Constants'

const ManageEntities = (props) => {
    const [message, setMessage] = useState(null)

    const getMessage = (messageData) =>{
        $('.errorEntityMessage').removeClass('error') 
        setMessage(messageData)

    }

    return (
        <div className='col-lg-12'>
            <div className='card mndesc'>
                <div className='card-header'>
                    {entityHeading.entityHeading.mainHeading}
                </div>
                <div className='card-body pt-0'>
                    <div className='entityContainer'>
                        {message ? <div className='errorEntityMessage'> Changes updated successfully </div> : 
                            message === null ? <div>  </div> : 
                            <div className='errorEntityMessage error'> No changes made </div>
                        }
                        <Theader />
                        <Tbody 
                            connectorId={props.connectorId} 
                            instancesId={props.instancesId}
                            getMessage={getMessage}                            
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageEntities
