import React, { useState } from 'react'
import './ege.css'

import Content from './Content';
import ActionSection from './ActionSection';

const Ege = () => {
    return (
        <div className="main-content mn-ege">
            <div className="page-content custompaddingdash">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card no-border no-boxshadow">
                                <div className="card-body">
                                    <div className="row">
                                        <Content />
                                        <ActionSection />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Ege