import React, { useState } from 'react';
import API from '../../../API/API';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import inputCalender from '../images/input_calender.svg';

const BreadCrumb = (props) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);
  const [maxStartDate] = useState(new Date()); // Set maxStartDate to today
  const [maxEndDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1))); // Set maxEndDate to tomorrow

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setStartDateError(false);
    if (date > endDate) {
      setEndDate(null);
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setEndDateError(false);
    if (date < startDate) {
      setStartDate(null);
    }
  };

  const formatDate = (date) => {
    const newDate = new Date(date);
    const year = newDate.getFullYear();
    const month = ("0" + (newDate.getMonth() + 1)).slice(-2);
    const day = ("0" + newDate.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  const applyDate = () => {
    if (!startDate) {
      setStartDateError(true);
    }
    if (!endDate) {
      setEndDateError(true);
    }

    if (startDate && endDate) {
      const convertedStartDate = formatDate(startDate);
      const convertedEndDate = formatDate(endDate);

      const response = window.localStorage.getItem("accessToken");
      const headers = {
        authorization: `Bearer ${response}`,
        "content-type": "application/json",
      };

      const bodySource = {
        subscriptionInstanceId: props.instanceId,
        sourceEndpointId: props.sourceId,
        requestType: "Source",
        updatedTime: {
          fromTime: convertedStartDate,
          toTime: convertedEndDate,
        },
      };

      const bodyTarget = {
        subscriptionInstanceId: props.instanceId,
        sourceEndpointId: props.targetId,
        requestType: "Target",
        updatedTime: {
          fromTime: convertedStartDate,
          toTime: convertedEndDate,
        },
      };

      API.post("/Client/connections/report/summary", bodySource, { headers })
        .then((response) => {
            console.log(response.data)
          API.post("/Client/connections/report/summary", bodyTarget, { headers })
            .then((response1) => {
              props.filterData({
                instancesId: props.instanceId,
                sourceEntity: response.data,
                sourceId: props.sourceId,
                sourceShortName: props.sourceShortName,
                targetEntity: response1.data,
                targetId: props.targetId,
                targetShortName: props.targetShortName,
              });
            });
        });
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <div className="float-left page-title-left">
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item">
                <Link to="/Dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">Entities</li>
            </ol>
          </div>
          <div className="float-right page-title-right positionrelative topnine detailsCal">
            <div className="form-inline form-group">
              <div className="input-group mr-3">
                <label className="summaryDateFrom"> From </label>
                <DatePicker
                  id={"startDatePicker"}
                  className={`form-control ${startDateError ? "errorNew" : ""}`}
                  placeholderText={"Pick the start date"}
                  selected={startDate}
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={endDate ? new Date(endDate.getTime() - 24 * 60 * 60 * 1000) : maxStartDate}
                  format={'yyyy-MM-dd'}
                  onChange={handleStartDateChange}
                />
                <span className="calenderIcon">
                  <img src={inputCalender} alt="Calendar" />
                </span>
                <label className="summaryDateFrom"> To </label>
                <DatePicker
                  id={"endDatePicker"}
                  className={`form-control ${endDateError ? "errorNew" : ""}`}
                  placeholderText={"Pick the end date"}
                  selected={endDate}
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate ? new Date(startDate.getTime() + 24 * 60 * 60 * 1000) : null}
                  maxDate={maxEndDate}  
                  format={'yyyy-MM-dd'}
                  onChange={handleEndDateChange}
                />
                <span className="calenderIcon">
                  <img src={inputCalender} alt="Calendar" />
                </span>
              </div>
              <button className="btn btn-primary mb-2" onClick={applyDate}>
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreadCrumb;