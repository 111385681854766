import React from 'react'

const Target = (props) => {
  return (
    <div className="card no-border no-boxshadow pb-0 mb-0 attatchicon">
        <div className="card-body">
            <div className="connector-logo-container">
                <img src={props.image} className="rightimage" title={""} />
            </div>
            <h5 className="text-truncate add-connector-title"> {props.title} </h5>
        </div>
    </div>
  )
}

export default Target