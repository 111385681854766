import React from 'react'
import './FeatuerContainer.css'
import Featuer from './Featuer'
import EgeAddons from './EgeAddons/EgeAddons'
const FeatuerContainer = (props) => {
    return (
        <div id="cardbdy-ftrnew" className="row">
            {
                ((props.targetName === "EGE") || (props.sourceName === "EGE")) ?
                    <div className="accordion-wrapper">
                        <div className="accordion">
                            <input type="radio" name="radio-a" id="check1" checked />
                            <label className="accordion-label" for="check1">Core Features</label>
                            <div id="style-3" className="accordion-content">
                                <div className='row'>
                                {   props.featuerData.map((item, index) => 
                                        <Featuer displayName={item.displayName} />
                                    )
                                }
                                </div>
                            </div>
                        </div>
                        <div className="accordion">
                            <input type="radio" name="radio-a" id="check2" />
                            <label className="accordion-label" for="check2">Addons</label>
                            <div id="style-3" className="accordion-content">
                                <div className="row">
                                    <EgeAddons />
                                </div>
                            </div>
                        </div>
                    </div>
                :
                props.featuerData.map((item, index) => 
                    <Featuer displayName={item.displayName} />
                )
            }
        </div>
    )
}

export default FeatuerContainer