import React, { useEffect } from 'react'
import './Installers.css'
import InfoComponent from '../../../Alerts/InfoComponent/InfoComponent'
import * as setupCompleted from '../../../Constants/Constants'
import ConnectorInfo from '../Questionnaires/ConnectorInfo'
import FinishSetup from './FinishSetup'
import SourceInstaller from './SourceInstaller/SourceInstaller'
import TargetInstaller from './TargetInstaller/TargetInstaller'

const Installers = (props) => {
    const connectorInfo= props.connectorInfo
    const subscriptionId = props.instance.subscriptionId
    const instanceId = props.instance.instances[0].id
    const sourceEndpointId = props.instance.source.id
    const targetEndpointId = props.instance.target.id
    const sourceFileType = props.instance.source.installer.type
    const targetFileType = props.instance.target.installer.type
    const sourceType = props.instance.source.type
    const targetType = props.instance.target.type

    return (
        <div className='card-body p-0'>
            <div className='downloadComponent'>
                <ConnectorInfo
                    name={props.name}
                    sourceName={props.sourceName}
                    sourceVersion={connectorInfo.erpVersion}
                    targetName={props.targetName}
                    targetVersion={connectorInfo.ecommVersion}
                    productType={connectorInfo.displayName}
                />
                <div className="installerComponent">
                    <SourceInstaller
                        installerName={props.sourceName}
                        installerVersion = {connectorInfo.erpVersion}
                        installerSize = {props.instance.source.installer.size}
                        subscriptionId={subscriptionId}
                        endpointId={sourceEndpointId}
                        instanceId={instanceId}
                        fileType={sourceFileType}
                        sourceType={sourceType}
                    />
                    <TargetInstaller
                        installerName={props.targetName}
                        installerVersion = {connectorInfo.ecommVersion}
                        installerSize = {props.instance.target.installer.size}
                        subscriptionId={subscriptionId}
                        instanceId={instanceId}
                        endpointId={targetEndpointId}
                        fileType={targetFileType}
                        targetType={targetType}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    Note : <InfoComponent info={setupCompleted.setupCompleted} />
                </div>
                <div className="col-lg-12">
                    <FinishSetup />
                </div>
            </div>
        </div>
    )
}

export default Installers
