import React from 'react'
import Styles from '../Source/Source.module.css'
import { Center, Card, Circle, Image , CardBody, Box, Wrap, Avatar, WrapItem} from '@chakra-ui/react';

import Magento from '../Images/magento.png'
import Shopify from '../Images/shopify.webp'
import FO from '../Images/Microsoft-Dynamics-365-Operation-and-Finance.png'
import Nav from '../Images/Microsoft-dynamics-NAV.png'
import GP from '../Images/Microsoft-dynamics-GP.png'
import AX from '../Images/Microsoft-dynamics-AX.png'
import BC from '../Images/Microsoft-Dynamics-365-Business-Central-integration.png'
import Netsuit from '../Images/Netsuite.png'
import SAP from '../Images/SAP.webp'
import BigCommerce from '../Images/Bigcommerce.png'
import EGE from '../Images/EGE.png'
import ThreeDCart from '../Images/3dcart.png'
import Quickbooks from '../Images/Quickbooks.png'
import Amazon from '../Images/Amazon-seller-Central.webp'
import CRM from '../Images/Microsoft-Dynamics-365-CRM.png' 
import SFCC from '../Images/salesforce.png'

const Images = (props) => {
    return (
        <Image 
            className={Styles.image}
            src={
                props.shortName === 'Magento' ? Magento : 
                props.shortName === 'Shopify' ? Shopify : 
                props.shortName === 'F&O' ? FO : 
                props.shortName === 'Nav' ? Nav :
                props.shortName === 'GP' ? GP :
                props.shortName === 'BC' ? BC :
                props.shortName === 'NetSuite' ? Netsuit :
                props.shortName === 'SAP' ? SAP :
                props.shortName === 'AX' ? AX :
                props.shortName === 'BigCommerce' ? BigCommerce :
                props.shortName === 'EGE' ? EGE :
                props.shortName === '3D Cart' ? ThreeDCart :
                props.shortName === 'QB' ? Quickbooks :
                props.shortName === 'Amazon' ? Amazon :
                props.shortName === 'CRM' ? CRM :
                props.shortName === 'SFCC' ? SFCC : null
            } 
        />
    )
}

export default Images
