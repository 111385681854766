import React from 'react'
import {Link} from 'react-router-dom'
const Navigation = () => {
    return (
        <div className="float-left page-title-left">
            <ol className="breadcrumb m-0">
                <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                <li className="breadcrumb-item"><Link to="/ViewSummary">Entities</Link></li>
                <li className="breadcrumb-item active">Entity Details</li>
            </ol>
        </div>
    )
}

export default Navigation
