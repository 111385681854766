import React from 'react'
import { useHistory } from 'react-router-dom'
const TableData = (props) => {
    const history = useHistory();
    const getCompleteInfo = (event) =>{
        sessionStorage.setItem('Status', event.target.name)
        localStorage.setItem('summaryDetailsInfo', JSON.stringify(
            {
                "page": 1,
                "pageSize": 10,
                "subscriptionInstanceId": props.instanceId,
                "sourceEndpointId": props.endPointId,
                "requestType": props.requestType,
                "statusIds": props.statusIds != null ? [props.statusIds] : [],
                "entityIds": props.entityId != null ? [props.entityId] : [],
                "updatedTime": {
                    "fromTime": null,
                    "toTime": null
                }
            }
        ))
        history.push(`/SummaryDetails`);
    }
    return (
        <td> 
            {
                props.count === 0 ? props.count : 
                <a className='text-primary viewDetails' 
                    onClick={getCompleteInfo}
                    id={props.entityId}
                    name={props.statusIds}
                > 
                    {props.count}
                </a> 
            } 
        </td>
    )
}
export default TableData