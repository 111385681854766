import React, { useEffect, useState } from 'react';
import Styles from './Connectors.module.css'
import { Center, Card, CardBody, Box, Wrap, Avatar, WrapItem, Heading} from '@chakra-ui/react';
import { LinkIcon} from '@chakra-ui/icons'
import Source from './Source/Source';
import Target from './Target/Target';
import { Spinner } from '@chakra-ui/react'

const Connectors = (props) => {
  return (
    <>
        <Box key={props.index} cursor={'pointer'} className={Styles.mnBox}>
          <Box className={Styles.wrapContainer}>
            <Box className={Styles.positionBox}>
              <Source image={props.image} shortName={props.sourceShortName} />
            </Box>
            <Box float={'left'} width={'10%'} position={'relative'} height={'80px'}>
              <Center className={Styles.linkIconLanding}>
                <LinkIcon />
              </Center>
            </Box>
            <Box className={Styles.positionBox}>
              <Target image={props.image} shortName={props.targetShortName} />
            </Box>
            <Box className='clearfix'></Box>
          </Box>
          <Heading className={Styles.connectorName} textAlign={'center'} color={'#444444'}> {props.name} </Heading>
        </Box>
    </>
  )
}

export default Connectors