import React, { useState, useEffect } from 'react'
import $ from 'jquery'
import { ApiContext } from './ApiContext'
import { DashboardContext } from './ApiContext'
import {BusinessEntitiesSourceContext} from './ApiContext'
import {BusinessEntitiesTargetContext} from './ApiContext'

import API from '../Components/API/API'

const Store = ({children}) => {
    const [connectors, setconnectors] = useState([])
    const [dashboardInfo, setDashboardInfo] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [businessEntitiesSource, setBusinessEntitiesSource] = useState([])
    const [businessEntitiesTarget, setBusinessEntitiesTarget] = useState([])

    useEffect(() => {  
        console.log("access", window.localStorage.getItem("accessToken"))

        $("#loaderContainer").removeClass("d-none")
        var response = window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };
        setIsLoading(true);
        API.get("/Client/connections",{headers})
        .then((response) => {
            if(response.status === 200){
                setconnectors(response.data)
                $("#loaderContainer").addClass("d-none")
                if(response.data.length === 0){
                    $("#mainWarningInfo").removeClass("d-none")
                }
                API.post("/Client/connections/report/summary",
                {
                    "subscriptionInstanceId": response.data[0].instances[0].id,
                    "sourceEndpointId": response.data[0].source.id,
                    "requestType": "Source"
                },
                {headers}
                ).then((response)=>{
                    setBusinessEntitiesSource(response.data)
                })
                API.post("/Client/connections/report/summary",
                {
                    "subscriptionInstanceId": response.data[0].instances[0].id,
                    "sourceEndpointId": response.data[0].target.id,
                    "requestType": "Target"
                },
                {headers}
                ).then((response)=>{
                    setBusinessEntitiesTarget(response.data)
                })
                
            }
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
        })
        API.get("/Dashboard",{headers})
        .then((response) => {
            if(response.status === 200){
                setDashboardInfo(response.data)
            }
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
        })
    }, [])
    return (
        <>
            <div id="loaderContainer" className="loaderContainer d-none"><div className="loader"></div></div>
            <ApiContext.Provider value={[connectors, setconnectors]}>
                <DashboardContext.Provider value={[dashboardInfo, setDashboardInfo]}>
                    <BusinessEntitiesSourceContext.Provider value={[businessEntitiesSource, setBusinessEntitiesSource]}>
                        <BusinessEntitiesTargetContext.Provider value={[businessEntitiesTarget, setBusinessEntitiesTarget]}>
                            {children}
                        </BusinessEntitiesTargetContext.Provider>
                    </BusinessEntitiesSourceContext.Provider>
                </DashboardContext.Provider>
            </ApiContext.Provider>
        </>
    )
}
export default Store
