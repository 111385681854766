import React from 'react'
import { NavLink, Link } from 'react-router-dom'

const SetupAction = (props) => {
    const setupRedirection = () =>{
        localStorage.setItem("setupConnectorId", props.connectorId)
        localStorage.setItem("setupInstanceId", props.instancesId)
    }
    return (
        <>
            {
                (
                    props.sourceType === 1) || (props.targetType === 1) ? (
                    (props.targetShortName == "EGE") ?
                    <Link className="customDownloadnew" to={{ pathname: "/Setup"}}  onClick={setupRedirection} > Setup </Link> :
                    <Link className="customDownloadnew" to={{ pathname: "/Setup"}}  onClick={setupRedirection}> Setup </Link>
                )
                :
                <Link className="customDownloadnew" to={{ pathname: "/Setup"}}  onClick={setupRedirection}>
                    Download Installers
                </Link>
            }
        </>
    )
}

export default SetupAction
