import React, { useContext, Component, useState } from "react";
import ConnectorList from "./ConnectorList";

const AddConnector = (props) => {
    const connectorType = props.connectorType
    const connectorTypeImg = props.connectorTypeImg
    let images = connectorTypeImg.map((item)=>item.data);
    return (
        <>
            <h4 className="newconnector-sub-title d-none"><span className="float-left">Select a Connector</span><span className="float-right color-blue d-none">Create New</span><span className="clearfix"></span></h4>
            <div className="cstmoverflow">
                <div className="row mt-3 padding-20-new">
                    {
                        connectorType.map((connector, index) => 
                            <React.Fragment key={connector.id}>
                                <ConnectorList 
                                    id={connector.id} 
                                    fullName ={connector.fullName} 
                                    shortName={connector.shortName}
                                    isActive={connector.isActive}
                                    type={connector.type}
                                    image={images[index]}
                                />
                            </React.Fragment>
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default AddConnector
