import React from 'react'

const CommonInfo = (props) => {
    return (
        <tr>
            <th scope="row" className="text-left">{props.label}</th>
            <td className="text-right">{props.version}</td>
        </tr>
    )
}

export default CommonInfo
