import React from 'react'

import activemenu from '../../images/activemenu.png'
const RedirectionMenu = () => {
  return (
    <>
    <li>
        <a href="http://support.i95dev.com/"  className="trhelpdesk waves-effect" target="_blank">
            <div className="mn-circle">
                <img src={activemenu} className="activemenu" />
            </div>
            <i className="uil-calender"></i>
            <span>Help Desk</span>
        </a>
    </li>
    <li>
        <a href="https://wiki.i95-dev.com"  className="trknowledgebase waves-effect" target="_blank">
            <div className="mn-circle">
                <img src={activemenu} className="activemenu" />
            </div>
            <i className="uil-calender"></i>
            <span>Knowledge Base</span>
        </a>
    </li>
    </>
  )
}

export default RedirectionMenu
