import React, { useEffect, useState } from 'react';
import {
    Text,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Checkbox,
    useDisclosure
} from '@chakra-ui/react';
import Styles from './termsandconditions.module.css'
const TermsandConditions = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isChecked, setIsChecked] = useState(props.buttonStatus);

    useEffect(()=>{
        setIsChecked(props.buttonStatus)
        console.log(isChecked)
    }, [props.buttonStatus])

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    const confirmSubscription = () =>{
        props.confirm()
        onClose()
    }

    return (
        <>
            {
                !isChecked ? 
                    <Button colorScheme="blue" onClick={onOpen}>
                        View Terms and Conditions
                    </Button>
                : null
            }
            

            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent maxW="800px">
                    <ModalHeader>Terms and Conditions</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text className={Styles.text}>
                            Welcome to our application. By accessing or using this application, 
                            you agree to comply with and be bound by the following terms and conditions. 
                            If you do not agree with any part of these terms, please do not use our application.
                        </Text>
                        <Text className={Styles.heading}> DEFINITIONS </Text>
                        <Text className={Styles.text}>
                            <b>1. Consent </b><br />
                            CONSENT means a clear affirmative act establishing a freely given, specific, informed and unambiguous indication of the website user’s agreement to the processing of personal data relating to him or her, such as by a written statement, including by electronic means, or an oral statement.
                            2. Data Controller
                                A DATA CONTROLLER is a natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of personal data for which and the manner in which any personal data are, or are to be, processed.
                            3. Data Processor
                                DATA PROCESSOR is a natural or legal person, public authority, agency or other body which processes personal data on behalf of the controller.
                            4. General Data Protection Regulation (GDPR)
                                Predominant objective of this protection is as follows:
                                The processing of personal data should be designed to serve mankind.
                                Natural persons should have control of their own personal data;
                                The right to the protection of personal data is not an absolute right; it must be considered in relation to its function in society and be balanced against other fundamental rights, in accordance with the principle of proportionality.
                                5. Legitimate Interest

Processing shall be lawful only if and to the extent that at least one of the following applies:

the data subject has given consent to the processing of his or her personal data for one or more specific purposes;
processing is necessary for the performance of a contract to which the legal/ natural person, whose data has been collected (data subject), is a party, or; in order to take steps at the request of the data subject prior to entering into a contract;
processing is necessary for compliance with a legal obligation to which the controller is subject;
processing is necessary in order to protect the vital interests of the data subject or of another natural person;
processing is necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller;
processing is necessary for the purposes of the legitimate interests pursued by the controller or by a third-party, except where such interests are overridden by the interests or fundamental rights and freedoms of the data subject which require protection of personal data, in particular where the data subject is a child.
6. Online Identifier

Natural persons may be associated with online identifiers provided by their devices, applications, tools and protocols, such as internet protocol addresses, cookie identifiers or other identifiers such as radio frequency identification tags. This may leave traces which, in particular when combined with unique identifiers and other information received by the servers, may be used to create profiles of the natural persons and identify them.

7. Personal Data

Personal data means any information relating to an identified or identifiable natural person (‘data subject’); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.
                        
                        </Text>

                        

                        <Checkbox mt={4} onChange={handleCheckboxChange}>
                            I agree to the terms and conditions
                        </Checkbox>
                    </ModalBody>

                    <ModalFooter>
                        {isChecked && (
                            <Button colorScheme="green" onClick={confirmSubscription}>
                                Confirm
                            </Button>
                        )}
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

export default TermsandConditions;
