import React, { useEffect, useState } from 'react'
import './magentoInfo.css'
import $ from 'jquery'
import DropDown from './SubComponents/DropDown'
import InputText from './SubComponents/InputText'
import Next from './SubComponents/Next'
import Select from 'react-select';
import ConfirmPopup from '../Popup/ConfirmandProceed'


const MagentoInfo = (props) => {
    const [magentoDetails, setMagentoDetails] = useState()
    const [popupOpen, setPopupOpen] = useState(false)
    const [value, setValue] = useState(null)

    const getFieldValue = (event) =>{
        setMagentoDetails({...magentoDetails, [event.target.name]: event.target.value})
    }

    const getMagentoInfo = (event) => {
        setPopupOpen(true)
    }

    const submitValues = (event) => {
        const {...inputfields} = magentoDetails;
        event.preventDefault();
    }
    const getCancel = (cancelPopup) =>{
        setPopupOpen(cancelPopup)
    }
    return (
        <div className="card mt-3 m-0 no-boxshadow no-border">
            <div className="card-body no-padding">
                <form id="magento_setup" name="magentoSetup" onSubmit={submitValues}>
                    <div className="row">
                        {
                            props.egeQuestions && props.egeQuestions.slice().reverse().map((item, index) => 
                                item.groupName === "magento" ?
                                    <div className="col-md-6">
                                        {
                                            (item.type === "DropDown" || item.type === "Dropdown")?
                                                <div className="form-group magentoGroup validClass">
                                                    {/* <DropDown id={item.code} name={item.id} label={item.text} options={item.questionOptions} getAllFieldValues={getAllFieldValues} /> */}
                                                    <select className="form-control aws_version-field version-field" id={item.code} name={item.id} onChange={getFieldValue}>
                                                        <option value="" data-default>{item.text}</option>
                                                        {item.questionOptions.map(value=>
                                                            <option value={value.code} >{value.displayName}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            :
                                            (item.type === "Text" || item.type === "text")?
                                                <div className="form-group magentoGroup validClass">
                                                    {/* <InputText id={item.code} name={item.id} label={item.text} getAllFieldValues={getAllFieldValues}/> */}
                                                    <input type="text" 
                                                        className="form-control magento_version-field version-field awsNext" 
                                                        name={item.id}
                                                        placeholder={item.text}
                                                        id={item.code}
                                                        onChange={getFieldValue}
                                                    />
                                                </div>
                                            :null
                                        }
                                    </div>
                                : null
                            )
                        }
                    </div>
                    <div className='row'>
                        <div className="col-lg-12 float-right">
                            <button type='button' id="magentoSubmitnew" className="btn btn-primary btn-rounded waves-effect waves-light float-right magentonext" onClick={getMagentoInfo}>
                                Next 
                            </button>
                        </div>
                    </div>
                    {
                        popupOpen ? 
                            <ConfirmPopup
                                getCancel={getCancel} 
                                awsDetails={props.awsDetails} 
                                magentoDetails={magentoDetails} 
                                name={props.name}
                                sourceName={props.sourceName}
                                targetName={props.targetName} 
                                connectorInfo={props.connectorInfo} 
                                instance = {props.instance}
                                id={props.id}
                                productTypeValue = {props.productTypeValue}
                                eventValue= { props.eventValue}
                                sourceVersion = {props.sourceVersion}
                                targetVersion ={props.targetVersion}
                                versionId ={props.versionId }
                            />
                        : null
                    }
                </form>
            </div>
        </div>
    )
}

export default MagentoInfo