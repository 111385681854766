import React, { useState } from 'react'
import TabCategory from './TabCategory'
import TabContent from './TabContent'
const SubTabs = () => {
    const [endPoints, setEndPoints] = useState([])
    const [endPointImgs, setEndPointImgs] = useState([])
    const [connectorType, setConnectorType] = useState()

    const GetConnectorType = (getConnectorType) =>{
        setConnectorType(getConnectorType.ListingType)
        setEndPointImgs(getConnectorType.ConnectorImage)
        setEndPoints(getConnectorType.ListofConnectors)
    }

    return (
        <div className="tabNewAdded text-muted">
            <div className="" id="connectorsew5" role="tabpanel">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card mndesc no-boxshadow no-border">
                            <div className="marketPlacetypesContent">
                                <div className="button-items">
                                    <TabCategory GetConnectorType={GetConnectorType} />
                                    <TabContent 
                                        endPoints={endPoints}
                                        endPointImgs={endPointImgs}
                                        connectorType={connectorType}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SubTabs