import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import API from '../../API/API'
import './configurations.css'

const Configurations = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [configuration, setConfiguration] = useState()
    const [updateConfiguration, setUpdateConfiguration] = useState()
    const [income, setIncome]  = useState()
    const [expense, setExpense]  = useState()
    const [inventory, setInventory]  = useState()

    const [sourceinputvalues, setSourceinputvalues] = useState()
    const [sourcetitle, setSourcetitle] = useState()
    const [isVisiblevalue, setIsVisiblevalue] = useState()
    const [typevalue, setTypevalue] = useState()
    const [endpointNamevalue, setEndpointNamevalue] = useState()
    const [idValue, setIdValue] = useState()
    
    useEffect(() => {
        setIsLoading(true)
        var connectorId = localStorage.getItem("configureConnectorId")
        var instanceId = localStorage.getItem("configureInstanceId")
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };
        API.get(`/Client/connections/${connectorId}/instances/${instanceId}`, {headers})
        .then(response => {
            window.localStorage.setItem("data",JSON.stringify(response.data))
            API.get(`/Client/connection/${connectorId}/instance/${instanceId}/configurations`,{headers })
            .then((response1) => {
                if(response1.status === 200){
                    setConfiguration(response1.data)
                    setIsLoading(false)
                }
            })
        })

        
    },[])

    const handleNameErpName = (event) =>{

    }
    const handleChange = (event) =>{
        setSourceinputvalues(event.target.value)
        setSourcetitle(event.target.title)
        setIsVisiblevalue(event.target.name.split('-')[0])
        setTypevalue(event.target.name.split('-')[1])
        setEndpointNamevalue(event.target.id.split('-')[1])
        setIdValue(event.target.id.split('-')[0])
    }
    const handleUpdate = (event) => {
        setIsLoading(true)
        setSourceinputvalues($(event.target).closest('tr').find('td:eq(1) input').val())
        setSourcetitle(event.target.getAttribute("data-key"))
        setIsVisiblevalue(event.target.getAttribute("data-isVisible"))
        setTypevalue(event.target.getAttribute("data-type"))
        setEndpointNamevalue(event.target.getAttribute("data-endpointName"))
        setIdValue(event.target.getAttribute("data-id"))

        var response = window.localStorage.getItem("accessToken");
        let data = window.localStorage.getItem("data");
        data = JSON.parse(data);

        console.log("data ..... ", data)

        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        
        const body =[
            {
                "id": parseInt(event.target.getAttribute("data-id")),
                "key": event.target.getAttribute("data-key"),
                "values": $(event.target).closest('tr').find('td:eq(1) input').val(),
                "isVisible":Boolean(event.target.getAttribute("data-isVisible")),
                "endpointName": event.target.getAttribute("data-endpointName"),
                "type": event.target.getAttribute("data-type")
            }
        ]
        API.put(`/Client/${data?.source?.id}/endpointId/${data?.target?.id}/subInstanceId/${data?.instances[0]?.id}`, body, { headers })
        .then(response => {
            if(response.data === true){
                document.getElementById("changeAlertSuccess").classList.remove("d-none")
                document.getElementById("editeditConfig"+event.target.id).classList.remove("d-none")                    
                document.getElementById("crosseditConfig"+event.target.id).classList.add("d-none")
                document.getElementById("saveeditConfig"+event.target.id).classList.add("d-none")
                setIsLoading(false)
                setTimeout(function () {
                    document.getElementById('changeAlertSuccess').classList.add('d-none');
                }, 5000)

            }
        })
    }
    const handleDisconnect = (event) => {
        var connectorId = localStorage.getItem("configureConnectorId")
        var instanceId = localStorage.getItem("configureInstanceId")
        var response = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        API.get(`/Authentication/QuickBooksDisconnect/?subscriptionConnectorId= ${instanceId} &instanceType=${configuration.instances[0].instanceType}`, { headers })
        .then(response=>{

        })

    }
    const handleInventory = (event) =>{
        setInventory(event.target.value)
    }
    const handleIncome = (event) =>{
        setIncome(event.target.value)
    }
    const handleexpense = (event)=>{
        setExpense(event.target.value)
    }
    const handleProductDetails = (event)=>{
        var connectorId = localStorage.getItem("configureConnectorId")
        var instanceId = localStorage.getItem("configureInstanceId")
        var response = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        const body=[
            {
              "id": 0,
              "key": "Income",
              "values": `${this.state.Income}`,
              "isVisible": true,
              "endpointName":  "Quickbooks",
              "type": "Source"
            },
            {
                "id": 0,
                "key": "Expense",
                "values": `${this.state.Expense}`,
                "isVisible": true,
                "endpointName":  "Quickbooks",
                "type": "Source"
            },
            {
              "id": 0,
              "key": "Inventory",
              "values": `${this.state.inventory}`,
              "isVisible": true,
              "endpointName": "Quickbooks",
              "type":  "Source"
            }
        ]
        API.put(`/Client/${configuration.source.id}/endpointId/${configuration.target.id}/subInstanceId/${configuration.instances[0].id}`, body, { headers })
        .then(response =>{
            setUpdateConfiguration(response.data)
        })
        API.get(`/Client/connection/${connectorId}/instance/${instanceId}/configurations`, { headers })
        .then(response=>{
            setConfiguration(response.data)
        })
    }
    const handleDiscountDetails = (event) =>{
        var connectorId = localStorage.getItem("configureConnectorId")
        var instanceId = localStorage.getItem("configureInstanceId")
        var response = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        const body=[
          {
            "id": 0,
            "key": "Discount",
            "values": `${income}`,
            "isVisible": true,
            "endpointName":  "Quickbooks",
            "type": "Source"
          }
        ]
        API.put(`/Client/${configuration.source.id}/endpointId/${configuration.target.id}/subInstanceId/${configuration.instances[0].id}`, body, { headers })
        .then(response =>{
            setUpdateConfiguration(response.data)
        })
        API.get(`/Client/connection/${connectorId}/instance/${instanceId}/configurations`, { headers })
        .then(response=>{
            setConfiguration(response.data)
        })
    }

    const passwordShow = (event) =>{
        $(event.target).parents("tr").find("td:nth-child(2) input").attr("type","text")
        $(event.target).parents("tr").find("td:nth-child(2) .passwordHide").removeClass("d-none")
        $(event.target).addClass("d-none")
    }

    const passwordHide = (event) =>{
        $(event.target).parents("tr").find("td:nth-child(2) input").attr("type","password")
        $(event.target).parents("tr").find("td:nth-child(2) .showPassword").removeClass("d-none")
        $(event.target).addClass("d-none")
    }

    $("body").on("click", ".edit", function () {
        $(this).closest('tr').find('input').addClass("notEmptyfield cursorBorder")
        $(this).closest('tr').find('input').removeAttr("disabled");
        $(this).closest('tr').find('input').focus();
        $(this).closest('tr').find('.cross').removeClass("d-none")
        $(this).closest('tr').find('.save').removeClass("d-none")
        $(this).addClass("d-none");
    })

    $("body").on("click", ".cross", function () {
        $(this).addClass("d-none");
        $(this).closest('tr').find('.save').addClass("d-none")
        $(this).closest('tr').find('input').removeClass("notEmptyfield cursorBorder")
        $(this).closest('tr').find('input').attr("disabled", "true")
        $(this).parents("tr").find(".edit").removeClass("d-none")
    })

    

    
    return (
        <>
            {isLoading ?
                <div className="loaderNewContainer">
                    <div className="loading-spinner"></div>
                </div> : null
            }
            <div className='main-content'>
                <div className='page-content'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='card no-border p-0 no-boxshadow mb-0'>
                                    <h4 className='card-title mb-2 card-title-wth-border'> <span className='float-left'> Configurations </span></h4>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div id="changeAlertSuccess" className="col-lg-12 d-none">
                                    <div className="alert alert-success" role="alert">
                                        Changes saved successfully
                                    </div>
                                </div>
                                <div id="notChanged" className="col-lg-12 d-none">
                                    <div className="alert alert-danger" role="alert">
                                        You did not changed anything
                                    </div>
                                </div>
                                <div className='card no-border p-0 no-boxshadow mb-0'>
                                    {
                                        configuration && configuration.source.shortName === "Shopify" ?
                                        <>
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                        <a class="nav-link mb-2 active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">Quickbooks Authentication</a>
                                                        <a class="nav-link mb-2" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">Staging Confguratiosn</a>
                                                        <a class="nav-link mb-2" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">Product Configurations</a>
                                                        <a class="nav-link" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false">Discount Configurations</a>
                                                    </div>
                                                </div>
                                                <div class="col-md-9">
                                                    <div class="tab-content text-muted mt-4 mt-md-0" id="v-pills-tabContent">
                                                        <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                                            <p>
                                                                Welcome to Quickbooks Authentication, currently you are authenticated with Quickbooks, To disconnect the connection with Quickbooks please click on Disconnect button
                                                            </p>
                                                            <button onClick={handleDisconnect}> Disconnect</button>
                                                        </div>
                                                        <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                                            <div>
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="card">
                                                                            <div className="card-body">
                                                                                <div className="table-responsive">
                                                                                    <table className="table table-centered table-nowrap mb-0">
                                                                                        <thead className="table-light">
                                                                                            <tr>
                                                                                                <th>Key</th>
                                                                                                <th className="text-center">Value</th>
                                                                                                <th className="action text-center">Action</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                configuration && configuration.source.length > 0 ? 
                                                                                                configuration && configuration.source.map(item =>
                                                                                                    <tr>
                                                                                                        <td> {item.key} </td>
                                                                                                        <td>
                                                                                                            <input 
                                                                                                                className="form-control" 
                                                                                                                type="text" 
                                                                                                                placeholder="Please enter Value" 
                                                                                                                defaultValue={(item.values.trim())} 
                                                                                                                id={item.id +"-"+ item.endpointName} 
                                                                                                                name={item.isVisible +"-"+ item.type} 
                                                                                                                title={item.key}
                                                                                                                onChange={handleChange} 
                                                                                                                disabled 
                                                                                                            /> 
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <button id="editeditConfig" className="edit editConfig text-primary border-color-primary">
                                                                                                                <i className="uil-edit-alt font-size-18"></i> 
                                                                                                            </button>
                                                                                                            <button id="saveeditConfig" className="save editConfig text-success border-color-success d-none"
                                                                                                                value={item.values}
                                                                                                                onClick={handleUpdate}
                                                                                                            >
                                                                                                                <i class="uil-check font-size-18"></i>
                                                                                                            </button>
                                                                                                            <button id="crosseditConfig" className="cross editConfig text-danger border-color-danger d-none" >
                                                                                                                <i class="uil-times font-size-18"></i>
                                                                                                            </button>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                ) : <tr> <td colSpan={2}><div>There is no data</div></td></tr>
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                                            <div className="form-group">
                                                                <select id="" className="form-control version-field" required onChange={handleexpense}>
                                                                    <option value="">Please Select Expense </option>
                                                                    {
                                                                        configuration && configuration.expense.map(item => 
                                                                            <option value={item.id}>{item.name}</option>
                                                                        )
                                                                    }
                                                                </select>
                                                            </div>
                                                            <div className="form-group">
                                                                <select className="form-control version-field" required onChange={handleIncome}>
                                                                    <option value="">Shipping Income</option>
                                                                    {
                                                                        configuration && configuration.income.map(item => 
                                                                            <option value={item.id}>{item.name}</option>
                                                                        )
                                                                    }
                                                                </select>
                                                            </div><div className="form-group">
                                                                <select className="form-control version-field" required onChange={handleInventory}>
                                                                    <option value="">Inventory Asset</option>
                                                                    {
                                                                        configuration && configuration.inventory.map(item => 
                                                                            <option value={item.id}>{item.name}</option>
                                                                        )
                                                                    }
                                                                </select>
                                                            </div>
                                                            <button onClick={handleProductDetails}>Submit</button>
                                                        </div>
                                                        <div class="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                                            <div className="form-group">
                                                                <select id="" className="form-control version-field" required onChange={handleNameErpName}>
                                                                    <option value="">Please Select Discount Type</option>
                                                                    {
                                                                        configuration && configuration.discount.map(item => 
                                                                            <option value={item.id}>{item.name}</option>
                                                                        )
                                                                    }
                                                                </select>
                                                            </div>
                                                            <button onClick={handleDiscountDetails}>Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </> : 
                                        <>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className="table-responsive">
                                                                <table className="table table-centered table-nowrap mb-0">
                                                                    <thead className="table-light">
                                                                        <tr>

                                                                            <th>Key</th>
                                                                            <th className="text-center">Value</th>
                                                                            <th className="action text-center">Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    {configuration && configuration.source.length > 0 ?
                                                                        <tbody>
                                                                            {
                                                                                configuration && configuration.source.map(item =>
                                                                                    <tr>
                                                                                        <td className="text-capitalize">
                                                                                            {item.key}
                                                                                        </td>
                                                                                        <td className="text-center">
                                                                                    {
                                                                                        item.key === "adminPassword" ? 
                                                                                        <>
                                                                                            <input className="form-control" type="password" placeholder="Please enter Value" 
                                                                                                defaultValue={item.values} 
                                                                                                id={item.id +"-"+ item.endpointName} 
                                                                                                name={item.isVisible +"-"+ item.type} 
                                                                                                title={item.key} 
                                                                                                onChange={handleChange} disabled 
                                                                                            /> 
                                                                                            <i class="uil-eye font-size-18 showPassword" id={item.id} onClick={passwordShow}></i>
                                                                                            <i class="uil-eye-slash font-size-18 showPassword passwordHide d-none" id={item.id} onClick={passwordHide}></i>
                                                                                        </> : 
                                                                                        <>
                                                                                            <input className="form-control" type="text"placeholder="Please enter Value" defaultValue={item.values} id={item.id +"-"+ item.endpointName} name={item.isVisible +"-"+ item.type} title={item.key} onChange={handleChange} disabled />
                                                                                        </>
                                                                                    }
                                                                                        
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                        <button id={"editeditConfig"+item.id} className="edit editConfig text-primary border-color-primary">
                                                                                            <i className="uil-edit-alt font-size-18"></i>
                                                                                        </button>
                                                                                        <button id={"saveeditConfig"+item.id} className="save editConfig text-success border-color-success d-none">
                                                                                            <i class="uil-check font-size-18" id={item.id}
                                                                                                data-vlue={item.values.trim()}
                                                                                                data-id={item.id}
                                                                                                data-endpointName={item.endpointName}
                                                                                                data-isVisible={item.isVisible}
                                                                                                data-type={item.type}
                                                                                                data-key={item.key}
                                                                                              onClick={handleUpdate}></i>
                                                                                        </button>
                                                                                        <button id={"crosseditConfig"+item.id} className="cross editConfig text-danger border-color-danger d-none" >
                                                                                            <i class="uil-times font-size-18"></i>
                                                                                        </button>
                                                                                    </td>
                                                                                    </tr>
                                                                            )}
                                                                        </tbody> : <div>There is no data</div>
                                                                    }
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className="table-responsive">
                                                                <table className="table table-centered table-nowrap mb-0">
                                                                    <thead className="table-light">
                                                                        <tr>

                                                                            <th>Key</th>
                                                                            <th className="text-center">Value</th>
                                                                            <th className="action text-center">Action</th>
                                                                        </tr>
                                                                    </thead>

                                                                    {configuration && configuration.target.length > 0 ?
                                                                        <tbody>
                                                                            {
                                                                                configuration && configuration.target.map(item =>
                                                                                    <tr>
                                                                                        <td className="text-capitalize">
                                                                                            {item.key}
                                                                                        </td>
                                                                                        <td className="text-center">
                                                                                    {
                                                                                        item.key === "adminPassword" ? 
                                                                                        <>
                                                                                            <input className="form-control" type="password" placeholder="Please enter Value" defaultValue={item.values} id={item.id +"-"+ item.endpointName} name={item.isVisible +"-"+ item.type} title={item.key} onChange={handleChange} disabled /> 
                                                                                            <i class="uil-eye font-size-18 showPassword" id={item.id} onClick={this.passwordShow}></i>
                                                                                            <i class="uil-eye-slash font-size-18 showPassword passwordHide d-none" id={item.id} onClick={this.passwordHide}></i>
                                                                                        </> : 
                                                                                        <>
                                                                                            <input 
                                                                                                className="form-control" 
                                                                                                type="text"
                                                                                                placeholder="Please enter Value" 
                                                                                                defaultValue={item.values.trim()} 
                                                                                                id={item.id +"-"+ item.endpointName} 
                                                                                                name={item.isVisible +"-"+ item.type} 
                                                                                                title={item.key} 
                                                                                                onChange={handleChange} 
                                                                                                disabled 
                                                                                            />
                                                                                        </>
                                                                                    }
                                                                                        
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                        <button id={"editeditConfig"+item.id} className="edit editConfig text-primary border-color-primary">
                                                                                            <i className="uil-edit-alt font-size-18"></i>
                                                                                        </button>
                                                                                        <button id={"saveeditConfig"+item.id} className="save editConfig text-success border-color-success d-none">
                                                                                            <i class="uil-check font-size-18" id={item.id} onClick={handleUpdate}
                                                                                                //defaultValue={item.values.trim()} 
                                                                                                //id={item.id +"-"+ item.endpointName} 
                                                                                                //name={item.isVisible +"-"+ item.type} 
                                                                                                //title={item.key}
                                                                                                data-vlue={item.values.trim()}
                                                                                                data-id={item.id}
                                                                                                data-endpointName={item.endpointName}
                                                                                                data-isVisible={item.isVisible}
                                                                                                data-type={item.type}
                                                                                                data-key={item.key}

                                                                                            ></i>
                                                                                        </button>
                                                                                        <button id={"crosseditConfig"+item.id} className="cross editConfig text-danger border-color-danger d-none" >
                                                                                            <i class="uil-times font-size-18"></i>
                                                                                        </button>
                                                                                    </td>
                                                                                    </tr>
                                                                            )}
                                                                        </tbody> : <div>There is no data</div>
                                                                    }
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Configurations