import React,{useState } from 'react'
import $ from "jquery"
import Select from 'react-select';
import Featuers from '../Featuers/Featuers';

const ProductType = (props) => {
    const productTypeOptions = props.productTypeData && props.productTypeData.map((item, index) => (
        {value: item.latestVersion.id, label: item.displayName}
    ))
    const [selectedOption, setSelectedOption] = useState(null)
    const selectProductType = (event) =>{
        localStorage.setItem("egeConnectorId",event.value)
        setSelectedOption(event.value)
        $("#selectProductType .css-14el2xx-placeholder").text("")
        $("#selectProductType .css-qc6sy-singleValue").text(event.label)
        $("#selectProductType").addClass("notEmptyfield")
        props.productTypeData.map(item => {
            if(item.name == event.label){
                props.passData(
                    {
                        "selectedProductType" : event.value,
                        "displayName" : event.label,
                        "latestVersion" : item.latestVersion
                    }            
                )
            }else{
                props.passData(
                    {
                        "selectedProductType" : event.value,
                        "displayName" : event.label,
                        "latestVersion" : item.latestVersion
                    }            
                )
            }
        })
        
        setSelectedOption(null)
    }
    return (
        <div className="form-group">
            <Select 
                id="selectProductType"
                className='form-control version-field'
                placeholder ={"Select Product Type"}
                options={productTypeOptions}
                onChange={selectProductType}
            />
        </div>
    )
}

export default ProductType