import React, { useState } from 'react'
import $ from 'jquery'
function AddMapping(props) {
    const addMappings = (event) => {
        if(props.sourceMethod === "" && props.targetMethod === "" ){
            $("select.sourceMethod").addClass("errorValidation")
            $("select.targetMethod").addClass("errorValidation")
        }else if(props.sourceMethod === ""){
            $("select.sourceMethod").addClass("errorValidation")
        }else if(props.targetMethod === ""){
            $("select.targetMethod").addClass("errorValidation")
        }else{
            let newMappingObj = {
                ecommerceMethod : props.targetMethod && props.targetMethod,
                erpMethod: props.sourceMethod && props.sourceMethod,
                sourceMethodText: props.sourceMethodText,
                targetMethodText: props.targetMethodText,
                isDefault: props.isDefault && props.isDefault,
            }
            props.getNewlyAddedMapping(newMappingObj)
        }
    }

    return (
        <button type='button' className='addMapping' onClick={addMappings}> 
            <i className="uil-plus-circle" id="478"></i>
        </button>
    )
}

export default AddMapping
