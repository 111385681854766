import React from 'react'

import * as setupStatus from '../../../../../../Constants/Constants';

const SetupStatus = (props) => {
    return (
        <a className="float-right font-size-10 infoNewway" id={props.connectorIndex}>
            <i className="uil-circle" id={props.connectorIndex}></i>
            <span id={props.connectorIndex}>
                <span className="text-danger font-weight-bolder"> {setupStatus.setupStatus} </span>
            </span>
        </a>
    )
}

export default SetupStatus
