import React from 'react'
import Connectors from './Connectors/Connectors'
const ConnectorPanel = () => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="card no-border p-0 no-boxshadow mb-0">
                    <div className="card-body p-0 no-boxshadow mb-0">
                        <div>
                            <Connectors />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ConnectorPanel