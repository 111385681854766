import React,{ useState} from 'react'
function SourcetoTarget(props) {
    const [checked, setChecked] = useState(false);
    function getSourcetoTarget(event){
        props.getSourcetoTarget(event.target.checked)
    }

    return (
        <div className="form-check">
            {
                <input 
                    className="custom-control-input form-check-input" 
                    type="checkbox" 
                    id={"sourcetotarget"} 
                    defaultChecked={checked}
                    onChange={getSourcetoTarget} 
                />
            }
            
            <label className="custom-control-label form-check-label" for={"sourcetotarget"}>
                ERP-Ecom
            </label>
        </div>
    )
}
export default SourcetoTarget
