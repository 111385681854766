import React, { useState, useEffect } from 'react'
import Tabs from './Tabs'
//import Details from './Details/Details'
import Details from '../../../../../Modules/Details/Details'
import Mappings from '../../../../../Modules/Mappings/Mappings'
import ManageEntities from '../../../../../Modules/ManageEntities/ManageEntities'

const SelectedConnector = (props) => {
    const [tabType, setTabType] = useState(window.localStorage.getItem("tabType"))

    var activatedTab = window.localStorage.getItem("tabType")

    const activeTab = (activeTab) =>{
        props.getActiveTab(activeTab)
        window.localStorage.setItem("tabType", activeTab) ;
        setTabType(activeTab)
    }

    return (
        <>
            <div className='card-header bg-transparent'>
                <h4 className='card-title mb-2 card-title-wth-border titlecustomNew'>
                    {props.name}
                </h4>
                <Tabs getActiveTab = {activeTab} instance={props.instance} />
            </div>
            <div className='card-body pleft-0 pright-0'>
                <div className='row'>
                    {
                        (activatedTab === "Details" || activatedTab === "") ? 
                        <Details
                            businessEntitiesSource={props.businessEntitiesSource}
                            businessEntitiesTarget={props.businessEntitiesTarget}
                            sourceName={props.sourceName}
                            targetName={props.targetName}
                            sourceShortName={props.sourceShortName}
                            targetShortName={props.targetShortName}
                            instancesId={props.instancesId}
                            sourceId={props.sourceId}
                            targetId={props.targetId}
                            sourceVersion={props.sourceVersion}
                            targetVersion={props.targetVersion}
                            featureName={props.featureName}
                            isSetupCompleted={props.isSetupCompleted}
                            sourceType = {props.sourceType}
                            targetType = {props.targetType}
                            connectorId={props.id}
                        /> : 
                        activatedTab === "Mappings" ? 
                        <Mappings
                            instancesId={props.instancesId}
                            connectorId={props.id}
                            sourceId={props.sourceId}
                            targetId={props.targetId}
                            sourceEndpointId={props.sourceEndpointId}
                            targetEndpointId={props.targetEndpointId}
                            id={props.id}
                            instance={props.instance}
                            sourceShortName={props.sourceShortName}
                            targetShortName={props.targetShortName}
                        /> : 
                        activatedTab === "ManageEntities" ?
                        <ManageEntities
                            instancesId={props.instancesId}
                            connectorId={props.id}
                        />:null
                    }
                </div>
            </div>
        </>
    )
}
export default SelectedConnector