import React, { useState } from 'react';
import './popover.css';
import API from '../../../../../API/API';
import inputCalender from '../../../images/input_calender.svg';
import filterIcon from '../../../images/filter.svg';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Navigation from './Navigation/Navigation';

const BreadCrumb = (props) => {
    const [detailPost, setDetailPost] = useState(JSON.parse(localStorage.getItem("summaryDetailsInfo")));
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [openPopover, setOpenPopover] = useState(false);

    const [targetId, setTargetId] = useState("");
    const [messageId, setMessageId] = useState("");
    const [sourceId, setSourceId] = useState("");
    const [referenceId, setReferenceId] = useState("");

    const [startDateError, setStartDateError] = useState(false);
    const [endDateError, setEndDateError] = useState(false);
    const [maxStartDate] = useState(new Date()); // Set maxStartDate to today
    const [maxEndDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1))); // Set maxEndDate to tomorrow

    const togglePopover = () => {
        setOpenPopover(!openPopover);
    };

    const handleStartDateChange = (date) => {
        //setStartDate(date);
        setStartDate(date);
        props.getStartDate(date);
        setStartDateError(false);
        if (date > endDate) {
            setEndDate(null);
        }
    };

    const handleEndDateChange = (date) => {
        // setEndDate(date);
        props.getEndDate(date);

        setEndDate(date);
        setEndDateError(false);
        if (date < startDate) {
            setStartDate(null);
        }
    };

    const isFutureDate = (date) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const dateWithoutTime = new Date(date);
        dateWithoutTime.setHours(0, 0, 0, 0);
        return dateWithoutTime < today;
    };

    const isFutureDate1 = (date) => {
        const today = new Date();
        return date >= today;
    };

    const applyDate = () => {
        if (!startDate || !endDate) {
            // Handle error cases for missing dates
            return;
        }

        const newstartDate = new Date(startDate),
            newstartDatemnth = ("0" + (newstartDate.getMonth() + 1)).slice(-2),
            newstartDateday = ("0" + newstartDate.getDate()).slice(-2);

        const convertedendDate = new Date(endDate),
            newendDatemnth = ("0" + (convertedendDate.getMonth() + 1)).slice(-2),
            newendDateday = ("0" + convertedendDate.getDate()).slice(-2);

        const convertedStartDate = [newstartDate.getFullYear(), newstartDatemnth, newstartDateday].join("-");
        const convertedEndDate = [convertedendDate.getFullYear(), newendDatemnth, newendDateday].join("-");
        
        const accessToken = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${accessToken}`,
            "content-type": "application/json"
        };
        const body = {
            "page": 1,
            "pageSize": 10,
            "subscriptionInstanceId": detailPost.subscriptionInstanceId,
            "sourceEndpointId": detailPost.sourceEndpointId,
            "requestType": detailPost.requestType,
            "statusIds": detailPost.statusIds,
            "entityIds": detailPost.entityIds,
            "updatedTime": {
                "fromTime": convertedStartDate,
                "toTime": convertedEndDate
            }
        };
        API.post(`/Client/connections/report/details`, body, { headers })
            .then(response => {
                props.applyDates(response.data);
            })
            .catch(error => {
                console.error("Failed to apply dates", error);
                // Handle error case
            });
    };

    const reset = () => {
        setStartDate(null);
        setEndDate(null);
        setTargetId('');
        setMessageId('');
        setSourceId('');
        setReferenceId('');

        const accessToken = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${accessToken}`,
            "content-type": "application/json"
        };
        const body = {
            "page": 1,
            "pageSize": 10,
            "subscriptionInstanceId": detailPost.subscriptionInstanceId,
            "sourceEndpointId": detailPost.sourceEndpointId,
            "requestType": detailPost.requestType,
            "statusIds": detailPost.statusIds,
            "entityIds": detailPost.entityIds,
            "updatedTime": {
                "fromTime": null,
                "toTime": null
            }
        };

        API.post(`/Client/connections/report/details`, body, { headers })
            .then(response => {
                props.applyDates(response.data);
            })
            .catch(error => {
                console.error("Failed to reset filters", error);
                // Handle error case
            });
    };

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        switch (name) {
            case "targetID":
                setTargetId(value);
                break;
            case "messageID":
                setMessageId(value);
                break;
            case "sourceID":
                setSourceId(value);
                break;
            case "referenceID":
                setReferenceId(value);
                break;
            default:
                break;
        }
    };

    const getFilteredData = () => {
        const newstartDate = new Date(startDate),
            newstartDatemnth = ("0" + (newstartDate.getMonth() + 1)).slice(-2),
            newstartDateday = ("0" + newstartDate.getDate()).slice(-2);
        const convertedendDate = new Date(endDate),
            newendDatemnth = ("0" + (convertedendDate.getMonth() + 1)).slice(-2),
            newendDateday = ("0" + convertedendDate.getDate()).slice(-2);
        const convertedStartDate = [newstartDate.getFullYear(), newstartDatemnth, newstartDateday].join("-");
        const convertedEndDate = [convertedendDate.getFullYear(), newendDatemnth, newendDateday].join("-");

        const accessToken = window.localStorage.getItem("accessToken");
        const headers = {
            "authorization": `Bearer ${accessToken}`,
            "content-type": "application/json"
        };
        const body = {
            "page": 1,
            "pageSize": 10,
            "subscriptionInstanceId": detailPost.subscriptionInstanceId,
            "sourceEndpointId": detailPost.sourceEndpointId,
            "requestType": detailPost.requestType,
            "statusIds": detailPost.statusIds,
            "entityIds": detailPost.entityIds,
            "targetId": targetId,
            "sourceId": sourceId,
            "reference": referenceId,
            "messageIds": messageId === "" ? [] : [parseInt(messageId)],
            "updatedTime": {
                "fromTime": startDate === null ? null : convertedStartDate,
                "toTime": endDate === null ? null : convertedEndDate
            }
        };
        API.post(`/Client/connections/report/details`, body, { headers })
            .then(response => {
                props.applyDates(response.data);
            })
            .catch(error => {
                console.error("Failed to apply filters", error);
                // Handle error case
            });
    };

    return (
        <div className="row">
            <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                    <Navigation />
                    <div className="float-right page-title-right positionrelative topnine detailsCal">
                        <div className="form-inline form-group">
                            <div className="input-group mr-3">
                                <label className="summaryDateFrom"> From </label>
                                <DatePicker
                                    id={"startDatePicker"}
                                    className={`form-control ${startDateError ? "errorNew" : ""}`}
                                    placeholderText={"Pick the start date"}
                                    selected={startDate}
                                    startDate={startDate}
                                    endDate={endDate}
                                    maxDate={endDate ? new Date(endDate.getTime() - 24 * 60 * 60 * 1000) : maxStartDate}
                                    format={'yyyy-MM-dd'}
                                    onChange={handleStartDateChange}
                                />
                                <span className="calenderIcon">
                                    <img src={inputCalender} alt="Calendar Icon" />
                                </span>
                                <label className="summaryDateFrom"> To </label>
                                <DatePicker
                                    id={"endDatePicker"}
                                    className={`form-control ${endDateError ? "errorNew" : ""}`}
                                    placeholderText={"Pick the end date"}
                                    selected={endDate}
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate ? new Date(startDate.getTime() + 24 * 60 * 60 * 1000) : null}
                                    maxDate={maxEndDate}  
                                    format={'yyyy-MM-dd'}
                                    onChange={handleEndDateChange}
                                />
                                <span className="calenderIcon">
                                    <img src={inputCalender} alt="Calendar Icon" />
                                </span>
                                <button className="btn btn-primary mb-2 ml-2" onClick={applyDate}>
                                    Apply
                                </button>
                                <label className="summaryDateTo summaryFilter" id="Popover1" onClick={togglePopover}>
                                    <img src={filterIcon} className="filterIcon" alt="Filter Icon" />
                                </label>
                            </div>

                            {openPopover && (
                                <div className="newPopover">
                                    <div className="popover-inner" role="tooltip">
                                        <div className="popoverForm">
                                            <div className="popover-body">
                                                <div className="table-responsive">
                                                    <form>
                                                        <table className="table table-borderless mb-0">
                                                            <tbody>
                                                                <tr>
                                                                    <th>Target ID</th>
                                                                    <td>
                                                                        <input
                                                                            className="form-control"
                                                                            placeholder="Enter Target ID"
                                                                            value={targetId}
                                                                            name="targetID"
                                                                            onChange={handleFilterChange}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Message ID</th>
                                                                    <td>
                                                                        <input
                                                                            className="form-control"
                                                                            placeholder="Enter Message ID"
                                                                            value={messageId}
                                                                            name="messageID"
                                                                            onChange={handleFilterChange}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Source ID</th>
                                                                    <td>
                                                                        <input
                                                                            className="form-control"
                                                                            placeholder="Enter Source ID"
                                                                            value={sourceId}
                                                                            name="sourceID"
                                                                            onChange={handleFilterChange}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th>Reference ID</th>
                                                                    <td>
                                                                        <input
                                                                            className="form-control"
                                                                            placeholder="Enter Reference ID"
                                                                            value={referenceId}
                                                                            name="referenceID"
                                                                            onChange={handleFilterChange}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <div className="d-flex justify-content-end mt-3">
                                                            <button type="button" className="btn btn-primary" onClick={getFilteredData}>
                                                                Apply
                                                            </button>
                                                            <button type="button" className="btn btn-secondary ml-2" onClick={reset}>
                                                                Reset
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BreadCrumb;