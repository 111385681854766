import React from 'react'
const Expires = (props) => {
    var date = new Date();
    let dateFinal = date.toISOString()
    let expire = (Math.round((new Date(props.expiryDate) - new Date(dateFinal)) / (1000 * 3600 * 24)))

    return (
        <>
            <div className="col-md-4">
                {
                    expire < "1" ? <h5 className="connectorExpired"> Expired </h5>:
                    <>
                        <p className="text-muted mb-0 font-size-9"> Expires in </p> 
                        <h5 className="font-size-10"> {expire + " days"} </h5>
                    </>
                }
            </div>
        </>
    )
}
export default Expires