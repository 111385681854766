import React, { useEffect, useState } from 'react'
import API from '../../../../../API/API'
import ConnectorListing from './ConnectorListing/ConnectorListing'
const SelectEGEConnector = () => {
    const [connectors, setConnectors] = useState([])
    const [integrationImg, setIntegrationImg] = useState([])
    const [integrationType, setIntegrationType] = useState([])
    
    useEffect(() => {
        var response =window.localStorage.getItem("accessToken");
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };
        API.get("/MarketPlace/endpoints/ege/connectors",{headers})
        .then(response => {
            if(response.status === 200){
                setConnectors(response.data)
                const images = response.data;
                Promise.all(images.map(image => API.get(
                    `/MarketPlace/connectors/${image.id}/image`,{headers}
                ))).then(resp =>resp)
                .then(result => {
                    setIntegrationImg(result)
                })
            }
        })
    },[])
    const imagesi = integrationImg && integrationImg.map((item)=>item.data);
    return (
        <>
            <div className="Selectconnectorewfine">
                <h4 className="newconnector-sub-title">
                    <span className="float-left">Select an Integration Type</span>
                    <span className="clearfix"></span>
                </h4>
            </div>
            <div className='row mt-3 padding-20-new'>
                {
                    connectors && connectors.map((item, index) => 
                        <ConnectorListing 
                            name={item.name}
                            id={item.id}
                            imageContent={item.imageContent}
                            sourceShortName={item.source.shortName}
                            sourceFullName={item.source.fullName}
                            sourceId={item.source.id}
                            sourceType={item.source.type}
                            targetShortName={item.target.shortName}
                            targetFullName={item.target.fullName}
                            targetId={item.target.id}
                            targetType={item.target.type}
                            integrationType={integrationType} 
                            imagesi={imagesi[index]}
                        />
                    )
                }
            </div>
        </>
    )
}
export default SelectEGEConnector