import React, {useState} from 'react'
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import API from '../../../API/API';
import SelectConnector from '../SelectConnector/SelectConnector';
import SelectIntegrationSkeleton from '../../../SharedComponents/Skeletons/SelectIntegrationSkeleton';

const Select = (props) => {
    const [state, setState] = useState({
        isPaneOpen: false,
    });
    const [integrationType, setIntegrationType] = useState([])
    const [integrationImg, setIntegrationImg] = useState([])
    const [loading, setLoading] = useState(false)

    const selectConnector = ()=> {
        setState({ isPaneOpen: true })
        var response =window.localStorage.getItem("accessToken");
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };
        API.get(`/MarketPlace/endpoints/${props.id}/connectors`,{headers})
        .then((response) => {
            if(response.status === 200){
                setIntegrationType(response.data)
                setLoading(true)
                const images = response.data;
                Promise.all(images.map(image => API.get(
                    `/MarketPlace/connectors/${image.id}/image`,{headers}
                ))).then(resp =>resp)
                .then(result => {
                    setIntegrationImg(result)
                    setLoading(false)
                })
            }
        })
    }

    return (
        <>
            <div className="conhoverselect" id={props.id} onClick={selectConnector}>
                Select 
            </div>
            <SlidingPane overlayClassName="some-custom-overlay-class" isOpen={state.isPaneOpen} onRequestClose={() => {setState({ isPaneOpen: false });}} title="Add Connector" subtitle="Select an Integration Type">
                <div className="IntegrationTypenew">
                    {
                        loading ? <SelectIntegrationSkeleton /> : 
                        <SelectConnector integrationType={integrationType} integrationImg={integrationImg} />
                    }
                </div>
            </SlidingPane>
        </>
        
    )
}
export default Select