import {React, useEffect, useState} from 'react'
import $ from 'jquery'
import Style from './sourceToTargetMappings.module.css'
import API from '../../../../../API/API';
import DeleteMapping from '../DeleteMapping/DeleteMapping';

const SourceToTargetMappings = (props) => {
  const [checked, setChecked] = useState(false);
  const [defaultMappings, setDefaultMappings] = useState()
  const [sourceMethods, setSourceMethods] = useState([])
  const [targetMethods, setTargetMethods] = useState([])
  useEffect(() => {
    var response =window.localStorage.getItem("accessToken") ;
    const headers={
      "authorization": `Bearer ${response}`,
      "content-type" : "application/json"
    };
    API.get(`/Connection/${props.connectorId}/instance/${props.instancesId}/customer-attribute`,{headers})
    .then((response) => {
      console.log("response.data", response.status)
      if(response.status === 200){
        setDefaultMappings(response.data.sourceMappedSchemaList)
        setSourceMethods(response.data && response.data.sourceMethods)
        setTargetMethods(response.data && response.data.targetMethods)
      }
    })
  },[])

  const updateMethod = (event) =>{
    if(event.target.checked === true){
      $(event.target).closest("td").attr("headers", "true")
    }else if(event.target.checked === false){
      $(event.target).closest("td").attr("headers", "false")
    }else{
      $(event.target).closest("td").attr("headers", event.target.value)
    }
  }
  
  return (
    <>
      <table id='newAttributeMappingTableNewProduct' className='table table-bordered'>
        <tbody>
          {
            defaultMappings && defaultMappings.map((item, index) =>
              <tr>
                <td headers={item.erpAttributeName}>
                  <select className='form-control sourceMethod' onChange={updateMethod}>
                    <option value={item.erpAttributeName}> {item.erpAttributeName}  </option>
                    {
                      sourceMethods && sourceMethods.map(item1 =>
                        item.erpAttributeName != item1.name ?
                        <option value={item1.name}> {item1.name} </option> : null
                      )
                    }
                  </select>
                </td>
                <td headers={item.erpAttributeDataType} className='d-none'> {item.erpAttributeDataType} </td>
                <td headers={item.erpIsRequired ? 'true' : 'false'} className='d-none'> {item.erpIsRequired ? 'true' : 'false'} </td>
                <td headers={item.erpAttributeSize} className='d-none'> {item.erpAttributeSize} </td>
                <td headers={item.ecommerceAttributeName}> 
                  <select className='form-control sourceMethod' onChange={updateMethod}>
                    <option value={item.ecommerceAttributeName} > {item.ecommerceAttributeName}   </option>
                    {
                      targetMethods && targetMethods.map(item1 =>
                        item.ecommerceAttributeName != item1.name ? 
                        <option value={item1.name}> {item1.name} </option> : null
                      )
                    }
                  </select>
                </td>
                <td headers={item.ecommerceAttributeDataType} className='d-none'> {item.ecommerceAttributeDataType} </td>
                <td headers={item.ecommerceIsRequired ? 'true' : 'false'} className='d-none'> {item.ecommerceIsRequired ? 'true' : 'false'} </td>
                <td headers={item.ecommerceAttributeSize} className='d-none'> {item.ecommerceAttributeSize} </td>
                <td headers={item.ecommerceAttributeType} className='d-none'> {item.ecommerceAttributeType} </td>
                <td>
                  <DeleteMapping default={item.default} />
                </td>
              </tr>
            )
          }
        </tbody>
      </table>
    </>
  )
}

export default SourceToTargetMappings
