import React,{useEffect, useState} from 'react'
import './egesetupdownload.css'
import API from '../../../API/API'

const EgeSetupDownload = (props) => {

    useEffect(() => {
        

    }, []);

    return (
        <div className="main-content">
            <div className="page-content pb-0">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='card setup-sectionnew'>
                                                <h4 className="card-header mt-0">BUSINESS CENTRAL SETUP</h4>
                                                <div className='card-body'>
                                                    <div className="media border-bottom">
                                                        <div className="media-body align-self-center overflow-hidden mr-4">
                                                            <div>
                                                                <div className="versionNew">
                                                                    <span className="mainTitle">Version </span>
                                                                    <span className="subTitle"> 16.x </span>
                                                                </div>
                                                                <div className="timeElapsedNew">
                                                                    <span className="mainTitle">File Size</span>
                                                                    <span className="subTitle">183.67 KB </span>
                                                                </div>
                                                                <div className="timeElapsedNew invisible">
                                                                    <span className="mainTitle">Ecommerce Type</span>
                                                                    <span className="subTitle">2.0 </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="startSetupButton">
                                                        <button type="button" className="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector">Download</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='card setup-sectionnew'>
                                                <h4 className="card-header mt-0">EGE SETUP</h4>
                                                <div className="card-body">
                                                    <div className="media border-bottom">
                                                        <div className="media-body align-self-center overflow-hidden mr-4">
                                                            <div>
                                                                <div className="timeElapsedNew">
                                                                    <span className="mainTitle">Version</span>
                                                                    <span className="subTitle">2.0 </span>
                                                                </div>
                                                                <div className="etaNew">
                                                                    <span className="mainTitle"> ETA </span>
                                                                    <span className="subTitle" id="etaNewApproach"> 00:00:00 </span>
                                                                </div>
                                                                <div className="timeElapsedNew">
                                                                    <span className="mainTitle">Time Elapsed</span>
                                                                    <span className="subTitle">
                                                                        <label id="hours">00</label>
                                                                        <label id="colon">:</label>
                                                                        <label id="minutes">00</label>
                                                                        <label id="colon">:</label>
                                                                        <label id="seconds">00</label>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="setupProgress">
                                                            <div className="setupProgresspercentage" id="targetPercentage">
                                                                <span className="numberPercentage">0%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="startSetupButton">
                                                        <button type="button" className="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector">Start Setup</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EgeSetupDownload