import React from 'react'
const TabsList = () => {
    return (
        <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
            <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#connectorsew5" role="tab">
                    <span className="d-none d-sm-block">Connectors</span> 
                </a>
            </li>
        </ul>
    )
}

export default TabsList
