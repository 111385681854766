import React from 'react'
const Validations = () => {
    return (
        <>
            <span className="errorInfoicon">i</span>
            <span id="awsworkspaceerrorInfoMessage" className="errorInfoMessage d-none">
                <p>Please provide Workspace name
                    <ul className="validationFormatlist">
                        <li>Must contain Lowercase characters and Numbers</li>
                        <li>Must not contain Uppercase characters</li>
                        <li>Must not contain <b>Testing</b> or <b>Internal</b> </li>
                    </ul>
                </p>
            </span>
    </>
  )
}

export default Validations
