import React from 'react'

import CommonInfo from './CommonInfo'

const ConnectorInfo = (props) => {
    const connectorInfo = [
        {label: props.sourceName, version:props.sourceVersion},
        {label: props.targetName, version:props.targetVersion},
        {label: "Product Type", version:props.featureName}
    ]
    return (
        <div className='table-responsive connectorshotdesc'>
            <table className="table table-nowrap table-centered mb-0">
                <tbody>
                    {connectorInfo.map((item,index) => <CommonInfo key={index} label={item.label} version={item.version} />)}
                </tbody>
            </table>
        </div>
    )
}

export default ConnectorInfo
