import React from 'react'

const TableHeadings = () => {
    return (
        <thead className="thead-light">
            <tr>
                <th>Entity</th>
                <th>Request Received</th>
                <th>Request in Process</th>
                <th>Response Received</th>
                <th>Response Transferred</th>
                <th>Error</th>
                <th>Completed</th>
                <th>Total by Entity</th>
            </tr>
        </thead>
    )
}

export default TableHeadings