import React from 'react'
import * as entityHeading from '../../../../Constants/Constants'
const Theader = () => {
    return (
        <div className='entityHeader'>
            <table width={"100%"} className='table table-bordered table-striped'>
                <thead>
                    <tr>
                        <th>{entityHeading.entityHeading.entityName}</th>
                        <th>{entityHeading.entityHeading.targetToSource}</th>
                        <th>{entityHeading.entityHeading.sourceToTarget}</th>
                    </tr>
                </thead>
            </table>
        </div>
    )
}

export default Theader
