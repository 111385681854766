import React, { useEffect, useState } from 'react'
import API from '../../../API/API'

const TabCategory = (props) => {
    const [connectorType, setConnectorType] = useState([])
    const [connectorTypeImg, setConnectorTypeImg] = useState([])
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState({
        isPaneOpen: false,
    });
    useEffect(() => {
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };
        API.get("/MarketPlace/endpoints",{headers})
        .then((response) => {
            if(response.status === 200){
                setConnectorType(response.data)
                setLoading(true)
                const images = response.data;
                Promise.all(images.map(image => API.get(
                    `/MarketPlace/endpoints/${image.id}/image`,{headers}
                )))
                .then(resp =>resp)
                .then(result => {
                    setConnectorTypeImg(result)
                    setLoading(false)
                    props.GetConnectorType(
                        {
                            ListingType: "All",
                            ListofConnectors: response.data,
                            ConnectorImage:result
                        }
                    )
                })
            }
        })
    }, []);
    const SelectConnectorType = (event) => {
        props.GetConnectorType(
            {
                ListingType: event.target.title,
                ListofConnectors:connectorType,
                ConnectorImage:connectorTypeImg
            }
        )
    }
    return (
        <ul className="row nav nav-pills" role="tablist">
            <li className="nav-item waves-effect" title={"All"} onClick={SelectConnectorType}>
                <a className="nav-link active" data-toggle="tab" role="tab" title={"All"}>
                    <span className="d-none d-sm-block" title={"All"}>All</span> 
                </a>
            </li>
            <li className="nav-item waves-effect" title={"Erp"} onClick={SelectConnectorType}>
                <a className="nav-link" data-toggle="tab" role="tab" title={"Erp"}>
                    <span className="d-none d-sm-block" title={"Erp"}>ERP</span> 
                </a>
            </li>
            <li className="nav-item waves-effect" title={"Ecommerce"} onClick={SelectConnectorType}>
                <a className="nav-link" data-toggle="tab" role="tab" title={"Ecommerce"}>
                    <span className="d-none d-sm-block" title={"Ecommerce"}>ECommerce</span> 
                </a>
            </li>
        </ul>

    )
}

export default TabCategory
