import React from 'react';
import { Box, Flex, Image, Menu, MenuButton, MenuList, MenuItem, Button } from '@chakra-ui/react';
import {Link} from 'react-router-dom'
import { ChevronDownIcon } from '@chakra-ui/icons';
import Styles from '../landingpage.module.css';
import { Auth } from 'aws-amplify';
const Header = () => {
  const handleLogin = async () => {
    try {
        // Redirect to federated sign-in page
        await Auth.federatedSignIn();
    } catch (error) {
        console.error('Error during sign-in:', error);
    }
};
  return (
    <Flex
      as="header"
      p={4}
      align="center"
      justify="space-between"
    >
      <Flex align="center">
        <Image
          src="https://www.i95dev.com/wp-content/uploads/2020/08/i95dev-Logo-red.png"
          alt="i95dev Logo"
          w={'80px'}
          mr={4}
        />
      </Flex>
      
      <Button onClick={handleLogin}> Login </Button>
    </Flex>
    
  );
};

export default Header;