export const MY_PRODUCTS = "My Products"
export const NO_CONNECTORS = " You don't have any Products to show, Please click on New connector Button and Follow the process to create Connector."
export const NO_BUSSINESS_ENTITY = 'There are no entities';
export const NO_ENTITY_DATA = 'There is no data to show';
export const setupStatus = "Setup Incomplete"
export const featuerWarning = "Select all fields to get Features "
export const noFeatuer = "We are still updating features for your selection, Meanwhile you can continue the process."
export const setupWarning = "Installation setup incomplete. To complete the installation process, Please download installers / Click on setup button, and continue installation process."
export const noData = "No data found related to your selection"
export const setupCompleted = "Please click on Finish Setup, Once done with Download and Setup process."
export const entityHeading = {
    mainHeading: "Manage Entities",
    entityName: "Entity Name",
    targetToSource: "eCommerce to ERP",
    sourceToTarget: "ERP to eCommerce"
}