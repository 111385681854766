import React from 'react'
import * as Headings from '../Constants/Constants'
const HmyProducts = () => {
  return (
    <>
        {Headings.MY_PRODUCTS}
    </>
  )
}

export default HmyProducts
