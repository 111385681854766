import React from 'react'
import { NavLink } from 'react-router-dom'
import SideMenu from './SideMenu/SideMenu'
import RedirectionMenu from './SideMenu/RedirectionMenu'

const menuItem = [
    { path: "/Updates", label:"What's New", className: "trupdates waves-effect" },
    { path: "/Dashboard", label:"Dashboard", className: "trdashboard waves-effect" },
    { path: "/MyProducts", label:"My Products", className: "trmyconnectors waves-effect" },
    { path: "/Marketplace", label:"Market Place", className: "trmarketplace waves-effect" },
    { path: "/Ege", label:"EGE", className: "trege waves-effect" },
]

const NavigationMenu = () => {
    return (
        <div data-simplebar className="sidebar-menu-scroll">
        <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
                <li className="menu-title d-none">Menu</li>
                {menuItem.map((item, index) => <SideMenu key={index} to={item.path} label={item.label} className={item.className} />)}
                <RedirectionMenu />
            </ul>
        </div>
    </div>
  )
}

export default NavigationMenu
