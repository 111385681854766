import React, { useState } from 'react'
import './Version.css'
import Source from '../Integrations/SubComponents/Source'
import Target from '../Integrations/SubComponents/Target'
import VersionForm from './VersionForm/VersionForm';
import Featuers from './Featuers/Featuers';

const Versions = (props) => {
    const [featuers, setFeatuers] = useState()
    const [eventValue, setEventValue] = useState()
    const [connectorInfo, setConnectorInfo] = useState()

    const [sourceVersion, setSourceVersion] = useState()
    const [targetVersion, setTargetVersion] = useState()
    
    const getFeatuers = (getFeatuers) => {
        if(getFeatuers != null){
            setEventValue(getFeatuers.selectedProductType)
            setFeatuers(getFeatuers.latestVersion.versionEntities)
        }else{
            setEventValue()
            setFeatuers()
        }
    };
    const selectedConnectorInfo = (connectorInfo) =>{
        setConnectorInfo(connectorInfo)
        setSourceVersion(connectorInfo.erpVersion)
        setTargetVersion(connectorInfo.ecommVersion)
    }

    return (
        <div className='row mt-3 plr-20'>
            <div className='col-lg-6 mb-3'>
                <div className='card-columns card-columns-2 integration-type-listing'>
                    <Source image={props.sourceImage} title={props.sourceName} />
                    <Target image={props.targetImage} title={props.targetName} />
                </div>
                <VersionForm 
                    id={props.id} 
                    versions = {props.versions} 
                    passData={getFeatuers} 
                    connectorInfo={selectedConnectorInfo} 
                />
            </div>
            <Featuers
                id={props.id} 
                name={props.name} 
                sourceName={props.sourceName}
                targetName={props.targetName}
                connectorInfo={connectorInfo} 
                featuers={featuers} 
                eventValue = {eventValue}  
            />
        </div>
    )
}

export default Versions