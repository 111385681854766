import React from 'react'
import MatchedEntity from './MatchedEntity'
import SourceEntity from './SourceEntity'
import TargetEntity from './TargetEntity'
const BusinessEntity = (props) => {
    const { sourceEntity, targetEntity } = props;

    // Merge entities with the same entityName
    const mergedEntities = sourceEntity.map(source => {
        const matchingTarget = targetEntity.find(target => target.entityName === source.entityName);
        return {
            ...source,
            sourceCount: source.totalCount,
            targetCount: matchingTarget ? matchingTarget.totalCount : 0, // Set targetCount to 0 if no match
        };
    }).concat(
        targetEntity.filter(target => 
            !sourceEntity.find(source => source.entityName === target.entityName)
        ).map(target => ({
            ...target,
            sourceCount: 0, // Set sourceCount to 0 for unmatched targetEntity
            targetCount: target.totalCount,
        }))
    );

  return (
    <>
        {mergedEntities.map((entity, i) => (
            <MatchedEntity
                key={i}
                entityName={entity.entityName}
                sourceCount={entity.sourceCount}
                targetCount={entity.targetCount}
            />
        ))}
        {/* {
            sourceEntity.map((sourceItem, index) =>
                <div key={index}>
                    {
                        targetEntity.map((targetItem, i) =>
                            sourceItem.entityName === targetItem.entityName ?
                                <React.Fragment key={i}>
                                    <MatchedEntity 
                                        entityName={sourceItem.entityName}
                                        sourceCount={sourceItem.totalCount}
                                        targetCount={targetItem.totalCount}
                                        key={i}
                                    />
                                </React.Fragment>
                            : null
                        )
                    }
                </div>
            )
        }
        {
            sourceEntity.map((sourceItem, i) => 
                <SourceEntity
                    key={i}
                    entityName={sourceItem.entityName}
                    sourceCount={sourceItem.totalCount}
                />
            )
        }
        {
            targetEntity.map((targetItem, i) => 
                <TargetEntity
                    key={i}
                    entityName={targetItem.entityName}
                    targetCount={targetItem.totalCount}
                />
            )
        } */}
    </>
  )
}

export default BusinessEntity
