import React,{ useState} from 'react'
function TargettoSource(props) {
    const [checked, setChecked] = useState(false);
    function getTargettoSource(event){
        props.getTargettoSource(event.target.checked)
    }
    return (
        <div className="form-check">
            {
                <input 
                    className="custom-control-input form-check-input" 
                    type="checkbox" 
                    id={"targettosource"+props.id}
                    defaultChecked={checked} 
                    onChange={getTargettoSource} 
                />
            }
            
            <label className="custom-control-label form-check-label" for={"targettosource"+props.id}>
                Ecom-ERP
            </label>
        </div>
    )
}
export default TargettoSource
    