import React, { useState, useEffect } from 'react'
import $ from 'jquery'
import Expires from './SubComponents/Expires'
import Pipes from './SubComponents/Pipes'
import ErrorLogs from './SubComponents/ErrorLogs'
import SetupStatus from './SubComponents/SetupStatus'
import ConnectorImage from './SubComponents/ConnectorImage'
const ConnectorListing = (props) => {
    return (
        <div className={`nav-link mb-2 connectortab ${props.selectedIndex === props.connectorIndex ? "active" : ""}`}>
            <div className="selectionProcessnew" title={props.name} id={props.connectorIndex} onClick={props.fn}></div>
            <div className="media pb-3 newMediaSection">
                <ConnectorImage sourceShortName={props.sourceShortName} targetShortName={props.targetShortName} />
                <div className="media-body align-self-center overflow-hidden">
                    <h5 className="font-size-14 custom-h5">
                        <a href="" className="text-dark text-truncate" title={props.name}>{props.name}</a>
                        {props.isSetupCompleted ? null : <SetupStatus connectorIndex={props.connectorIndex} />}
                    </h5>
                    <div className="row">
                        <Expires expiryDate={props.expiryDate} />
                        <ErrorLogs errorCount={props.errorCount} />
                        <Pipes entityCount={props.entityCount}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConnectorListing