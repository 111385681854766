import React from 'react'
import UserName from './UserName'
import Logout from './Logout'

const User = () => {
    return (
        <>
            <div className="dropdown d-inline-block">
                <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="uil-user"></i>
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                    <UserName />
                    <Logout />
              </div>
            </div>
        </>
    )
}

export default User
