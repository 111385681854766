import {React, useEffect, useState} from 'react'
import $ from 'jquery'
import API from '../../../../../API/API'
const TargetMethods = (props) => {
    const [targetMethods, setTargetMethods] = useState([])
    useEffect(() => {
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };
        API.get(`/Connection/${props.connectorId}/instance/${props.instancesId}/payment-methods/source`,{headers})
        .then((response) => {
            if(response.status === 200){
                setTargetMethods(response.data)
            }
        })
    },[])
    const selectTargetMethods = (event) => {
        if(event.target.value === "customeCommercemethod"){
            $(event.target).addClass("d-none")
            $(event.target).next(".customerpmethod").removeClass("d-none")
        }else{
            props.getSelectedTargetMethod(event.target.value)
        }
    }
    const enterTargetMethod = (event)=>{
        props.getSelectedTargetMethod(event.target.value)
    }

    return (
        <>
            <select className='form-control sourceMethod' onChange={selectTargetMethods}>
                <option>{"Select eCommerce Method"} </option>
                <option value={"customeCommercemethod"}> Custom eCommerce Method </option>
                {
                    targetMethods && targetMethods.map(item => 
                        <option value={item}> {item} </option>
                    )
                }
            </select>
            <input type='text' placeholder='Please enter eCommerce Method' className='d-none customerpmethod form-control version-field erpFieldnew' onChange={enterTargetMethod} />
        </>
    )
}

export default TargetMethods
