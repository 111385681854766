import React from 'react'

const QuestionnairesTable = (props) => {

    console.log("props.questions", props.questions)
    return (
        <div className="table d-none">
            <table id="defaultQuestioneries">
                {
                    props.questions.map((item)=>
                        <tr id={"newSavequestions"+item.id}>
                            <th>{item.id}</th>
                            <td id={item.id}> {item.defaultValue ? item.defaultValue : ''} </td>
                        </tr>
                    )
                        
                }
            </table>
        </div>
    )
}

export default QuestionnairesTable
