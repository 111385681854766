import React,{ useEffect, useState } from 'react'
import { Amplify, Auth, Hub } from 'aws-amplify'
import { Route, BrowserRouter as Router, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import API from '../API/API';
import Header from '../SharedComponents/Header/Header'
import SideBar from '../SharedComponents/SideBar/SideBar'
import Dashboard from '../Pages/Dashboard/Dashboard';
import MyProducts from '../Pages/MyProducts/MyProducts';
import ViewDetails from '../Modules/ViewDetails/ViewDetails';
import SummaryDetails from '../Modules/ViewDetails/EntityTable/SummaryDetails/SummaryDetails'
import MarketPlace from '../Pages/MarketPlace/MarketPlace';
import EGE from '../Pages/EGE/Ege'
import EgeSetupDownload from '../Pages/EGE/EgeSetupDownload/EgeSetupDownload';
import Authentication from '../Pages/Authentication/Authentication';
import Setup from '../Pages/Setup/Setup';
import Configurations from '../Pages/Configurations/Configurations';
import Updates from '../Pages/Updates/Updates';
import LandingPage from '../Pages/LandingPage/LandingPage';
import Versions from '../Pages/LandingPage/Versions/Versions';
import Subscription from '../Pages/LandingPage/Subscription/Subscription';
import LoadingPage from '../Pages/LandingPage/LoadingPage';

const Routes = () => {
    Amplify.configure({
        Auth: {
            // region: `us-east-1`,
            // userPoolId: `us-east-1_Ox4Vg6Tz0`,
            // userPoolWebClientId: `21osdv823olqsgs6ukbgkjleki`,
            // mandatorySignIn: true,
            // redirectSignIn: window.location.protocol + "//" + window.location.host
            region: `${process.env.REACT_APP_AWS_REGION}`,
            userPoolId: `${process.env.REACT_APP_AWS_USERPOOLID}`,
            userPoolWebClientId: `${process.env.REACT_APP_AWS_CLIENTID}`,
            mandatorySignIn: true,
            redirectSignIn: window.location.protocol + "//" + window.location.host
        }
    });
    Auth.configure({
        oauth: {
            //domain: `cloud-dev.auth.us-east-1.amazoncognito.com`,
            domain: `${process.env.REACT_APP_AWS_DOMAIN}.auth.${process.env.REACT_APP_AWS_REGION}.amazoncognito.com`,
            scope: [
                "email",
                "profile",
                "openid",
                "aws.cognito.signin.user.admin"
            ],
            redirectSignIn: window.location.protocol + "//" + window.location.host,
            redirectSignOut: window.location.protocol + "//" + window.location.host,
            responseType: "code"
        }
    });
    const [user, setUser] = useState(null);
    const [client, setClient] = useState(null);
    useEffect(() => {
        Hub.listen('auth', ({ payload: { event, data } }) => {
            switch (event) {
                case 'signIn':
                getUser().then(userData => {
                    if (isFirstTime(userData)) {
                        createCustomer(userData).then(() => {
                            refreshToken().then(() => {
                                setUser(data);
                            });
                        }).catch(() => {
                            Auth.signOut();
                        });
                    }
                });
                break;
                case 'signOut':
                setUser(null);
                window.localStorage.clear();
                break;
                case 'signIn_failure':
                case 'cognitoHostedUI_failure':
                break;
            }
        });
        getUser().then(userData => {
            if (!isFirstTime(userData)) {
                setData(userData.signInUserSession);
                setUser(userData);
            }
        });
    }, []);
    
    function isFirstTime(userData) {
        if (!userData) {
            return true;
        }
        const groups = userData.signInUserSession.idToken.payload["cognito:groups"];
        window.localStorage.setItem('signInUserSessionidToken', userData.signInUserSession.idToken.jwtToken);
        if (groups && groups.length > 0) {
            return false;
        }
        return true;
    }
    
    function refreshToken() {
        try {
            return Auth.currentAuthenticatedUser().then(cognitoUser => {
                cognitoUser.refreshSession(cognitoUser.signInUserSession.refreshToken, (err, token) => {
                    setData(token);
                });
            });
        } catch (e) {
        }
    }
    
    function setData(userData) {
        window.localStorage.setItem('accessToken', userData.accessToken.jwtToken);
        window.localStorage.setItem('userData', JSON.stringify(userData.idToken.payload));
    }
    
    function getUser() {
        return Auth.currentAuthenticatedUser()
        .then(userData => {
            return userData;
        }).catch(() => {
            //Auth.federatedSignIn();
        });
    }
    
    function createCustomer(userData) {
        const headers = {
            "authorization": `Bearer ${userData.signInUserSession.idToken.jwtToken}`,
            "content-type": "application/json"
        };

        const body = {};
        return API.post(`/Account/Client`, body, { headers })
        .then(response =>{
            setClient(response)
        })
    }
    return (
        <div>
            {user ? 
                <Router >
                    <Switch>
                        <div>
                            <Route exact path="/" 
                            render={() => { 
                                return ( 
                                    client && client.data.isFirstLogin && client.data.result ?  <Redirect to="/Updates" /> : <Redirect to="/Dashboard" />
                                )
                            }}
                            />
                            <Route exact path={'/'} component={Dashboard} />
                            <Route path="/Dashboard" component={Dashboard} />
                            <Route path="/MyProducts" component={MyProducts} />
                            <Route path="/ViewSummary" component={ViewDetails} />
                            <Route path="/SummaryDetails" component={SummaryDetails} />
                            <Route path="/Marketplace" component={MarketPlace} />
                            <Route path="/Ege" component={EGE} />
                            <Route path="/Egesetupdownload" component={EgeSetupDownload} />
                            <Route path="/Authentication" component={Authentication} />
                            <Route path="/Setup" component={Setup} />
                            <Route path="/configurations" component={Configurations} />
                            <Route path="/Updates" component={Updates} />
                        </div>
                    </Switch>
                    <Header />
                    <SideBar />
                </Router>
            :<Router >
                <Switch>
                    <div>
                        <Route exact path={'/'} component={LandingPage} />
                        <Route path="/connector/:id/:name" component={Versions} />
                        <Route path="/subscription" component={Subscription} />
                    </div>
                </Switch>
            </Router>
        }
        </div>
    )
}

export default Routes
