import React from 'react'
import * as noData from '../../Constants/Constants'
const NoEntities = (props) => {
  return (
    <div className="alert alert-warning" role="alert">
        {noData.noData}
    </div>
  )
}

export default NoEntities
