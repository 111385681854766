import React, { useState } from 'react'
import SourceMethods from './SourceMethods/SourceMethods'
import TargetMethods from './TargetMethods/TargetMethods'
import IsDefault from './SourcetoTarget/SourcetoTarget'
import TargettoSource from './TargettoSource/TargettoSource'
import AddMapping from './AddMapping/AddMapping'
import SubmitMappings from './SubmitMappings/SubmitMappings'

import DefaultMappingTable from './DefaultMappingTable/DefaultMappingTable'
const MultiLocationMapping = (props) => {
    const newMappingData = []
    const [sourceMethod, setSourceMethod] = useState("")
    const [sourceMethodText, setSourceMethodText] = useState("")
    const [targetMethod, setTargetMethod] = useState("")
    const [targetMethodText, setTargetMethodText] = useState("")
    const [isDefault, setIsDefault] = useState(false)

    const [sourceToTarget, setSourceToTarget] = useState(false)
    const [targetToSource, setTargetToSource] = useState(false)
    const [newMapping, setNewMapping] = useState(newMappingData)
    
    const getSelectedSourceMethod = (event) => {
        setSourceMethod(event)
    }
    const getSelectedSourceMethodText = (event) => {
        setSourceMethodText(event)
    }
    const getSelectedTargetMethod = (event) => {
        setTargetMethod(event)
    }
    const getSelectedTargetMethodText = (event) => {
        setTargetMethodText(event)
    }
    const getIsDefault = (event) => {
        setIsDefault(event)
    }
    
    const getNewlyAddedMapping = (event) =>{
        setNewMapping(s => {
            return [
                ...s,{
                    IsDefault: event.isDefault,
                    EcommerceMethod: event.targetMethodText,
                    ErpMethod: event.sourceMethodText,
                    ErpLocationId: event.erpMethod,
                    EcommerceLocationId: event.ecommerceMethod
                }
            ];
        });
    }

    return (
        <>
            <div className="card-header p-10">
                Multi Location Mapping 
                <SubmitMappings
                    connectorId ={props.connectorId}
                    instancesId={props.instancesId}
                />
            </div>
            <div className='card-body p-10'>
                <div className='mappingFormContainer'>
                    <table className='mappingsNew mappingForm multiLocationForm' width={"100%"}>
                        <thead>
                            <tr>
                                <td className='selecterptd'>
                                    <SourceMethods
                                        connectorId ={props.connectorId}
                                        instancesId={props.instancesId} 
                                        sourceId={props.sourceId}
                                        targetId={props.targetId}
                                        sourceEndpointId={props.sourceEndpointId}
                                        targetEndpointId={props.targetEndpointId}
                                        id={props.id}
                                        getSelectedSourceMethod = {getSelectedSourceMethod}
                                        getSelectedSourceMethodText={getSelectedSourceMethodText}
                                    />
                                </td>
                                <td className='selectecommercetd'>
                                    <TargetMethods
                                        connectorId ={props.connectorId}
                                        instancesId={props.instancesId} 
                                        targetId={props.targetId}
                                        sourceEndpointId={props.sourceEndpointId}
                                        targetEndpointId={props.targetEndpointId}
                                        id={props.id}
                                        getSelectedTargetMethod = {getSelectedTargetMethod}
                                        getSelectedTargetMethodText={getSelectedTargetMethodText}
                                    />
                                </td>
                                <td colSpan={2}>
                                    <IsDefault getSourcetoTarget={getIsDefault} />
                                </td>
                                <td>
                                    <AddMapping
                                        sourceMethod={sourceMethod}
                                        targetMethod={targetMethod}
                                        sourceMethodText={sourceMethodText}
                                        targetMethodText={targetMethodText}
                                        isDefault={isDefault}
                                        getNewlyAddedMapping={getNewlyAddedMapping}
                                    />
                                </td>
                            </tr>
                        </thead>
                    </table>
                    <DefaultMappingTable
                        connectorId ={props.connectorId}
                        instancesId={props.instancesId}
                        sourceId={props.sourceId}
                        targetId={props.targetId}
                        sourceEndpointId={props.sourceEndpointId}
                        targetEndpointId={props.targetEndpointId}
                        id={props.id}
                        newlyAddedMapping={newMapping}
                    />
                </div>
            </div>
        </>
    )
}

export default MultiLocationMapping



