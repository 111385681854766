import React from 'react'
import { NavLink } from 'react-router-dom'
import activemenu from '../../images/activemenu.png'

const SideMenu = (props) => {
  return (
    <>
      <li>
          <NavLink to={{pathname: props.to}} className={props.className}>
              <div className="mn-circle">
                  <img src={activemenu} className="activemenu" />
              </div>
              <i className="uil-calender"></i>
              <span>{props.label}</span>
          </NavLink>
      </li>
    </>
  )
}

export default SideMenu
