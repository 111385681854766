import React from 'react'
import Styles from './Features.module.css'
import { Box, SimpleGrid, Grid, GridItem, Heading, Image } from '@chakra-ui/react'
import FeatuerImage from '../../../Modules/NewConnector/Versions/Featuers/FeatuerImage'
import { Spinner } from '@chakra-ui/react'
const Features = (props) => {
    return (
        <Box>
            {props.connectorName} Features
            {
                props.versionEntities && props.versionEntities.length > 0 ?
                    <SimpleGrid columns={[2, 3, 6]} spacing='40px' mt={'20px'}>
                        {
                            props.versionEntities && props.versionEntities.map(item=>
                                <Box className={Styles.featuresContainer}>
                                    <Box className={Styles.featuresImgBox}>
                                        <FeatuerImage displayName={item.displayName} />
                                    </Box>
                                    
                                    <Heading as={'h3'} mt={'15px'}> {item.displayName} </Heading>
                                </Box>
                            )
                        }
                    </SimpleGrid>
                : props.versionEntities && props.versionEntities.length === 0 ? <Box fontFamily={'calibri'} fontSize={'14px'} mt={'30px'}> We are working on <b> {props.connectorName} </b> Features </Box> : null
            }
        </Box>
    )
}

export default Features