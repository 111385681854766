import React,{useState} from 'react'
import API from '../../../../../API/API'
import $ from 'jquery'
const IsOutboundActive = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const changeStatus = (event) => {
        setIsLoading(true)
        $(event.target).parents("tr").find("td:nth-child(2) input").attr("id",JSON.parse(event.target.checked))
        let entityId = event.target.name
        var response =window.localStorage.getItem("accessToken");
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };
        const body = {
            "isInboundActive" : JSON.parse(event.target.id),
            "isOutboundActive": event.target.checked
        }
        API.post(`/Connection/${props.connectorId}/instance/${props.instancesId}/entities/${entityId}`, body, { headers })
        .then((response) => {
            if(response.status === 200){
                props.getStatus(response)
                setIsLoading(false)
            }
        })
    }
    return (
        <>
            <td>
                {isLoading ?
                    <div className="loaderNewContainer">
                        <div className="loading-spinner"></div>
                    </div> : null
                }
                <label class="switch">
                    <input type="checkbox"
                        id={props.id.toString()}
                        name={props.name}
                        defaultChecked={props.isOutboundActive}
                        disabled={props.isOutboundEditable === true ? false : true}
                        onClick={changeStatus}
                    />
                    <span class="slider round"></span> 
                </label>
            </td>
        </>
    )
}

export default IsOutboundActive
