import React from 'react'
import ScaleYourBusiness from './ScaleYourBusiness'
import BuildLoyalty from './BuildLoyalty'
import AdvancedB2BModules from './AdvancedB2BModules'
import SeamlessERPIntegrations from './SeamlessERPIntegrations'
const TabsContent = () => {
    return (
        <div className="col-md-9">
            <div className="tab-content text-muted mt-4 mt-md-0 vpillsTabContentNew" id="v-pills-tabContent">
                <ScaleYourBusiness />
                <SeamlessERPIntegrations />
                <BuildLoyalty />
                <AdvancedB2BModules />
            </div>
        </div>
    )
}

export default TabsContent
