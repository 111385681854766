import React from 'react'
import $ from 'jquery'

const DeleteMapping = (props) => {
    
    function deleteMapping (event){
        $(event.target).closest("tr").remove()
        $(".addNewMappingTarget").addClass('d-none')
        $(".updateMappingsTarget").removeClass('d-none')
    }

    return (
        <>
            {
                props.default ? 
                <>
                    <button type="button" className="deleteMapping"  disabled>
                        <span className='deleteMappingNew'></span>
                        <span id="shippingMapping0"></span>
                        <i className="uil-trash-alt"></i>
                    </button>
                </>
                :
                <>
                    <button type="button" className="deleteMapping" onClick={deleteMapping}>
                        <span className='deleteMappingNew'></span>
                        <span id="shippingMapping0"></span>
                        <i className="uil-trash-alt"></i>
                    </button>
                </>
            }
        </>
    )
}

export default DeleteMapping
