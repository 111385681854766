import React from 'react'
import TableData from './TableData'
const TableRow = (props) => {
    return (
        <tr>
            <TableData 
                entityName = {props.entityName}
                messageId = {props.messageId}
                targetId = {props.targetId}
                sourceId = {props.sourceId}
                statusName = {props.statusName}
                updatedTime = {props.updatedTime}
                syncCounter = {props.syncCounter}
                reference = {props.reference}
                errorMessage={props.errorMessage}
                index={props.index}
            />
        </tr>
    )
}

export default TableRow
