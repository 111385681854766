import React from 'react'
import Select from '../../../../../../Modules/NewConnector/Integrations/Select'
import Source from './SubComponents/Source'
import Target from './SubComponents/Target'
const ConnectorListing = (props) => {
    return (
        <div className="col-lg-6 mb-3" id={props.id}>
            <div className="card-columns card-columns-2 integration-type-listing">
                <Select 
                    id={props.id} 
                    sourceImage={props.imagesi} 
                    targetImage={props.imagesi} 
                    sourceName={props.sourceShortName}
                    targetName={props.targetShortName}
                    name={props.name}
                /> 
                <Source
                    image={props.imagesi}
                    title={props.sourceShortName}
                />
                <Target 
                    image={props.imagesi}
                    title={props.targetShortName}
                />
            </div>
        </div>
    )
}
export default ConnectorListing