import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import API from '../../../API/API';
import Styles from './Prerequisites.module.css';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    SimpleGrid,
    IconButton,
    Select,
    Spinner,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

const FloatingLabelInput = ({ label, type, value, onChange, required, options }) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleToggle = () => {
        setShowPassword(!showPassword);
    };

    return (
        <FormControl className={Styles.floatingLabelFormControl}>
            {type === 'Dropdown' ? (
                <Select
                    placeholder={label}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    isRequired={required}
                >
                    {options.map((option) => (
                        <option key={option.id} value={option.code}>
                            {option.displayName}
                        </option>
                    ))}
                </Select>
            ) : (
                <>
                    <Input
                        type={type === 'Password' && !showPassword ? 'password' : 'text'}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        required={required}
                        className={value ? Styles.hasValue : ''}
                    />
                    <FormLabel className={Styles.floatingLabel}>{label}</FormLabel>
                    {type === 'Password' && (
                        <IconButton
                            variant="link"
                            aria-label={showPassword ? 'Hide password' : 'Show password'}
                            icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                            onClick={handleToggle}
                            position="absolute"
                            right="0.5rem"
                            top="50%"
                            transform="translateY(-50%)"
                            color="gray.500"
                        />
                    )}
                </>
            )}
        </FormControl>
    );
};

const Prerequisites = (props) => {
    const history = useHistory();
    const { id: connectorId } = useParams();
    const { name: connectorName } = useParams();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [formState, setFormState] = useState({});
    const [subscriptionAnswers, setSubscriptionAnswers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [errors, setErrors] = useState({});

    // Initialize formState and subscriptionAnswers with default values
    useEffect(() => {
        const initialState = {};
        const initialAnswers = [];

        props.questions.forEach((question) => {
            const defaultValue = question.defaultValue || '';
            initialState[question.code] = defaultValue;

            if (question.id && defaultValue) {
                initialAnswers.push({
                    QuestionId: question.id,
                    Answer: defaultValue,
                });
            }
        });

        setFormState(initialState);
        setSubscriptionAnswers(initialAnswers);
    }, [props.questions]);

    const confirmProceed = () => {
        if (props.sourceVersion === null || props.targetVersion === null) {
            props.versionError();
        } else {
            onOpen();
        }
    };

    const handleInputChange = (code, value, questionId = null) => {
        setFormState((prevState) => ({
            ...prevState,
            [code]: value,
        }));

        if (questionId) {
            setSubscriptionAnswers((prevAnswers) => {
                const existingAnswerIndex = prevAnswers.findIndex(answer => answer.QuestionId === questionId);
                if (existingAnswerIndex !== -1) {
                    const updatedAnswers = [...prevAnswers];
                    updatedAnswers[existingAnswerIndex].Answer = value;
                    return updatedAnswers;
                } else {
                    return [...prevAnswers, { QuestionId: questionId, Answer: value }];
                }
            });
        }
    };

    const resetForm = () => {
        setFormState({});
        setSubscriptionAnswers([]);
        setError('');
        setErrors({});
    };

    const handleClose = () => {
        resetForm();
        onClose();
    };

    const validateForm = () => {
        let validationErrors = {};

        if (!formState.email) {
            validationErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formState.email)) {
            validationErrors.email = 'Email is invalid';
        }

        if (!formState.name) {
            validationErrors.name = 'Name is required';
        }

        if (!formState.companyName) {
            validationErrors.companyName = 'Company Name is required';
        }

        props.questions.forEach((field) => {
            const answer = subscriptionAnswers.find(a => a.QuestionId === field.id);
            if (field.validations && field.validations.required && (!answer || !answer.Answer)) {
                validationErrors[field.code] = `${field.text} is required`;
            }

            if (field.type === 'Text' && answer && answer.Answer.trim() === '') {
                validationErrors[field.code] = `${field.text} cannot be empty`;
            }
        });

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;

        const body1 = {
            ConnectorVersionId: parseInt(props.connectorVersionId),
            ConnectorName: connectorName,
            SourceVersion: props.sourceVersion,
            TargetVersion: props.targetVersion,
            Email: formState.email,
            Name: formState.name,
            CompanyName: formState.companyName,
        };

        if (subscriptionAnswers.length > 0) {
            body1.SubscriptionAnswers = subscriptionAnswers;
        }

        console.log(body1);

        try {
            setLoading(true);
            const response = await API.post('/Subscription/TrialSubscription', body1, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                history.push('/subscription');
            } else {
                console.error('Error submitting the form:', response.statusText);
            }
        } catch (error) {
            setError('There was an error submitting the form.');
            console.error('Error during API call:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {loading && (
                <Box className={Styles.parentBoxofLoader}>
                    <Box w={'100%'} height={'100%'} position={'relative'}>
                        <Spinner
                            thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='blue.500'
                            size='xl'
                            position={'relative'}
                            top={'50%'}
                            transform={'translateY(-50%)'}
                            left={'50%'}
                        />
                    </Box>
                </Box>
            )}
            <Button className={Styles.Button} onClick={confirmProceed}>
                Provide Prerequisites
            </Button>

            <Modal isOpen={isOpen} onClose={handleClose}>
                <ModalOverlay />
                <ModalContent maxW={'750px'}>
                    <ModalHeader>Provide Prerequisites</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                            <SimpleGrid columns={3} spacing={4}>
                                <FloatingLabelInput
                                    label="Email"
                                    type="email"
                                    value={formState.email}
                                    onChange={(value) => handleInputChange('email', value)}
                                    required
                                />
                                {errors.email && <span className={Styles.error}>{errors.email}</span>}

                                <FloatingLabelInput
                                    label="Name"
                                    type="text"
                                    value={formState.name}
                                    onChange={(value) => handleInputChange('name', value)}
                                    required
                                />
                                {errors.name && <span className={Styles.error}>{errors.name}</span>}

                                <FloatingLabelInput
                                    label="Company Name"
                                    type="text"
                                    value={formState.companyName}
                                    onChange={(value) => handleInputChange('companyName', value)}
                                    required
                                />
                                {errors.companyName && <span className={Styles.error}>{errors.companyName}</span>}

                                {props.questions.map((question) => (
                                    <FloatingLabelInput
                                        key={question.id}
                                        label={question.text}
                                        type={question.type}
                                        value={subscriptionAnswers.find(a => a.QuestionId === question.id)?.Answer || question.defaultValue || ''}
                                        onChange={(value) => handleInputChange(question.code, value, question.id)}
                                        options={question.questionOptions}
                                    />
                                ))}
                            </SimpleGrid>
                            <Button
                                mt={4}
                                colorScheme='blue'
                                type="submit"
                                isLoading={loading}
                            >
                                Submit
                            </Button>
                            {error && <span className={Styles.error}>{error}</span>}
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default Prerequisites;
