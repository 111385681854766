import React from 'react'

import EbayMarkerPlace from './images/1_ebay_marketplace_integration.png'
import AmazonMarketplace from './images/2_amazon_marketplace_integration.png'
import ClaueTheme from './images/3_claue_theme_integration.png'
import ElasticSearch from './images/4_elastic_search_integration.png'
import ChargeLogic from './images/5_charge_logic _integration.png'

const EgeAddons = () => {
    return (
        <>
            <div className="col-lg-4 card no-border no-boxshadow">
                <div className="featuer-circle">
                    <span className="fwdsyncimg">
                        <img src={EbayMarkerPlace} />
                    </span>
                </div>
                <h6> Ebay MarkerPlace </h6>
            </div>
            <div className="col-lg-4 card no-border no-boxshadow">
                <div className="featuer-circle">
                    <span className="fwdsyncimg">
                        <img src={AmazonMarketplace} />
                    </span>
                </div>
                <h6> Amazon Marketplace </h6>
            </div>
            <div className="col-lg-4 card no-border no-boxshadow">
                <div className="featuer-circle">
                    <span className="fwdsyncimg">
                        <img src={ClaueTheme} />
                    </span>
                </div>
                <h6> Claue Theme </h6>
            </div>
            <div className="col-lg-4 card no-border no-boxshadow">
                <div className="featuer-circle">
                    <span className="fwdsyncimg">
                        <img src={ElasticSearch} />
                    </span>
                </div>
                <h6> Elastic Search </h6>
            </div>
            <div className="col-lg-4 card no-border no-boxshadow">
                <div className="featuer-circle">
                    <span className="fwdsyncimg">
                        <img src={ChargeLogic} />
                    </span>
                </div>
                <h6> Charge Logic </h6>
            </div>
        </>        
    )
}
export default EgeAddons