import React, { useEffect, useState, useCallback } from 'react'
import './setup.css'
import API from '../../API/API'
import { InfinitySpin } from  'react-loader-spinner'

const Setup = (props) => {
    const [counter, setCounter] = useState(10);

    const [connector, setConnector] = useState()
    const [count, setCount] = useState(0)
    const [sourceProgressMessage, setSourceProgressMessage] = useState()
    const [targetProgressMessage, setTargetProgressMessage] = useState()
    const [loading, setLoading]  =useState(false)

    useEffect(() => {
        

        var connectorId = localStorage.getItem("setupConnectorId")
        var instanceId = localStorage.getItem("setupInstanceId")
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };
        API.get(`/Client/connections/${connectorId}/instances/${instanceId}`,{headers })
        .then((response) => {
            if(response.status === 200){
                setConnector(response.data)
            }
        })
    },[])

    const sourceDownload = () =>{
        setCount(count + 1)
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json",
        };
        API.get(`/User/Token/Check`, {headers})
        .then((response)=> {
            if(response.status === 200){
                if(response.data.result == false){
                    API.get('/User/Authorize', { headers })
                    .then((response)=>{
                        if(response.status === 200){
                            if(count < 5){
                                var win = window.open(response.data, "", "width=500,height=500");
                                setTimeout(function () { win.close(); }, 10000);
                            }
                            else{
                                var html = "<html><head></head><body> Authentication failed : The Request could not be completed </body></html>"
                                var win = window.open(response.data, "", "width=500,height=500");
                                win.document.write(html)
                                setTimeout(function () { win.close(); }, 10000);
                            }
                        }
                    })
                    setTimeout(function () {
                        if (count < 5) {
                            sourceDownload();
                        }else{
                            setCount(0);
                        }
                    }, 11000);
                }else{
                    API.post(`/Client/connections/download`, {
                            "subscriptionId": connector.subscriptionId,
                            "endpointId": connector.source.id,
                            "instanceId": connector.instances[0].id,
                            "fileType": connector.source.installer.type,
                            "refreshToken": response,
                        },{ headers, responseType: 'blob' }
                    )
                    .then((response) => {
                        const disposition = response.headers["content-disposition"];
                        var fileName = "";
                        const array1 = disposition.split(';');
                        if (array1 && array1.length > 1) {
                            const string1 = array1[1];
                            if (string1) {
                                const array2 = string1.split('=');
                                if (array2 && array2.length > 1) {
                                    fileName = array2[1];
                                }
                            }
                        }
                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', fileName);
                        link.click();
                    })
                }
            }
        })
    }

    const sourceSetup = () => {
        setCount(count+1)
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json",
        };
        API.get(`/User/Token/Check`, {headers})
        .then((response)=> {
            if(response.status === 200){
                if(response.data.result == false){
                    API.get('/User/Authorize', { headers })
                    .then((response)=>{
                        if(response.status === 200){
                            if(count < 5){
                                var win = window.open(response.data, "", "width=500,height=500");
                                setTimeout(function () { win.close(); }, 10000);
                            }else{
                                var html = "<html><head></head><body> Authentication failed : The Request could not be completed </body></html>"
                                var win = window.open(response.data, "", "width=500,height=500");
                                win.document.write(html)
                                setTimeout(function () { win.close(); }, 10000);
                            }

                        }
                    })
                    setTimeout(function () {
                        if (count < 5) {
                            sourceSetup();
                        }else{
                            setCount(0);
                        }
                    }, 600000);
                }else{
                    API.post(`/Client/connections/setup`, {
                        "subscriptionId": connector.subscriptionId,
                        "endpointId": connector.source.id,
                        "instanceId": connector.instances[0].id,
                        "fileType": connector.source.installer.type
                    }, {headers})
                    .then((response) =>{
                        setLoading(false)
                        setSourceProgressMessage(response.data.message)
                    })
                }
            }
        })
    }
    
    const targetDownload = () =>{
        setCount(count + 1)
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json",
        };
        API.get(`/User/Token/Check`, {headers})
        .then((response)=> {
            if(response.status === 200){
                if(response.data.result == false){
                    API.get('/User/Authorize', { headers })
                    .then((response)=>{
                        if(response.status === 200){
                            if(count < 5){
                                var win = window.open(response.data, "", "width=500,height=500");
                                setTimeout(function () { win.close(); }, 10000);
                            }
                            else{
                                var html = "<html><head></head><body> Authentication failed : The Request could not be completed </body></html>"
                                var win = window.open(response.data, "", "width=500,height=500");
                                win.document.write(html)
                                setTimeout(function () { win.close(); }, 10000);
                            }
                        }
                    })
                    setTimeout(function () {
                        if (count < 5) {
                            targetDownload();
                        }else{
                            setCount(0);
                        }
                    }, 11000);
                }else{
                    API.post(`/Client/connections/download`, {
                            "subscriptionId": connector.subscriptionId,
                            "endpointId": connector.target.id,
                            "instanceId": connector.instances[0].id,
                            "fileType": connector.target.installer.type,
                            "refreshToken": response,
                        },{ headers, responseType: 'blob' }
                    )
                    .then((response) => {
                        const disposition = response.headers["content-disposition"];
                        var fileName = "";
                        const array1 = disposition.split(';');
                        if (array1 && array1.length > 1) {
                            const string1 = array1[1];
                            if (string1) {
                                const array2 = string1.split('=');
                                if (array2 && array2.length > 1) {
                                    fileName = array2[1];
                                }
                            }
                        }
                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', fileName);
                        link.click();
                    })
                }
            }
        })
    }

    const targetSetup = () => {
        setLoading(true)
        setCount(count+1)
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json",
        };
        API.get(`/User/Token/Check`, {headers})
        .then((response)=> {
            if(response.status === 200){
                if(response.data.result == false){
                    API.get('/User/Authorize', { headers })
                    .then((response)=>{
                        if(response.status === 200){
                            if(count < 5){
                                var win = window.open(response.data, "", "width=500,height=500");
                                setTimeout(function () { win.close(); }, 300000);
                            }else{
                                var html = "<html><head></head><body> Authentication failed : The Request could not be completed </body></html>"
                                var win = window.open(response.data, "", "width=500,height=500");
                                win.document.write(html)
                                setTimeout(function () { win.close(); }, 300000);
                            }
                        }
                    })
                    setTimeout(function () {
                        if (count < 5) {
                            sourceSetup();
                        }else{
                            setCount(0);
                        }
                    }, 900000);
                }else{
                    API.post(`/Client/connections/setup`, {
                        "subscriptionId": connector.subscriptionId,
                        "endpointId": connector.target.id,
                        "instanceId": connector.instances[0].id,
                        "fileType": connector.target.installer ? connector.target.installer.type : 1
                    }, {headers}, {timeout: 900000})
                    .then((response) =>{
                        counter > 0 && setTimeout(() => setCounter(counter - 1), 900000);
                        //alert(response.data.message)
                        setLoading(false)
                        setTargetProgressMessage(response.data.message)
                    },[counter])
                }
            }
        })
    }
    return (
        <div className='main-content'>
            <div className='page-content'>
                <div className='container-fluid'>
                    <div className='row mlr-176 mt-80'>
                        <div className='col-lg-6'>
                            <div className="card setup-sectionnew">
                                <h4 className="text-truncate font-size-14 card-header mt-0" title={connector && connector.source.name}>{connector && connector.source.name}</h4>
                                <div className="card-body">
                                    <div className="media border-bottom custom-height-new">
                                        <div className="media-body align-self-center overflow-hidden mr-4">
                                            <div>
                                                <div className="versionNew">
                                                    <span className="mainTitle">Version </span>
                                                    <span className="subTitle"> {connector && connector.source.version} </span>
                                                </div>
                                                <div className="timeElapsedNew">
                                                    <span className="mainTitle">File Size</span>
                                                    <span className="subTitle">{connector && connector.source && connector.source.installer ? connector.source.installer.size : null}</span>
                                                </div>
                                                <div><p> {sourceProgressMessage} </p></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="startSetupButton">
                                        {
                                            connector && connector.source.type !== 1 ? 
                                            <button type="button" className="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector"  onClick={sourceDownload} >Download</button> :
                                            <button type="button" className="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector"  onClick={sourceSetup} >Start Setup</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className="card setup-sectionnew">
                                <h4 className="text-truncate font-size-14 card-header mt-0" title={connector && connector.target.name}>{connector && connector.target.name}</h4>
                                <div className="card-body">
                                    {
                                        loading ?
                                            <div className='spinLoaderContainer'>
                                                <InfinitySpin
                                                    width='200'
                                                    color="#437FED"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                />
                                                <p> Please wait, Setup in progress </p>
                                            </div> 
                                        : null
                                    }
                                    <div className="media border-bottom custom-height-new">
                                        <div className="media-body align-self-center overflow-hidden mr-4">
                                            <div>
                                                <div className="versionNew">
                                                    <span className="mainTitle">Version </span>
                                                    <span className="subTitle"> {connector && connector.target.version} </span>
                                                </div>
                                                <div className="timeElapsedNew">
                                                    <span className="mainTitle">File Size</span>
                                                    <span className="subTitle">{connector && connector.target && connector.target.installer ? connector.target.installer.size : null}</span>
                                                </div>
                                                <div><p> {targetProgressMessage} </p></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="startSetupButton">
                                        {
                                            connector && connector.target.type !== 1 ? 
                                                <button type="button" className="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector"  onClick={targetDownload} >Download</button>
                                            : <button type="button" className="btn btn-primary btn-rounded waves-effect waves-light float-right new-connector"  onClick={targetSetup} >Start Setup</button>
                                        }
                                    </div>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Setup
