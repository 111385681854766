import React,{useEffect, useState} from 'react'
import API from '../../../../../API/API'
import $ from 'jquery'

function SubmitMappings(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [allUpdatedMappings, setAllUpdatedMappings] = useState()
    useEffect(() => {
        //setAllUpdatedMappings(props.allUpdatedMappings)
        //api/Connection/573/instance/573/shipping-mappings
    },[])

    const submitSelectedMappings = () =>{
        var allMapping = []
        $("#shippingMappingTable tr").each(function(){
            var mappingTable = {
                "ecommerceMethod": $(this).find("td:nth-child(2)").attr("headers"),
                "erpMethod": $(this).find("td:nth-child(1)").attr("headers"),
                "isEcommerceDefault": $(this).find("td:nth-child(3)").attr("headers") === "true" ? true : false,
                "isErpDefault": $(this).find("td:nth-child(4)").attr("headers") === "true" ? true : false
            }
            allMapping.push(mappingTable)
        })
        setIsLoading(true);
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };
        const body = allMapping
        API.post(`/Connection/${props.connectorId}/instance/${props.instancesId}/shipping-mappings`, body, { headers })
        .then((response) => {
            if(response.status === 200){
                setIsLoading(false);
            }
        })
    }


    return (
        <>
            {isLoading ?
                <div className="spinner-container">
                    <div className="loading-spinner"></div>
                </div> : null
            }

            <button className='submitButton' onClick={submitSelectedMappings}>
                Submit Mappings
            </button>
        </>
        
    )
}

export default SubmitMappings
