import React, { useState } from 'react'
import API from '../../../API/API'
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Versions from '../Versions/Versions'


const Select = (props) => {
    const [state, setState] = useState({
        isPaneOpen: false,
    });
    const [versions, setVersions] = useState([])
    const selectIntegrations = (event)=> {
        setState({ isPaneOpen: true })
        var response =window.localStorage.getItem("accessToken") ;
        const headers = {
            "authorization": `Bearer ${response}`,
            "content-type": "application/json"
        };
        API.get(`/MarketPlace/connectors/${event.target.id}/endpoint-versions`, { headers })
        .then((response) => {
            if(response.status === 200){
                setVersions(response.data)
            }
        })
    }
    
    return (
        <>
            <div className="conhoverselect" id={props.id} onClick={selectIntegrations}>
                <button type="button" className="btn btn-primary btn-rounded waves-effect waves-light new-connector" id={props.id} onClick={selectIntegrations}>
                    Select
                </button> 
            </div>
            <SlidingPane overlayClassName="some-custom-overlay-class" isOpen={state.isPaneOpen} onRequestClose={() => {setState({ isPaneOpen: false });}} title="Add Connector" subtitle="Choose a Version">
                <Versions 
                    id={props.id}
                    name={props.name}
                    versions={versions} 
                    sourceImage={props.sourceImage} 
                    targetImage={props.targetImage}
                    sourceName={props.sourceName}
                    targetName={props.targetName}
                 />
            </SlidingPane>
        </>
    )
}

export default Select