import React from 'react'
import nav_gp from '../../../Pages/Dashboard/ConnectorPanel/Connectors/ConnectorListing/SubComponents/images/nav_gp.svg'
import magento from '../../../Pages/Dashboard/ConnectorPanel/Connectors/ConnectorListing/SubComponents/images/magento.svg'
import bc from '../../../Pages/Dashboard/ConnectorPanel/Connectors/ConnectorListing/SubComponents/images/bcnew.png'
import EGE from '../../../Pages/Dashboard/ConnectorPanel/Connectors/ConnectorListing/SubComponents/images/EGE.svg'
import bigcommerce from '../../../Pages/Dashboard/ConnectorPanel/Connectors/ConnectorListing/SubComponents/images/bigcommerce.png'
import qb from '../../../Pages/Dashboard/ConnectorPanel/Connectors/ConnectorListing/SubComponents/images/qb_thumb.png'
import shopify from '../../../Pages/Dashboard/ConnectorPanel/Connectors/ConnectorListing/SubComponents/images/shopify.svg'

const Imagecomponent = (props) => {
    return (
        <>
            {
                props.name === "EGE" ? <img src={EGE} alt={props.name} className='srcImgNew' /> : 
                props.name === "Magento" ? <img src={magento} alt={props.name} className='srcImgNew' /> : 
                (props.name === "Nav") || (props.name === "GP") || (props.name === "AX") ? <img src={nav_gp} alt={props.name} className='srcImgNew' /> : 
                (props.name === "BC") || (props.name === "Business Central") ? <img src={bc} className='srcImgNew'  /> :
                props.name === "BigCommerce" ? <img src={bigcommerce} alt={props.name} className='srcImgNew' /> : 
                props.name === "QuickBooks" ? <img src={qb} alt={props.name} className='srcImgNew' /> : 
                props.name === "Shopify" ? <img src={shopify} alt={props.name} className='srcImgNew' /> : <div className='imgPlaceholderNew'> {props.name} </div>
            }
        </>
    )
}

export default Imagecomponent
