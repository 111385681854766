import React from 'react'
import * as noFeatuer from '../../Constants/Constants'

const NoFeatuer = () => {
    return (
        <div id="featuersWarning" className="row">
            <div className="col-lg-12">
                <div className="alert alert-info" role="alert">{noFeatuer.noFeatuer}</div>
            </div>
        </div>
    )
}

export default NoFeatuer
