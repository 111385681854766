import React, { useContext, useEffect, useState } from 'react'
import $ from'jquery'
import API from '../../../../API/API'
import { ApiContext } from '../../../../../Contexts/ApiContext'
import { BusinessEntitiesSourceContext } from '../../../../../Contexts/ApiContext'
import { BusinessEntitiesTargetContext } from '../../../../../Contexts/ApiContext'
import HmyProducts from '../../../../Headings/HmyProducts'
import NewConnector from '../../../../Modules/NewConnector/NewConnector'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ConnectorListing from '../../../Dashboard/ConnectorPanel/Connectors/ConnectorListing/ConnectorListing'
import SelectedConnector from './SelectedConnector/SelectedConnector'
import * as NO_CONNECTORS from '../../../../Constants/Constants'
import InfoComponent from '../../../../Alerts/InfoComponent/InfoComponent'

const Connectors = () => {
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
    };
    const [isLoading, setIsLoading] = useState(false)
    const [selectTab, setSelectTab] = useState("Details")
    const [connectors, setconnectors] = useContext(ApiContext)
    const [id, setId] = useState(0)
    const selectedConnector = connectors && connectors[id]
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [businessEntitiesSource, setBusinessEntitiesSource] = useContext(BusinessEntitiesSourceContext)
    const [businessEntitiesTarget, setBusinessEntitiesTarget] = useContext(BusinessEntitiesTargetContext)
    window.localStorage.setItem("tabType", "Details")
    function testFunction(event){
        setIsLoading(true);
        window.localStorage.setItem("tabType", "Details");
        setSelectTab("Details")

        $(".tabItem").removeClass("active")
        $(".nav-item:first-child .tabItem").addClass("active")
        $(".connectortab").removeClass("active")
        $(event.target).closest(".connectortab").addClass("active")
        setId(event.target.id)
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };    
        API.post("/Client/connections/report/summary",
        {
            "subscriptionInstanceId": connectors[event.target.id].instances[0].id,
            "sourceEndpointId": connectors[event.target.id].source.id,
            "requestType": "Source"
        },
        {headers}
        ).then((response)=>{
            setBusinessEntitiesSource(response.data)
        })
        API.post("/Client/connections/report/summary",
        {
            "subscriptionInstanceId": connectors[event.target.id].instances[0].id,
            "sourceEndpointId": connectors[event.target.id].target.id,
            "requestType": "Target"
        },
        {headers}
        ).then((response)=>{
            setBusinessEntitiesTarget(response.data)
            setIsLoading(false);
        })
    }

    const getActiveTab = (activeTabType) =>{
    }

    return (
        <>
            {isLoading ?
                <div className="loaderNewContainer">
                    <div className="loading-spinner"></div>
                </div> : null
            }
            <h4 className="card-title mb-2 card-title-wth-border">
                <span className="float-left"><HmyProducts /> ({connectors.length})</span>
                <NewConnector />
                <div className="clearfix"></div>
            </h4>
            {
                connectors && connectors.length > 0 ? 
                    <>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='card nav-pills no-border p-0 no-boxshadow mb-0'>
                                    <Slider {...settings}>
                                        {
                                            connectors.map((item,index) => 
                                                <div key={index}>
                                                    <ConnectorListing key={index}
                                                        connectorIndex={index}
                                                        name={item.name}
                                                        sourceShortName={item.source.shortName}
                                                        targetShortName={item.target.shortName}
                                                        isSetupCompleted={item.instances[0].isSetupCompleted}
                                                        expiryDate={item.expiryDate}
                                                        errorCount={item.instances[0].errorCount}
                                                        entityCount={item.instances[0].entityCount}
                                                        selectedIndex={selectedIndex}
                                                        fn={testFunction}
                                                    />
                                                </div>
                                            )
                                        }
                                    </Slider>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='card custom_mn_card'>
                                <SelectedConnector
                                    name={selectedConnector && selectedConnector.name}
                                    sourceName={selectedConnector && selectedConnector.source.name}
                                    sourceVersion={selectedConnector && selectedConnector.source.version}
                                    targetName={selectedConnector && selectedConnector.target.name}
                                    targetVersion={selectedConnector && selectedConnector.target.version}
                                    featureName={selectedConnector && selectedConnector.featureName}
                                    isSetupCompleted = {selectedConnector && selectedConnector.instances[0].isSetupCompleted}
                                    businessEntitiesSource={businessEntitiesSource}
                                    businessEntitiesTarget={businessEntitiesTarget}
                                    sourceShortName={selectedConnector && selectedConnector.source.shortName}
                                    targetShortName={selectedConnector && selectedConnector.target.shortName}
                                    instancesId={selectedConnector && selectedConnector.instances[0].id}
                                    sourceId={selectedConnector && selectedConnector.source.id}
                                    targetId={selectedConnector && selectedConnector.target.id}
                                    sourceEndpointId={selectedConnector && selectedConnector.source.endpointId}
                                    targetEndpointId={selectedConnector && selectedConnector.target.endpointId}
                                    id={selectedConnector && selectedConnector.id}
                                    selectTab={selectTab}
                                    getActiveTab={getActiveTab}
                                    sourceType = {selectedConnector && selectedConnector.source.type}
                                    targetType = {selectedConnector && selectedConnector.target.type}
                                    instance={selectedConnector && selectedConnector.instances && selectedConnector.instances[0]}
                                />
                            </div>                    
                        </div>
                    </> 
                : 
                <div id='mainWarningInfo' className='row d-none'>
                    <div className='col-md-12'>
                        <InfoComponent info={NO_CONNECTORS.NO_CONNECTORS} />
                    </div>
                </div> 
            }
        </>
    )
}

export default Connectors