import React, { useState } from 'react'
import $ from 'jquery'
import Select from 'react-select';

const Dropdown = (props) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionId, setSelectedOptionId] = useState(null);

    const questionOptions = props.options && props.options.map((item, index) => (
            {value:item.code, label:item.displayName, id:props.id, question:props.placeholder}
        )
    )

    const selectOption = (event) =>{
        $("#defaultQuestioneries tr#newSavequestions"+event.id+" td").text(event.value)
    }

    return (
        <div id={"prerequisites"} className="form-group">
            <Select
                className='form-control'
                placeholder={props.placeholder}
                isSearchable={false}
                defaultValue = {selectedOption}
                options={questionOptions}
                onChange={selectOption}
            />
        </div>
    )
}

export default Dropdown